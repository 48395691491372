import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  AppBar,
  Box,
  Button,
  Container,
  Divider,
  Drawer,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Slide,
  Toolbar,
  Typography,
  useScrollTrigger,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import logosvg from 'assets/logosvg.svg';
import { ReactComponent as MyAvatar } from 'assets/icons/mypage.svg';
import withRouter from 'utils/withRouter';
import { selectUser } from 'store/userSlice';

function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide
      appear={false}
      direction="down"
      in={!trigger}
      sx={{ backgroundColor: trigger ? 'transparent' : 'none' }}
    >
      {children}
    </Slide>
  );
}

const Header = (props) => {
  const { active, bgTransparent } = props;
  const user = useSelector(selectUser);

  const [menu, setMenu] = useState([
    { path: '/about', name: '소개합니다' },
    { path: '/process', name: '신청 및 일정 안내' },
    { path: '/faq', name: '자주 묻는 질문' },
  ]);
  const [drawerState, setDrawerState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [anchor, setAnchor] = useState('left');

  const toggleDrawer = (aType, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setDrawerState({ ...drawerState, [aType]: open });
  };

  useEffect(() => {
    if (user) {
      setMenu([
        { path: '/about', name: '소개합니다' },
        { path: '/process', name: '신청 및 일정 안내' },
        { path: '/faq', name: '자주 묻는 질문' },
        { path: '/QnA', name: '질문 게시판' },
      ]);
    }
  }, [user]);

  const list = (type) => (
    <Box
      sx={{ width: type === 'top' || type === 'bottom' ? 'auto' : 250 }}
      color="inherit"
      role="presentation"
      onClick={toggleDrawer(type, false)}
      onKeyDown={toggleDrawer(type, false)}
    >
      <Typography variant="h1" sx={{ m: 2 }}>
        배남프
      </Typography>
      <Divider />
      <List>
        {menu.map((item, index) => (
          <ListItem
            disablePadding
            key={index}
            className={active === item.name ? 'bg-slate-100' : ''}
          >
            <ListItemButton onClick={() => props.navigate(item.path)}>
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
        {/* {user && (
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                props.navigate('/notice');
              }}
            >
              <ListItemText primary="공지사항" />
            </ListItemButton>
          </ListItem>
        )} */}
      </List>
      <Divider />
      <List>
        {user ? (
          <ListItem disablePadding className="!justify-center">
            <Box
              className="flex"
              sx={{ p: 1 }}
              onClick={() => {
                if (user.mentoringInfoExist) {
                  props.navigate('/mypage');
                } else {
                  props.navigate('/mentoring-info');
                }
              }}
            >
              <MyAvatar />
              <Typography
                variant="body1"
                component="span"
                className="pl-1 !font-medium"
                color={(theme) => theme.palette.primary.main}
              >
                내 정보
              </Typography>
            </Box>
          </ListItem>
        ) : (
          <>
            <ListItem disablePadding className="!justify-center">
              <Link className="flex" href="/signin" sx={{ p: 1, display: 'block' }}>
                <Typography
                  variant="body1"
                  component="span"
                  className="pl-1 !font-medium"
                  color={(theme) => theme.palette.primary.main}
                >
                  로그인
                </Typography>
              </Link>
            </ListItem>
            {/* <ListItem disablePadding className="!justify-center">
              <Button
                variant="contained"
                color="primary"
                sx={{ m: 1, display: 'block' }}
                onClick={() => props.navigate('/process')}
              >
                신청하기
              </Button>
            </ListItem> */}
          </>
        )}
      </List>
    </Box>
  );

  return (
    <HideOnScroll {...props}>
      <AppBar
        className="drop-shadow"
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{ backgroundColor: bgTransparent ? 'transparent' : 'none' }}
      >
        <Container maxWidth="lg">
          <Toolbar disableGutters className="!min-h-2">
            <Typography
              variant="h1"
              noWrap
              component="a"
              href="/"
              sx={{ display: { xs: 'none', md: 'flex' } }}
            >
              <Box className=" pr-4">
                <img src={logosvg} alt="logosvg" />
              </Box>
            </Typography>
            {/* 디스플레이 모바일 */}
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={toggleDrawer('left', true)}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                anchor={anchor}
                open={drawerState[anchor]}
                variant="temporary"
                onClose={toggleDrawer(anchor, false)}
                sx={{ display: { xs: 'flex', md: 'none' } }}
              >
                {list(anchor)}
              </Drawer>
            </Box>
            {/* 디스플레이 pc */}
            <Typography
              variant="h1"
              noWrap
              component="a"
              href="/"
              sx={{
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                textDecoration: 'none',
                mr: 2,
              }}
            >
              <Box className=" pr-4">
                <img src={logosvg} alt="logosvg" />
              </Box>
            </Typography>
            <Box
              sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}
              className="justify-between"
            >
              <Box className="flex items-center">
                {menu.map((item, index) => (
                  <Typography
                    key={index}
                    sx={{ mx: 2 }}
                    className={
                      active === item.name
                        ? 'text-[#E4782A]  cursor-pointer underline underline-offset-[6px] decoration-2'
                        : 'text-[#169DC5] cursor-pointer'
                    }
                    onClick={() => props.navigate(item.path)}
                  >
                    {item.name}
                  </Typography>
                ))}
                {/* {user && (
                  <Link href="/mypage" className="flex" sx={{ p: 1 }}>
                    <MyAvatar />
                    <Typography
                      variant="body1"
                      component="span"
                      className="pl-1 !font-medium"
                      color={(theme) => theme.palette.primary.main}
                    >
                      공지사항
                    </Typography>
                  </Link>
                )} */}
              </Box>
              <Box className="flex items-center">
                {user ? (
                  <Button
                    onClick={() =>
                      user.mentoringInfoExist
                        ? props.navigate('/mypage')
                        : props.navigate('/mentoring-info')
                    }
                  >
                    <MyAvatar />
                    <Typography
                      variant="body1"
                      component="span"
                      className="pl-1 !font-medium"
                      color={(theme) => theme.palette.primary.main}
                    >
                      내 정보
                    </Typography>
                  </Button>
                ) : (
                  <>
                    <Link className="flex" href="/signin" sx={{ display: 'block' }}>
                      <Typography
                        variant="body1"
                        component="span"
                        className="pl-1 !font-medium"
                        color={(theme) => theme.palette.primary.main}
                      >
                        로그인
                      </Typography>
                    </Link>
                    {/* <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        mr: 2,
                        ml: 4,
                        px: 4,
                        display: 'block',
                        fontSize: '1rem',
                        fontWeight: 'bold',
                      }}
                      onClick={() => props.navigate('/process')}
                    >
                      신청하기
                    </Button> */}
                  </>
                )}
              </Box>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </HideOnScroll>
  );
};

export default withRouter(Header);
