import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { selectUser } from 'store/userSlice';

const ProtectedRoute = ({ children }) => {
  const user = useSelector(selectUser);

  if (user) {
    return <Navigate to="/" />;
  }
  return children;
};

export default ProtectedRoute;
