import { useState } from 'react';
import { Box, Typography } from '@mui/material';
import Stepper from 'components/applications/Stepper';
import MentorInfo from 'components/applications/mentor/MentorInfo';
import MentorDetail from 'components/applications/mentor/MentorDetail';
import MentorApply from 'components/applications/mentor/MentorApply';
import { useSelector } from 'react-redux';
import withRouter from 'utils/withRouter';
import { selectUser } from 'store/userSlice';

const Mentor = (props) => {
  const user = useSelector(selectUser);
  const [activeStep, setActiveStep] = useState(user.mentoringInfoExist ? 2 : 0);
  const stepStatus = useSelector((state) => state.application.mentor[activeStep]);

  const handlePrevStep = () => {
    if (activeStep === 0) {
      props.navigate(-1);
      return;
    }
    setActiveStep(activeStep - 1);
  };

  const stepComponents = {
    0: (
      <MentorInfo
        rows={stepStatus}
        step={activeStep}
        setStep={setActiveStep}
        actionPrevStep={handlePrevStep}
      />
    ),
    1: (
      <MentorDetail
        rows={stepStatus}
        step={activeStep}
        setStep={setActiveStep}
        actionPrevStep={handlePrevStep}
      />
    ),
    2: <MentorApply rows={stepStatus} step={activeStep} actionPrevStep={handlePrevStep} />,
  };

  return (
    <Box className="m-auto w-full max-w-screen-lg">
      <Box className="p-12 pb-0 md:p-16 md:pb-0 ">
        <Stepper steps={['멘토 정보', '교육 상세', '신청 완료']} active={activeStep} />
      </Box>
      <Box className="p-12 md:p-16 text-center box-border">{stepComponents[activeStep]}</Box>
    </Box>
  );
};

export default withRouter(Mentor);
