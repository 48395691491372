export class TuteeModel {
  constructor(name, email, tid = null) {
    this.name = name;
    this.email = email;
    this.tid = tid;
  }

  toString() {
    return `${this.name}, ${this.email}, ${this.tid}`;
  }
}

// Firestore data converter
const tuteeConverter = {
  toFirestore: (tutee) => {
    return {
      name: tutee.name,
      email: tutee.email,
      tid: tutee.tid,
    };
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return new TuteeModel(data.name, data.email, data.tid);
  },
};

export default tuteeConverter;
