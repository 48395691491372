import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { grey, lightBlue } from '@mui/material/colors';
import Root from 'components/Root';
import { limit, orderBy, where } from 'firebase/firestore';
import applicationConverter from 'models/application_model';
import matchingConverter from 'models/matching_model';
import userConverter from 'models/user_model';
import React, { useEffect, useState } from 'react';
import FirestorePath from 'services/firebase_path';
import { getCollectionFuture, setDocument } from 'services/firebase_services';
import styled from 'styled-components';

const AccountStatus = () => {
  const [applicationList, setApplicationList] = useState([]);
  const [menteeList, setMenteeList] = useState({});
  const [tableState, setTableState] = useState(0);

  const [matchingStatus, setMatchingStatus] = useState({});
  let rowIndex = 0;

  const handleChange = (event, index) => {
    // Array.from({
    //   length: 24,
    //   3: 0,
    //   5: 1,
    //   6: 1,
    //   7: 2,
    //   8: 2,
    //   9: 2,
    // })
    // console.log(event.target.value + '--' + index);
    matchingStatus[index] = event.target.value;
    setMatchingStatus({ ...matchingStatus });
  };

  const columns = {
    0: [
      { id: 'no.', label: 'no.', minWidth: 10, align: 'left' },
      { id: '이름', label: '이름', minWidth: 70, align: 'left' },
      { id: '이메일', label: '이메일', minWidth: 30, align: 'left' },
      { id: '연락처', label: '연락처', minWidth: 30, align: 'left' },
      { id: '성별', label: '성별', minWidth: 10, align: 'left' },
      { id: '소속', label: '소속', minWidth: 80, align: 'left' },
      { id: '수업코스', label: '수업코스', minWidth: 100, align: 'left' },
      { id: '과목', label: '과목', minWidth: 100, align: 'left' },
      { id: '기타과목', label: '기타과목', minWidth: 50, align: 'left' },
    ],
    1: [
      { id: 'no.', label: 'no.', minWidth: 10, align: 'left' },
      { id: '이메일', label: '이메일', minWidth: 70, align: 'left' },
      { id: '정보입력여부', label: '정보입력여부', minWidth: 50, align: 'left' },
    ],
    2: [
      { id: 'no.', label: 'no.', minWidth: 10, align: 'left' },
      { id: '이름', label: '이름', minWidth: 70, align: 'left' },
      { id: '이메일', label: '이메일', minWidth: 30, align: 'left' },
      { id: '연락처', label: '연락처', minWidth: 30, align: 'left' },
      { id: '성별', label: '성별', minWidth: 10, align: 'left' },
      { id: '수업코스', label: '수업코스', minWidth: 100, align: 'left' },
      { id: '과목', label: '과목', minWidth: 100, align: 'left' },
      { id: '기타과목', label: '기타과목', minWidth: 50, align: 'left' },
      { id: '매칭신청여부', label: '매칭신청여부', minWidth: 70, align: 'left' },
    ],
    3: [
      { id: 'no.', label: 'no.', minWidth: 10, align: 'left' },
      { id: '이메일', label: '이메일', minWidth: 70, align: 'left' },
      { id: '멘토링정보 입력여부', label: '멘토링정보 입력여부', minWidth: 50, align: 'left' },
    ],
  };

  // 가입+멘토링정보 완료 멘티 불러오는 함수
  async function updateMentee(userType, index) {
    let tempMenteeList = [];

    // application 불러오기
    const docs = await getCollectionFuture(
      FirestorePath.applications(),
      (data, docId) => applicationConverter.fromFirestore(data),
      [
        where('userType', '==', userType),
        // where('matchingStatus', '<', 2),
        // where('availableSubjects', 'array-contains', subject),
        // where('availableCourses', 'array-contains-any', courses),
      ]
    );
    if (docs.length === 0) {
      console.log('no data');
    } else {
      console.log('do something');
      //   console.log(docs);
      // 테스트 계정 멘티는 지워주기
      const temp = docs.filter(
        (doc) =>
          doc.email !== 'bhgt9510@naver.com' &&
          doc.email !== 'bhgt4563@gmail.com' &&
          doc.email !== 'soi1_1@hanmail.net' &&
          doc.email !== 'cwcw0908@gmail.com' &&
          doc.email !== 'ljeongmin0908@gmail.com'
      );
      const result = temp.sort(
        index === 0
          ? (a, b) => (a.name < b.name ? -1 : 1)
          : (a, b) => (a.matchingStatus > b.matchingStatus ? -1 : 1)
      );
      console.log(result);
      tempMenteeList = [...result];
    }

    // 업데이트하기
    setMenteeList({ ...menteeList, [index]: [...tempMenteeList] });
    setTableState(index);
  }

  // 가입만 완료한 멘티 불러오는 함수
  async function updateGaipMentee(userType, index) {
    let tempMenteeList = [];

    // users 불러오기
    const userDocs = await getCollectionFuture(
      FirestorePath.users(),
      (data, docId) => userConverter.fromFirestore(data),
      [
        where('userType', '==', userType),
        // where('matchingStatus', '<', 2),
        // where('availableSubjects', 'array-contains', subject),
        // where('availableCourses', 'array-contains-any', courses),
      ]
    );
    if (userDocs.length === 0) {
      console.log('no data');
    } else {
      console.log('do something');
      //   console.log(userDocs);
      // 테스트 계정 멘티는 지워주기
      const temp = userDocs.filter(
        (doc) =>
          doc.email !== 'bhgt9510@naver.com' &&
          doc.email !== 'bhgt4563@gmail.com' &&
          doc.email !== 'soi1_1@hanmail.net' &&
          doc.email !== 'cwcw0908@gmail.com' &&
          doc.email !== 'ljeongmin0908@gmail.com'
      );
      const result = temp.sort((a, b) => (a.mentoringInfoExist < b.mentoringInfoExist ? -1 : 1));
      console.log(result);
      tempMenteeList = [...result];
    }

    // 업데이트하기
    setMenteeList({ ...menteeList, [index]: [...tempMenteeList] });
    setTableState(index);
  }

  const menteeListLength = Object.keys(menteeList).length;

  return (
    <Box className="flex flex-col w-full pt-12">
      <Box className="flex flex-row gap-8">
        <Button onClick={() => updateMentee('mentee', 0)}>
          1. 가입+정보입력 완료한 🌸멘티 불러오기 -{' '}
          {menteeListLength ? menteeList[0]?.length ?? '0' : '--'}명
        </Button>
        <Button onClick={() => updateGaipMentee('mentee', 1)}>
          2. 가입만 완료한 🌸멘티 불러오기 -{' '}
          {menteeListLength ? menteeList[1]?.length ?? '0' : '--'}명
        </Button>
      </Box>
      <Box className="flex flex-row gap-8">
        <Button onClick={() => updateMentee('mentor', 2)}>
          3. 가입+정보입력 완료한 🌼멘토 불러오기 -{' '}
          {menteeListLength ? menteeList[2]?.length ?? '0' : '--'}명
        </Button>
        <Button onClick={() => updateGaipMentee('mentor', 3)}>
          4. 가입만 완료한 🌼멘토 불러오기 -{' '}
          {menteeListLength ? menteeList[3]?.length ?? '0' : '--'}명
        </Button>
      </Box>
      {menteeListLength ? (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer sx={{}}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns[tableState].map((column, index) => (
                    <TableCell
                      key={index}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {menteeList[tableState].map((mentee, menteeIndex) => {
                  if (tableState === 0) {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={mentee.aid + menteeIndex}>
                        <TableCell align="left">{++rowIndex}</TableCell>
                        <TableCell align="left">{mentee.name}</TableCell>
                        <TableCell align="left">{mentee.email}</TableCell>
                        <TableCell align="left">{mentee.contact}</TableCell>
                        <TableCell align="left">{mentee.gender}</TableCell>
                        <TableCell align="left">{mentee.belong}</TableCell>
                        <TableCell align="left">{mentee.availableCourses.join(', ')}</TableCell>
                        <TableCell align="left">{mentee.availableSubjects.join(', ')}</TableCell>
                        <TableCell align="left">{mentee.extraSubjects}</TableCell>
                      </TableRow>
                    );
                  } else if (tableState === 1) {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={mentee.uid + menteeIndex}>
                        <TableCell align="left">{++rowIndex}</TableCell>
                        <TableCell align="left">{mentee.email}</TableCell>
                        <TableCell align="left">
                          {mentee.mentoringInfoExist ? '완료' : '미작성'}
                        </TableCell>
                      </TableRow>
                    );
                  } else if (tableState === 2) {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={mentee.aid + menteeIndex}>
                        <TableCell align="left">{++rowIndex}</TableCell>
                        <TableCell align="left">{mentee.name}</TableCell>
                        <TableCell align="left">{mentee.email}</TableCell>
                        <TableCell align="left">{mentee.contact}</TableCell>
                        <TableCell align="left">{mentee.gender}</TableCell>
                        <TableCell align="left">{mentee.availableCourses.join(', ')}</TableCell>
                        <TableCell align="left">{mentee.availableSubjects.join(', ')}</TableCell>
                        <TableCell align="left">{mentee.extraSubjects}</TableCell>
                        <TableCell align="left">
                          {mentee.matchingStatus >= 1 ? '완료' : '미신청'}
                        </TableCell>
                      </TableRow>
                    );
                  } else if (tableState === 3) {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={mentee.uid + menteeIndex}>
                        <TableCell align="left">{++rowIndex}</TableCell>
                        <TableCell align="left">{mentee.email}</TableCell>
                        <TableCell align="left">
                          {mentee.mentoringInfoExist ? '완료' : '미작성'}
                        </TableCell>
                      </TableRow>
                    );
                  }
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      ) : (
        <CircularProgress />
      )}
    </Box>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: 20px 0px;
`;

const TestButton = styled.button`
  display: flex;
  justify-content: center;
  width: 240px;
  background-color: lightblue;
  padding: 4px;
`;

const Application = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-bottom: 10px;

  width: 640px;
`;

const ApproveButton = styled.button`
  display: flex;
  justify-content: center;
  width: 80px;
  background-color: ${(isActive) => {
    return isActive.isActive ? 'grey' : 'lightBlue';
  }};
  border-radius: 20px;
  padding: 4px;
`;

export default AccountStatus;

// tunV5ePNIPMfZtSDbbuX | 최은우 | 1
// L0MZCbqLxg6VKzM5dmoJ | 김아연 멘토 | 1
// KIAXSlszEEDgEG0nLJBb | 권시안 | 1
