import { Box, Paper } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Header from './Header';
import Footer from './footer/Footer';

const Root = (props) => {
  const { children, activeMenu = null, bgTransparent = false, bgColor = 'white' } = props;
  const location = useLocation();

  return (
    <Box className="flex flex-col items-center relative box-border w-full bg-zinc-300">
      <Helmet>
        <title>배남프: 배워서 남주자 프로젝트</title>
        <meta property="og:title" content="배남프" />
        <meta
          property="og:description"
          content="안녕하세요! 배남프입니다. 배운것을 나누며 변화를 만들어갑니다."
        />
        <meta property="og:image" content="%PUBLIC_URL%/assets/logosvg.svg" />
        <meta property="og:url" content={`onnuribnp.com${location.pathname}`} />
      </Helmet>
      <Header active={activeMenu} bgTransparent={bgTransparent} />
      <Paper square className="w-full md:min-h-[92vh] pt-16" sx={{ backgroundColor: bgColor }}>
        {children}
      </Paper>
      <Footer />
    </Box>
  );
};

export default Root;
