import { Box, Divider, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/userSlice';

const Info = (props) => {
  const user = useSelector(selectUser);

  const { contactVisible, partner } = props;
  const { currentUserType } = user;
  const { name, gender, birth, email, contact, userType } = partner;

  const courseList = partner.availableCourses;
  const personalityList = partner.personality[userType];

  const today = new Date();
  const birthDate = new Date(birth);
  const age = today.getFullYear() - birthDate.getFullYear();

  const lastName = name.replaceAll(' ', '').substring(0, 1);
  const firstName = name.replaceAll(' ', '').substring(1);
  const profileName = firstName.length > 4 ? lastName : firstName;

  return (
    <Box
      className="flex sm:flex-row flex-col rounded-2xl group/myInfo gap-8 p-5"
      sx={{ backgroundColor: '#F5F5F5' }}
    >
      <Box className="flex items-center sm:px-4 px-0">
        <Box
          className="flex justify-center items-center w-28 h-28 rounded-full"
          sx={{ backgroundColor: '#' + Math.round(Math.random() * 0xffffff).toString(16) }}
        >
          <Typography fontSize="1.75rem" color="white">
            {profileName}
          </Typography>
        </Box>
      </Box>
      <Box className="flex flex-1 flex-col">
        <Box className="flex-1 flex-col justify-between">
          <Box className="flex">
            <Grid container spacing={0}>
              <Grid item xs={5}>
                <Typography variant="body3" fontWeight="bold">
                  이름(성별)
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Box className="flex gap-1">
                  <Typography variant="body3">{name}</Typography>
                  <Typography
                    variant="body3"
                    fontWeight="bold"
                    color={(theme) =>
                      gender === 'M' ? theme.palette.primary.main : theme.palette.error.main
                    }
                  >
                    {gender === 'M' ? '남' : '여'}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box className="flex">
            <Grid container spacing={0}>
              <Grid item xs={5}>
                <Typography variant="body3" fontWeight="bold">
                  생년월일(나이)
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography variant="body3">{`${birth.replaceAll('-', '.')}(${age}세)`}</Typography>
              </Grid>
            </Grid>
          </Box>
          {contactVisible ? (
            <>
              <Box className="flex">
                <Grid container wrap="nowrap" spacing={0}>
                  <Grid item xs={5}>
                    <Typography variant="body3" fontWeight="bold">
                      이메일
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography variant="body3" sx={{ overflowWrap: 'break-word' }}>
                      {email}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box className="flex">
                <Grid container spacing={0}>
                  <Grid item xs={5}>
                    <Typography variant="body3" fontWeight="bold">
                      연락처{currentUserType === 'mentee' ? '(카카오ID)' : ''}
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography variant="body3">
                      {currentUserType === 'mentor' ? '@' : ''}
                      {contact}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </>
          ) : (
            <></>
          )}
        </Box>
        <Box className="py-4">
          <Divider />
        </Box>
        <Box className="flex-1 flex-col">
          <Box className="flex">
            <Grid container spacing={0}>
              <Grid className="text-left" item xs={12}>
                <Typography variant="body3">{personalityList.map((p) => `#${p} `)}</Typography>
              </Grid>
            </Grid>
          </Box>
          <Box className="flex">
            <Grid container spacing={0}>
              <Grid className="flex flex-wrap gap-1 justify-start" item xs={12}>
                {courseList.map((course, index) => (
                  <Typography
                    key={index}
                    className="border rounded-full px-2"
                    variant="body3"
                    sx={{ borderColor: (theme) => theme.palette.figma.gray02 }}
                  >
                    {course}
                  </Typography>
                ))}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Info;
