import { Box, Typography } from '@mui/material';

const Waiting = () => {
  const emojiList = [
    '🎁',
    '🎀',
    '🧸',
    '🎉',
    '🎈',
    '🪩',
    '🇰🇷',
    '⚽️',
    '🎲',
    '♟️',
    '🎸',
    '🚴',
    '🛹',
    '🛝',
    '🥋',
    '☂️',
    '🌸',
    '✨',
    '⭐️',
    '🌼',
    '🍄',
    '🍀',
  ];
  return (
    <Box className="flex flex-col flex-1 justify-center items-center text-center">
      <Typography variant="h1" fontSize="4rem">
        {emojiList[Math.floor(Math.random() * emojiList.length)]}
      </Typography>
      <Typography variant="h1">
        본부에서 매칭을 진행중입니다 :)
        <br />
        조금만 기다려주세요🙌
      </Typography>
    </Box>
  );
};

export default Waiting;
