import { Box, Typography } from '@mui/material';
import Root from 'components/Root';

const PrivacyPolicy = () => {
  return (
    <Root>
      <Box className="flex justify-center py-20 px-20">
        <Box className="max-w-5xl">
          <Typography variant="h1" className="!pb-4">
            개인정보 처리방침 1.0
          </Typography>
          <Box className="border-2 p-10 mb-8">
            <Typography variant="body1" color="primary.main">
              배워자 남주자 프로젝트(이하”배남프”라 함)가 취급하는 모든 개인정보는 관련법령에
              근거하거나 정보주체의 동의에 의하여 수집•보유 및 처리 되고 있습니다.
            </Typography>
            <Typography variant="body1" color="neutral.500" className="!pb-8">
              본 방침은 2023년 6월 22일부터 시행됩니다.
            </Typography>
            <Typography variant="body1" color="neutral.500">
              배남프는 법령의 규정에 따라 수집•보유 및 처리하는 개인정보를 행정업무의 적절한 수행과
              정보주체의 권익을 보호하기 위하여 적법하고 적정하게 취급할 것입니다. 또한, 배남프는
              관련 법령에서 규정한 바에 따라 배남프에서 보유하고 있는 개인정보에 대한 열람 청구권 및
              정정청구권 등 이용자의 권익을 존중하며, 여러분은 이러한 법령상 권익의 침해 등에 대하여
              행정심판법에서 정하는 바에 따라 행정심판을 청구할 수 있으며, 개인정보분쟁조정위원회,
              개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 배남프는 개인정보
              처리방침은 현행「개인정보보호법」에 근거를 두고 있습니다. 이 방침은 별도의 설명이 없는
              한 배남프에서 운용하는 모든 홈페이지에 적용됨을 알려드립니다.
            </Typography>
          </Box>
          <Typography variant="h2" color="neutral.500" className="!pb-8">
            ● 개인정보의 처리 목적
          </Typography>
          <Typography variant="body1" color="neutral.500" className="!pb-8">
            배남프는 개인정보를 다음의 목적 이외의 용도로는 이용하지 않으며 이용 목적이 변경될
            경우에는 동의를 받아 처리하겠습니다.
            <br />
            <br />
            1. 서비스 제공
            <br />
            본인인증, 전자서명인증, 행사등록 및 검색 등 서비스제공과 관련한 목적으로 개인정보를
            처리합니다.
            <br />
            <br />
            2. 민원처리
            <br />
            개인정보열람, 개인정보정정•삭제, 개인정보 처리정지 요구, 개인정보 유출사고 신고, 개인정
            보 침해 사실 신고 접수•처리, 스팸민원고충처리, 해킹신고 등 민원처리를 목적으로 개인정보
            를 처리합니다.
          </Typography>
          <Typography variant="h2" color="neutral.500" className="!pb-8">
            ● 홈페이지에서 운영하는 보안조치
          </Typography>
          <Typography variant="body1" color="neutral.500" className="!pb-8">
            홈페이지 보안 또는 지속적인 서비스를 위해, 배남프는 네트워크 트래픽의 통제는 물론
            불법적으로 정보를 변경하는 등의 시도를 탐지하기 위해 보안프로그램을 운영하고 있습니다.
          </Typography>
          <Typography variant="h2" color="neutral.500" className="!pb-8">
            ● 링크, 배너
          </Typography>
          <Typography variant="body1" color="neutral.500" className="!pb-8">
            배남프가 운영하는 모든 홈페이지의 링크 또는 배너를 클릭하여 배남프의 내부 또는 외부기관
            홈페이지로 옮겨갈 경우 해당 홈페이지는 운영기관이 게시한 개인정보 처리방침이 적용되므로
            해당 홈페이지의 개인정보 처리방침을 확인하시기 바랍니다.
          </Typography>
          <Typography variant="h2" color="neutral.500" className="!pb-8">
            ● 개인정보의 수집 및 보유
          </Typography>
          <Typography variant="body1" color="neutral.500" className="!pb-8">
            배남프는 법령의 규정과 정보주체의 동의에 의해서만 개인정보를 수집•보유합니다. 배남프가
            법령의 규정에 근거하여 수집•보유하고 있는 주요 개인정보파일은 다음과 같습니다.
            <br />
            <br />- 개인정보 수집항목: 이름/성별/생년월일/공동체/
            다락방,순/연락처/이메일/직업/전공/교육경력/배남프참여여부/수업가능과목/멘토링가능연령대/현재
            섬기고 있는 사역/배남프 멘토를 지원하게 된 계기/나의신앙관
            <br />
            <br />
            - 수집 목적: 서비스 제공
            <br />
            <br />- 보유 및 이용기간: 배남프 서비스 종료
          </Typography>
          <Typography variant="h2" color="neutral.500" className="!pb-8">
            ● 개인정보의 3자 제공
          </Typography>
          <Typography variant="body1" color="neutral.500" className="!pb-8">
            배남프가 수집•보유하고 있는 개인정보는 이용자의 동의 없이는 제3자에게 제공하지 않으며
            다음의 경우에는 개인정보를 제3자에게 제공할 수 있습니다.
            <br />
            <br />
            1. 정보주체로부터 별도의 동의를 받는 경우
            <br />
            2. 법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여 불가피한 경우
            <br />
            3. 정보주체 또는 그 법정대리인이 의사표시를 할 수 없는 상태에 있거나 주소불명 등으로
            사전동의를 받을 수 없는 경우로서 명백히 정보주체 또는 제3자의 급박한 생명, 신체, 재산 의
            이익을 위하여 필요하다고 인정되는 경우
            <br />
            4. 다음 각 호의 어느 하나에 해당하는 경우에는 정보주체 또는 제3자의 이익을 부당하게 침해
            할 우려가 있을 때를 제외하고는 이용자의 개인정보를 목적 외의 용도로 이용하거나 이를
            제3자에게 제공할 수 있습니다,
            <br />
            <br />
            가. 정보주체로부터 별도의 동의를 받은 경우
            <br />
            나. 다른 법률에 특별한 규정이 있는 경우
            <br />
            다. 정보주체 도는 그 법정대리인이 의사표시를 할 수 없는 상태에 있거나 주소불명 등으로
            사전 동의를 받을 수 없는 경우로서 명백히 정보주체 또는 제3자의 급박한 생명, 신체, 재산의
            이익을 위하여 필요 하다고 인정되는 경우
            <br />
            라. 통계작성 및 학술연구 등의 목적을 위하여 필요한 경우로서 특정 개인을 알아볼 수 없 는
            형태의 개인 정보를 제공하는 경우
            <br />
            마. 개인정보를 목적 외의 용도로 이용하거나 이를 제3자에게 제공하지 아니하면 다른 법률
            에서 정하는 소관 업무를 수행할 수 없는 경우로서 보호위원회의 심의•의결을 거친 경우
            <br />
            바. 조약, 그 밖의 국제협정의 이행을 위하여 외국정부 또는 국제기구에 제공하기 위하여
            필요한 경우
            <br />
            사. 범죄의 수사와 공소의 제기 및 유지를 위하여 필요한 경우
            <br />
            아. 법원의 재판업무 수행을 위하여 필요한 경우
            <br />
            자. 형(刑)및 감호, 보호처분의 집행을 위하여 필요한 경우
            <br />
          </Typography>
          <Typography variant="h2" color="neutral.500" className="!pb-8">
            ● 정보주체의 권리•의무 및 그 행사방법
          </Typography>
          <Typography variant="body1" color="neutral.500" className="!pb-8">
            이용자는 정보주체로서 다음과 같은 권리를 행사할 수 있습니다.
            <br />
            <br />
            1. 개인정보 열람 요구 : 배남프에서 보유하고 있는 개인정보파일은「개인정보보호법」제35조
            (개인정보의 열람)에 따라 열람을 요구할 수 있습니다. 다만 개인정보 열람 요구는「개인정
            보보호법」제35조제5항에 의하여 다음과 같이 제한될 수 있습니다.
            <br />
            가. 법률에 따라 열람이 금지되거나 제한되는 경우
            <br />
            나. 다른 사람의 생명•신체를 해할 우려가 있거나 다른 사람의 재산과 그 밖의 이익을 부당
            하게 침해할 우려가 있는 경우
            <br />
            <br />
            2. 개인정보 정정•삭제 요구 : 배남프에서 보유하고 있는 개인정보파일에
            대해서는「개인정보보호법」제36조(개인정보의 정정•삭제)에 따라 배남프에 정정•삭제를
            요구할 수 있습니다. 다만, 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는
            경우에는 그 삭제를 요구할 수 없습니다.
            <br />
            <br />
            3. 개인정보 처리정지 요구 : 배남프에서 보유하고 있는 개인정보파일에 대해서는
            「개인정보보호법」제37조(개인정보의 처리정지 등)에 따라 배남프에 개인정보의 처리정지를
            요구할 수 있습니다. 또한 만 14세미만 아동의 법정대리인은 배남프에 그 아동의 개인정보의
            열람, 정정•삭제, 처리정지 요구를 할 수 있습니다. 다만, 개인정보 처리정지 요구 시
            「개인정보보호법」제37조제2항에 의하여 처리정지 요구가 거절 될 수 있습니다.
            <br />
            가. 법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여 불가피한 경우
            <br />
            나. 다른 사람의 생명•신체를 해할 우려가 있거나 다른 사람의 재산과 그 밖의 이익을 부당
            하게 침해할 우려가 있는 경우
            <br />
            다. 개인정보를 처리하지 아니하면 정보주체와 약정한 서비스를 제공하지 못하는 등 계약의
            이행이 곤란한 경우로서 정보주체가 그 계약의 해지 의사를 명확하게 밝히지 아니한 경우
            <br />
            <br />
            4. 개인정보의 열람, 정정•삭제, 처리정지 요구에 대해서는 10일 이내에 해당 사항에 대한
            배남프의 조치를 통지합니다. 개인정보의 열람, 정정•삭제, 처리정지 요구는 해당 부서를
            통해서 가능합니다.
            <br />
            <br />
            5. 개인정보의 열람, 정정•삭제, 처리정지 요구 접수•처리 부서와 담당자는 아래와 같습니다.
            <br />
            부 서 : 배남프 <br />
            담당자: 윤한규
            <br />
            이메일: : bhgt4563@gmail.com
            <br />
          </Typography>
          <Typography variant="h2" color="neutral.500" className="!pb-8">
            ● 파기
          </Typography>
          <Typography variant="body1" color="neutral.500" className="!pb-8">
            배남프는 원칙적으로 개인정보의 보유기간이 경과했거나 처리목적이 달성된 경우에는 지체없이
            해당 개인정보를 파기합니다. 다만, 다른 법률에 따라 보존하여야 하는 경우에는 그러 하지
            않습니다. 파기의 절차, 기한 및 방법은 다음과 같습니다.
            <br />
            <br />
            1. 파기절차
            <br />
            이용자가 입력한 정보는 보유기간이 경과했거나 처리목적의 달성 후 내부 방침 및 관련 법령에
            따라 파기 합니다.
            <br />
            <br />
            2. 파기기한
            <br />
            이용자의 개인정보는 개인정보의 보유기간이 경과된 경우 보유기간의 종료일로부터 5일
            이내에, 개인정보의 처리 목적 달성 등 그 개인정보가 불필요하게 되었을 때에는 개인정 보의
            처리가 불필요한 것으로 인정되는 날로부터 5일 이내에 그 개인정보를 파기합니다.
            <br />
            <br />
            3. 파기방법
            <br />
            배남프에서 처리하는 개인정보를 파기할 때에는 다음의 방법으로 파기 합니다.
            <br />
            가. 전자적 파일 형태인 경우 : 복원이 불가능한 방법으로 영구삭제
            <br />
            나. 전자적 파일의 형태 외의 기록물, 인쇄물, 서면, 그 밖의 기록매체인 경우 : 파쇄 또는
            소각 <br />
          </Typography>
          <Typography variant="h2" color="neutral.500" className="!pb-8">
            ● 개인정보의 안정성 확보 조치
          </Typography>
          <Typography variant="body1" color="neutral.500" className="!pb-8">
            배남프는「개인정보보호법」제29조에 따라 다음과 같이 안전성 확보에 필요한 기술적, 관리적,
            물리적 조치를 하고 있습니다.
            <br />
            <br />
            1. 개인정보취급자 지정의 최소화 및 교육
            <br />
            개인정보취급자의 지정을 최소화하고 정기적인 교육을 시행하고 있습니다.
            <br />
            <br />
            2. 개인정보에 대한 접근 제한
            <br />
            개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소를 통하여
            개인정보에 대한 접근을 통제하고, 침입차단시스템과 탐지시스템을 이용하여 외부로부터 의
            무단 접근을 통제하고 있습니다. 또한 권한부여, 변경 또는 말소에 대한 내역을 기록 하고, 그
            기록을 최소 3년간 보관하고 있습니다.
            <br />
            <br />
            3. 접속기록의 보관 및 위 변조 방지
            <br />
            개인정보처리시스템에 접속한 기록(웹 로그,요약정보 등)을 최소 6개월이상 보관, 관리 하고
            있으며, 접속 기록이 위 변조 및 도난, 분실되지 않도록 관리하고 있습니다.
            <br />
            <br />
            4. 개인정보의 암호화
            <br />
            이용자의 개인정보는 암호화 되어 저장 및 관리되고 있습니다. 또한 중요한 데이터는 저장 및
            전송 시 암호화하여 사용하는 등의 별도 보안기능을 사용하고 있습니다.
            <br />
            <br />
            5. 해킹 등에 대비한 기술적 대책
            <br />
            배남프는 해킹이나 컴퓨터 바이러스 등에 대한 개인정보 유출 및 훼손을 막기 위하여
            보안프로그램을 설치하고 주기적인 갱신•점검을 하며 외부로부터 접근이 통제된 구역에
            시스템을 설치하고 기술적, 물리적으로 감시 및 차단하고 있습니다.
            <br />
            <br />
            6. 비인가자에 대한 출입 통제
            <br />
            개인정보를 보관하고 있는 개인정보시스템의 물리적 보관 장소를 별도로 두고 이에 대해
            출입통제 절차를 수립, 운영하고 있습니다.
            <br />
          </Typography>
          <Typography variant="h2" color="neutral.500" className="!pb-8">
            ● 개인정보 보호책임자
          </Typography>
          <Typography variant="body1" color="neutral.500" className="!pb-8">
            배남프는 개인정보를 보호하고 개인정보와 관련된 사항을 처리하고 위하여 아래와 같이
            개인정보 책임자 및 실무담당자를 지정하고 있습니다.
            <br />
            <br />
            - 개인정보 보호책임자
            <br />
            담당부서: 배남프
            <br />
            성명: 최성민목사
            <br />
            전화: 3215-7527
            <br />
            팩스: 794-3399
            <br />
            이메일: masome@onnuri.org
            <br />
            <br />
            - 개인정보 보호담당자
            <br />
            담당부서: 배남프
            <br />
            성명: 최종현목사
            <br />
            전화: 3215-7527
            <br />
            팩스: 794-3399
            <br />
            이메일: homelyjh@onnuri.org
            <br />
          </Typography>
          <Typography variant="h2" color="neutral.500" className="!pb-8">
            ● 개인정보 처리방침의 변경
          </Typography>
          <Typography variant="body1" color="neutral.500" className="!pb-8">
            이 개인정보 처리방침은 시행일로부터 적용되며, 법령 및 방침에 다른 변경내용의 추가, 삭제
            및 정정이 있는 경우에는 가능한 변경사항의 시행 7일 전부터 공지사항을 통하여 고지할
            것입니다.
            <br />
            <br />
            시행일자: 2023년6월22일
            <br />
          </Typography>
          <Typography variant="h2" color="neutral.500" className="!pb-8">
            ● 권익침해 구제방법
          </Typography>
          <Typography variant="body1" color="neutral.500" className="!pb-8">
            정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터
            넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖 에
            기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.
            <br />
            <br />
            1. 개인정보분쟁조정위원회 : (국번없이) 118(내선2번)
            <br />
            2. 정보보호마크인증위원회 : 02-580-0533~4 (http://eprivacy.or.kr) <br />
            3. 대검찰청 사이버범죄수사단 : 02-3480-3582 (http://www.spo.go.kr/) <br />
            4. 경찰청 사이버테러대응센터 : 1566-0112 (http://www.netan.go.kr/) <br />
          </Typography>
        </Box>
      </Box>
    </Root>
  );
};

export default PrivacyPolicy;
