import { Box, Button, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material';
import { where } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import FirestorePath from 'services/firebase_path';
import { getCollectionFutureCount } from 'services/firebase_services';
import { selectUser } from 'store/userSlice';
import AccountStatus from './AccountStatus';

const CheckStatus = () => {
  const user = useSelector(selectUser);

  const [buttonState, setButtonState] = useState(0);
  const [sumComponent, setSumComponent] = useState(<Box> </Box>);

  const [mentorLength, setMentorLength] = useState(0);
  const [menteeLength, setMenteeLength] = useState(0);
  const [matchingLength, setMatchingLength] = useState(0);
  const [goodMentorLength, setGoodMentorLength] = useState(0);

  useEffect(() => {
    console.log('buttonState');
    console.log(buttonState);
    // switch (`${buttonState}`) {
    //   case '0':
    //     setSumComponent(
    //       <Box className="py-12">
    //         멘토: {mentorLength} 명
    //         <br />
    //         멘티: {menteeLength} 명
    //       </Box>
    //     );
    //     break;
    //   case '1':
    //     setSumComponent(
    //       <Box className="py-12">
    //         완료 멘토 수: {goodMentorLength} 명<br />
    //         미완료 멘토 수(전체-완료): {mentorLength - goodMentorLength} 명
    //       </Box>
    //     );
    //     break;
    //   case '2':
    //     setSumComponent(<Box className="py-12">매칭 총: {matchingLength} 건</Box>);
    //     break;
    // }
  }, [buttonState, setButtonState]);

  if (user) {
    if (user.userType === 'admin') {
      return (
        <Box className="flex flex-col p-20">
          <Box className="pb-12">
            <Typography fontSize={'2rem'} fontWeight={'bold'}>
              현황 체크 어드민 페이지
            </Typography>
            <Typography fontSize={'1rem'} color="red">
              관리자만 접근할 수 있는 페이지입니다. 아래 버튼을 클릭해서 데이터를 조회할 수
              있습니다.
              <br />
              버튼 클릭 당 서버 부하가 큰 작업입니다. 과하게 사용시 서버비용이 발생할 수 있습니다.
              <br />
            </Typography>
          </Box>
          <Box className="flex flex-wrap gap-4 pb-12">
            <Button
              variant="contained"
              onClick={async () => {
                const count = await getCollectionFutureCount(FirestorePath.applications(), [
                  where('userType', '==', 'mentor'),
                ]);

                if (count === undefined) {
                  console.log('no data');
                } else {
                  //   console.log('do something');
                  console.log(count);

                  setMentorLength(count);
                  setButtonState(0);
                }
              }}
            >
              가입 + 멘토링 정보 입력 완료한 '멘토' 현황보기
            </Button>
            <Button
              variant="contained"
              onClick={async () => {
                const count = await getCollectionFutureCount(FirestorePath.applications(), [
                  where('userType', '==', 'mentee'),
                ]);

                if (count === undefined) {
                  console.log('no data');
                } else {
                  //   console.log('do something');
                  console.log(count);

                  setMenteeLength(count);
                  setButtonState(0);
                }
              }}
            >
              가입 + 멘토링 정보 입력 완료한 '멘티' 현황보기
            </Button>
            {/*  */}
            <Button
              variant="contained"
              onClick={async () => {
                const count = await getCollectionFutureCount(FirestorePath.matchings(), [
                  //   where('userType', '==', 'mentor'),
                ]);

                if (count === undefined) {
                  console.log('no data');
                } else {
                  //   console.log('do something');
                  console.log(count);

                  setMatchingLength(count);
                  setButtonState(2);
                }
              }}
            >
              제출된 매칭신청 현황보기
            </Button>
            <Button
              variant="contained"
              onClick={async () => {
                const count = await getCollectionFutureCount(FirestorePath.applications(), [
                  where('userType', '==', 'mentor'),
                  where('matchingStatus', '==', 1),
                ]);

                if (count === undefined) {
                  console.log('no data');
                } else {
                  //   console.log('do something');
                  console.log(count);

                  setGoodMentorLength(count);
                  setButtonState(1);
                }
              }}
            >
              가입+멘토링정보+매칭신청 까지 완료한 멘토 수
            </Button>
            {/*  */}
          </Box>
          <Box>
            <Box className="py-4">
              가입+멘토링정보 완료한 멘토: {mentorLength} 명
              <br />
              가입+멘토링정보 완료한 멘티: {menteeLength} 명
            </Box>
          </Box>
          <Box className="py-4">매칭 총: {matchingLength} 건</Box>
          <Box className="py-4">
            완료 멘토 수: {goodMentorLength} 명<br />
            미완료 멘토 수(전체-완료): {mentorLength - goodMentorLength} 명
          </Box>
          <AccountStatus />
          {/* {buttonState === 0 ? (
            <Box>
              <Box className="py-12">
                멘토: {mentorLength} 명
                <br />
                멘티: {menteeLength} 명
              </Box>
              <Box sx={{ width: '100%', height: 400, maxWidth: 360, bgcolor: 'background.paper' }}>
                <FixedSizeList
                  height={400}
                  width={500}
                  itemSize={46}
                  itemCount={200}
                  overscanCount={5}
                >
                  {renderRow}
                </FixedSizeList>
              </Box>
            </Box>
          ) : buttonState === 1 ? (
            <Box className="py-12">
              완료 멘토 수: {goodMentorLength} 명<br />
              미완료 멘토 수(전체-완료): {mentorLength - goodMentorLength} 명
            </Box>
          ) : (
            <Box className="py-12">매칭 총: {matchingLength} 건</Box>
          )} */}
        </Box>
      );
    }
    return <Navigate to="/" />;
  }
  return <Box> </Box>;
};

function renderRow(props) {
  const { index, style } = props;

  return (
    <ListItem style={style} key={index} component="div" disablePadding>
      <ListItemButton>
        <ListItemText primary={`${index} ${index + 1}`} />
      </ListItemButton>
    </ListItem>
  );
}

export default CheckStatus;
