import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import hope from 'assets/about/hope.svg';
import wisdom from 'assets/about/wisdom.svg';
import love from 'assets/about/love.svg';

const AboutKeyValues = () => {
  return (
    <Box className="flex justify-center md:py-20 p-20">
      <Box className="flex flex-col justify-center items-start max-w-5xl" sx={{ width: '1024px' }}>
        <Typography variant="h1" color="primary" className="!pb-4">
          핵심가치
        </Typography>

        {/* Hope 파트 */}
        <Box className="flex lg:flex-row flex-col-reverse w-full">
          <Grid container className="lg:py-0 py-16">
            <Grid className="flex justify-center items-center" item xs={12}>
              <Box className="flex flex-col justify-center items-start px-4 lg:px-10">
                <Box className="flex flex-row gap-2">
                  <Typography color="text" fontSize="1.5rem" fontWeight="bold" lineHeight="100%">
                    Hope.
                  </Typography>
                  <Typography
                    color="text.black"
                    fontSize="1.25rem"
                    fontWeight="medium"
                    lineHeight="120%"
                  >
                    미래를 향한 소망을 품습니다.
                  </Typography>
                </Box>
                <Typography
                  color="text.black"
                  fontSize="1rem"
                  fontWeight="regular"
                  lineHeight="130%"
                >
                  <br />
                  배남프는 한 번으로 끝나는 프로젝트가 아닙니다. 하나님의 계획에 동참하고 계신
                  선교사님들의 자녀들, 한국에 정착하고 있는 다문화 가정의 자녀들, 더 나아가 교육의
                  사각지대에 있는 다양한 환경의 아이들에게 양질의 교육 환경을 제공하고자 합니다.
                  이를 통해 다음 세대의 아이들이 하나님의 비전을 온전히 품는 미래로 향하길
                  소망합니다.
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container className="lg:justify-normal justify-center">
            <img src={hope} alt="hope" />
          </Grid>
        </Box>

        {/* Wisdom 파트 */}
        <Box className="flex lg:flex-row flex-col w-full">
          <Grid container className="lg:justify-normal justify-center">
            <img src={wisdom} alt="wisdom" />
          </Grid>
          <Grid container className="lg:py-0 py-16">
            <Grid className="flex justify-center items-center" item xs={12}>
              <Box className="flex flex-col justify-center items-start px-4 lg:px-10">
                <Box className="flex flex-row gap-2">
                  <Typography color="text" fontSize="1.5rem" fontWeight="bold" lineHeight="100%">
                    Wisdom.
                  </Typography>
                  <Typography
                    color="text.black"
                    fontSize="1.25rem"
                    fontWeight="medium"
                    lineHeight="120%"
                  >
                    진리를 위한 지혜를 추구합니다.
                  </Typography>
                </Box>
                <Typography
                  color="text.black"
                  fontSize="1rem"
                  fontWeight="regular"
                  lineHeight="130%"
                >
                  <br />
                  단순한 지식을 전달할 수 있는 방법은 많습니다. 하지만 점점혼란스러워지고 있는 세상
                  속에서 자녀들을 성경적 가치로 양육할 수 있는 선생님을 찾는 것은 쉬운 일이
                  아닙니다.배남프를 통해 다음 세대의 아이들이 세상 속에서 하나님의 지혜를 구하고,
                  진리를 추구하는 삶을 살아갈 수 있길 원합니다.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/* Love 파트 */}
        <Box className="flex lg:flex-row flex-col-reverse w-full">
          <Grid container className="lg:py-0 py-16">
            <Grid className="flex justify-center items-center" item xs={12}>
              <Box className="flex flex-col justify-center items-start px-4 lg:px-10">
                <Box className="flex flex-row gap-2">
                  <Typography color="text" fontSize="1.5rem" fontWeight="bold" lineHeight="100%">
                    Love.
                  </Typography>
                  <Typography
                    color="text.black"
                    fontSize="1.25rem"
                    fontWeight="medium"
                    lineHeight="120%"
                  >
                    끝없는 사랑을 전하기 원합니다.
                  </Typography>
                </Box>
                <Typography
                  color="text.black"
                  fontSize="1rem"
                  fontWeight="regular"
                  lineHeight="130%"
                >
                  <br />
                  하나님께서 우리에게 주시는 사랑은 끝이 없습니다. 우리를 가득 채우는 예수님의
                  십자가 사랑을 다음 세대인 아이들에게 흘려보내는 것은 하나님께서 우리에게 허락하신
                  축복이자 사명입니다. 손에서 손으로 전해지는 끝없는 사랑을 통해 이 땅에 하나님
                  나라를 세워갑니다.
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container className="lg:justify-normal justify-center">
            <img src={love} alt="love" />
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default AboutKeyValues;
