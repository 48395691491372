import { createTheme } from '@mui/material/styles';

export const defaultTheme = createTheme({
  typography: {
    fontFamily: "'Noto Sans KR', 'Roboto', 'Helvetica', 'Arial', sans-serif",
    h1: { fontWeight: 700, fontSize: '1.5rem', lineHeight: '160%' },
    h2: {
      fontWeight: 600,
      fontSize: '1.25rem',
      lineHeight: '160%',
    },
    body1: { fontWeight: 600, fontSize: '1rem', lineHeight: '160%' },
    body3: { fontWeight: 500, fontSize: '0.85rem', lineHeight: '160%' },
    xl: { fontWeight: 700, fontSize: '1.25rem', lineHeight: '160%' },
    label1: {
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: '1rem',
    },
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#169DC5',
      lightBlue: '#00D1FF',
      light: '#e7f5f9',
      dark: '#0b4f63',
      blue2: '#86D4E3',
      blue3: '#E1F5F8',
    },
    error: {
      main: '#E4782A',
    },
    warning: {
      main: '#EEAA26',
    },
    background: {
      default: '#ffffff',
      dark: '#000000',
    },
    text: {
      primary: '#000000',
      secondary: '#7D7C99',
      warning: '#EEAA26',
      disabled: '#9695B6',
      white: '#ffffff',
      gray: '#ADADAD',
    },
    figma: {
      blue01: '#169DC5',
      blue02: '#86D4E3',
      blue03: '#E1F5F8',
      orange01: '#E4782A',
      orange02: '#E29341',
      orange03: '#FFDCB8',
      gray01: '#A3A3A3',
      gray02: '#9C9C9C',
      gray03: '#DDDDDD',
      yellow03: '#FFF7BE',
    },
    neutral: {
      100: '#D7D5EE',
      200: '#AFAED3',
      300: '#9695B6',
      400: '#7D7C99',
      500: '#5F5E78',
      600: '#49485C',
      700: '#2B2A39',
      800: '#23222F',
      900: '#181820',
    },
  },
  components: {
    MuiListItemButton: {
      styleOverrides: {
        root: {
          color: '#169DC5',
          '&:hover': {
            backgroundColor: '#8BCEE2',
            color: '#ffffff',
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          borderRadius: '999px',
          padding: '0.125rem 1.5rem',
          fontSize: '1rem',
          '&.formButton': {
            padding: '0.5rem 1rem',
            margin: 5,
            fontSize: '1.25rem',
            fontWeight: 600,
            boxShadow: 'none',
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
          fontWeight: 600,
          fontSize: '1rem',
          display: 'inline-flex',
          alignItems: 'center',
          '&:hover': {
            textDecoration: 'none',
            color: '#E4782A',
          },
          '&.active': {
            color: '#E4782A',
            borderBottom: '2px solid #E4782A',
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          padding: '0.75rem 2.5rem',
          backgroundColor: '#F5F5F5',
          borderTop: 'none',
          // borderBottom: '1px solid #dddddd',
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          backgroundColor: '#F5F5F5',
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          borderRadius: '12rem',
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          '& .tHead': {
            color: '#169DC5',
            borderTop: '0.125rem solid #169DC5',
            borderBottom: '0.062rem solid #169DC5',
          },
        },
      },
    },
  },
  // typography: {
  //   fontFamily: "'Spoqa Han Sans Neo', 'Roboto', 'Helvetica', 'Arial', sans-serif",
  //   fontSize: 16,
  //   h1: { fontWeight: 700, fontSize: '2.5rem', lineHeight: 1.25 }, // 40
  //   h2: { fontWeight: 700, fontSize: '2rem' }, // 32
  //   h3: { fontWeight: 700, fontSize: '1.5rem' }, // 24
  //   subtitle1: { fontWeight: 500, fontSize: '2rem', lineHeight: 1.25 }, // 32
  //   subtitle2: { fontWeight: 400, fontSize: '1.75rem' }, // 28
  //   subtitle3: { fontWeight: 500, fontSize: '1.5rem' }, // 24
  //   body1: { fontWeight: 400, fontSize: '2rem', lineHeight: 1 }, // 30
  //   body2: { fontWeight: 400, fontSize: '1.5rem', lineHeight: 1.5, letterSpacing: '-0.02em' }, // 24
  //   caption: { fontWeight: 400, fontSize: '1.25rem', lineHeight: 1.5 }, // 20
  //   button: { fontWeight: 400, fontSize: '1.5rem ' }, // 24
  // },
  // components: {
  //   MuiButton: {
  //     variants: [
  //       {
  //         props: { variant: 'line' },
  //         style: {
  //           backgroundColor: 'transparent',
  //           '&.Mui-focusVisible': {
  //             textDecoration: 'underline',
  //             textUnderlineOffset: '13px',
  //             // textDecorationColor: 'blue',
  //             backgroundColor: 'transparent',
  //           },
  //           '&:focus': {
  //             backgroundColor: 'transparent',
  //           },
  //           '&.active': {
  //             backgroundColor: 'transparent',
  //             '&:focus': {
  //               backgroundColor: 'transparent',
  //             },
  //           },
  //         },
  //       },
  //     ],
  //     styleOverrides: {
  //       rootroot: ({ theme }) => ({
  //         padding: `${theme.spacing(2)} ${theme.spacing(6)}`,
  //         borderRadius: '9999px',
  //         boxShadow: 'none',
  //         textTransform: 'none',
  //         '&:hover': {
  //           boxShadow: 'none',
  //         },
  //         '&.active': {
  //           backgroundColor: theme.palette.primary.main,
  //           color: theme.palette.text.primary,
  //           '&.Mui-focusVisible': {
  //             backgroundColor: theme.palette.primary.main,
  //             color: theme.palette.text.primary,
  //             outline: 'none',
  //             border: '0',
  //             outlineColor: 'transparent',
  //           },
  //         },
  //       }),
  //       text: ({ theme }) => ({
  //         color: theme.palette.text.primary,
  //         '&.Mui-focusVisible': {
  //           color: theme.palette.text.primary,
  //           backgroundColor: 'rgba(255, 255, 255, 0.2)',
  //         },
  //         '&.active': {
  //           backgroundColor: 'rgba(255, 255, 255, 0.35)',
  //         },
  //       }),
  //       contained: ({ theme }) => ({
  //         backgroundColor: theme.palette.neutral[800],
  //         color: theme.palette.neutral[400],
  //         '&.Mui-focusVisible': {
  //           backgroundColor: theme.palette.neutral[800],
  //           color: theme.palette.neutral[200],
  //           outline: 'solid',
  //           border: '1px',
  //           outlineColor: theme.palette.neutral[200],
  //         },
  //       }),
  //       outlined: ({ theme }) => ({
  //         border: `1px solid ${theme.palette.neutral[500]}`,
  //         color: theme.palette.neutral[500],
  //         '& path': {
  //           fill: theme.palette.neutral[500],
  //         },
  //         '&.Mui-focusVisible': {
  //           color: theme.palette.text.primary,
  //           border: `1px solid ${theme.palette.text.primary}`,
  //           '& path': {
  //             fill: theme.palette.text.primary,
  //           },
  //         },
  //       }),
  //     },
  //   },
  // MuiButtonBase: {
  //   defaultProps: {
  //     disableRipple: true,
  //   },
  //   styleOverrides: {
  //     root: {
  //       // hover시 배경 컬러 레드로 변경
  //       '&:hover': {
  //         color: '#FF0000',
  //       },
  //     },
  //   },
  // },
  // MuiDrawer: {
  //   styleOverrides: {
  //     paper: ({ theme }) => ({
  //       backgroundColor: '#16161C',
  //       color: theme.palette.text.primary,
  //       backgroundImage: 'none',
  //     }),
  //   },
  // },
  //   MuiInput: {
  //     styleOverrides: {
  //       root: ({ theme }) => ({
  //         padding: `${theme.spacing(6)} 0`,
  //         borderBottom: `1px solid ${theme.palette.neutral[400]}`,
  //         '& input:-webkit-autofill': {
  //           WebkitTextFillColor: '#fff',
  //           WebkitBoxShadow: '0 0 0px 1000px #000 inset',
  //           boxShadow: '0 0 0px 1000px #000 inset',
  //           transition: 'background-color 5000s ease-in-out 0s',
  //         },
  //         '& input:autofill': {
  //           WebkitTextFillColor: '#fff',
  //           WebkitBoxShadow: '0 0 0px 1000px #000 inset',
  //           boxShadow: '0 0 0px 1000px #000 inset',
  //           transition: 'background-color 5000s ease-in-out 0s',
  //         },
  //         '&:placeholder': {
  //           color: theme.palette.neutral[400],
  //         },
  //         '&:after': {
  //           color: `${theme.palette.text.primary}`,
  //           borderBottom: `2px solid ${theme.palette.text.primary}`,
  //           transition: 'none',
  //         },
  //         '&:before': {
  //           transition: 'none',
  //         },
  //       }),
  //     },
  //   },
  //   MuiSwitch: {
  //     styleOverrides: {
  //       root: () => ({
  //         width: '106px',
  //         height: '64px',
  //         padding: '14px',
  //       }),
  //       switchBase: ({ theme }) => ({
  //         color: `${theme.palette.neutral[400]}`,
  //         '&.Mui-checked': {
  //           transform: 'translateX(42px)',
  //           color: `${theme.palette.primary.main}`,
  //           '&+.MuiSwitch-track': {
  //             opacity: 0.6,
  //           },
  //         },
  //       }),
  //       thumb: () => ({
  //         width: '46px',
  //         height: '46px',
  //         // color: `${theme.palette.neutral[300]}`,
  //       }),
  //       track: ({ theme }) => ({
  //         borderRadius: '16px',
  //         backgroundColor: `${theme.palette.neutral[500]}`,
  //         opacity: 0.6,
  //       }),
  //     },
  //   },
  // },
});
