import styled from '@emotion/styled';

const TimeBox = styled.div`
  display: inline-flex;
  white-space: nowrap;
  align-items: center;
  & > label {
    padding: 0.5rem 1rem;
    background-color: #fff;
    border: 1px solid #169dc5;
    color: #169dc5;
    /* border-radius: 6rem; */
    /* margin: 5px; */
    cursor: pointer;
  }
  & > input[type='checkbox'] {
    display: none;
  }
  & > input[type='checkbox']:checked + label {
    color: #fff;
    background-color: #169dc5;
  }
`;

const Basicbox = styled.div`
  display: inline-flex;
  align-items: center;
  & > label {
    padding: 0.5rem 1.2rem;
    background-color: #fff;
    border: 1px solid #169dc5;
    color: #169dc5;
    border-radius: 6rem;
    margin: 5px;
    cursor: pointer;
  }
  & > input[type='checkbox'] {
    display: none;
  }
  & > input[type='checkbox']:checked + label {
    color: #fff;
    background-color: #169dc5;
  }
`;

const Squarebox = styled.div`
  display: inline-flex;
  align-items: center;
  & > label {
    padding: 0.5rem 0.8rem;
    background-color: #fff;
    border: 1px solid #169dc5;
    color: #169dc5;
    border-radius: 0.5rem;
    margin: 3px;
    cursor: pointer;
  }
  & > input[type='checkbox'] {
    display: none;
  }
  & > input[type='checkbox']:checked + label {
    color: #fff;
    background-color: #169dc5;
  }
`;

export const CustomTimeCheckbox = (props) => {
  const { name, label, dataKey, data, actionChange } = props;
  const value = `${dataKey}-${label}`;

  return (
    <TimeBox>
      <input
        type="checkbox"
        id={`${name}-${value}`}
        name={name}
        value={value}
        checked={data?.includes(value)}
        onChange={actionChange}
      />
      <label htmlFor={`${name}-${value}`}>{label}</label>
    </TimeBox>
  );
};

export const CustomBasicCheckbox = (props) => {
  const { name, label, data, actionChange } = props;

  return (
    <Basicbox>
      <input
        type="checkbox"
        id={`${name}-${label}`}
        name={name}
        value={label}
        checked={data?.includes(label)}
        onChange={actionChange}
      />
      <label htmlFor={`${name}-${label}`}>{label}</label>
    </Basicbox>
  );
};

export const CustomSquareCheckbox = (props) => {
  const { name, label, data, actionChange } = props;

  return (
    <Squarebox>
      <input
        type="checkbox"
        id={`${name}-${label}`}
        name={name}
        value={label}
        checked={data?.includes(label)}
        onChange={actionChange}
      />
      <label htmlFor={`${name}-${label}`}>{label}</label>
    </Squarebox>
  );
};
