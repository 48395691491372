import { ReactComponent as AboutText } from 'assets/about/about_text.svg';
import styled from 'styled-components';
import { Box, Typography } from '@mui/material';

const AboutHeader = () => {
  return (
    <Box className="flex flex-col justify-center items-center bg-no-repeat bg-cover bg-[url(assets/about/about_background.svg)] min-h-[70vh]">
      <Box className="flex flex-col justify-center items-start">
        <Box className="flex md:flex-row flex-col items-start md:items-end md:mb-2">
          <AboutText className="mr-2 mb-3" />
          <Typography
            color="text.white"
            fontSize="2rem"
            fontFamily="NanumMyeongjo"
            fontWeight="regular"
          >
            배워서 남주자 프로젝트
          </Typography>
        </Box>
        <Typography
          color="text.gray"
          fontFamily="NanumMyeongjo"
          fontSize="1.5rem"
          lineHeight="140%"
          fontWeight="regular"
        >
          다음 세대를 영성과 실력으로 세상을 변화시키는
          <br />
          주역으로 세워나가고자 합니다.
        </Typography>
      </Box>
    </Box>
  );
};

export default AboutHeader;
