import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Checkbox,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import {
  CustomTimeCheckbox as TimeCheckbox,
  CustomBasicCheckbox as BasicCheckbox,
  CustomSquareCheckbox as SquareCheckbox,
} from 'elements/CustomCheckbox';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  saveApplication,
  selectApplication,
  setApplication,
  setInit,
} from 'store/applicationSlice';
import { showMessage } from 'store/messageSlice';
import { selectUser, updateUser } from 'store/userSlice';

const subjectList = ['국어', '영어', '수학', '과학', '사회', '역사', '한국어', '기타'];
const courseList = [
  '유초등저학년(1~2학년)',
  '초등중학년(3~4학년)',
  '초등고학년(5~6학년)',
  '중등',
  '고등',
];
const days = ['월', '화', '수', '목', '금', '토', '일'];
const times = [
  '00-01',
  '01-02',
  '02-03',
  '03-04',
  '04-05',
  '05-06',
  '06-07',
  '07-08',
  '08-09',
  '09-10',
  '10-11',
  '11-12',
  '12-13',
  '13-14',
  '14-15',
  '15-16',
  '16-17',
  '17-18',
  '18-19',
  '19-20',
  '20-21',
  '21-22',
  '22-23',
  '23-24',
];

const MentorDetail = ({ rows, step, setStep, actionPrevStep }) => {
  const user = useSelector(selectUser);
  const mentor = useSelector(selectApplication).mentor;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [availableSubjects, setAvailableSubjects] = useState(rows.availableSubjects);
  const [availableCourses, setAvailableCourses] = useState(rows.availableCourses);
  const [availableDay, setAvailableDay] = useState([]);
  const [availableTime, setAvailableTime] = useState(rows.availableDateTime);
  const [pledge, setPledge] = useState(rows.pledge);
  const [extraSubjects, setExtraSubjects] = useState(false);

  // 교재 사용에 관한 저작권 안내 체크박스 handle
  const handlePledgeCheckbox = (value) => {
    setPledge(value);
  };

  // 요일 체크 시 해당 요일 모든 시간 체크 on/off
  const handleDayCheckbox = (value, type) => {
    const list = availableDay;

    if (list.includes(value)) {
      setAvailableDay(list.filter((item) => item !== value));
      setAvailableTime(availableTime.filter((item) => item.split('-')[0] !== value));
    } else {
      setAvailableDay([...list, value]);
      const tempList = [];

      times.map((time) => {
        const temp = `${value}-${time}`; // ex) 월-09-10
        const isExist = availableTime.includes(temp);

        if (!isExist) {
          tempList.push(temp);
        }
        return 0;
      });

      setAvailableTime([...availableTime, ...tempList]);
    }
  };

  const handleCheckbox = (value, type) => {
    let list;
    if (type === 'availableSubjects') {
      list = availableSubjects;
    } else if (type === 'availableCourses') {
      list = availableCourses;
    } else if (type === 'availableDay') {
      list = availableDay;
    } else if (type === 'availableTime') {
      list = availableTime;
    }

    if (list.includes(value)) {
      if (type === 'availableSubjects') {
        setAvailableSubjects(list.filter((item) => item !== value));
        if (value === '기타') {
          setExtraSubjects(false);
        }
      } else if (type === 'availableCourses') {
        setAvailableCourses(list.filter((item) => item !== value));
      } else if (type === 'availableDay') {
        setAvailableDay(list.filter((item) => item !== value));
      } else if (type === 'availableTime') {
        setAvailableTime(list.filter((item) => item !== value));
      }
    } else if (type === 'availableSubjects') {
      setAvailableSubjects([...list, value]);
      if (value === '기타') {
        setExtraSubjects(true);
      }
    } else if (type === 'availableCourses') {
      setAvailableCourses([...list, value]);
    } else if (type === 'availableDay') {
      setAvailableDay([...list, value]);
    } else if (type === 'availableTime') {
      setAvailableTime([...list, value]);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    formData.delete('availableDay');
    formData.delete('availableTime');
    const data = Object.fromEntries(formData.entries());
    
    if (availableSubjects.includes('기타') && !data.extraSubjects) {
      dispatch(showMessage({ message: '희망 과목을 입력해주세요.', variant: 'error' }));
      return;
    }
    if (!availableSubjects.length) {
      dispatch(showMessage({ message: '수업 가능 과목을 선택해주세요.', variant: 'error' }));
      return;
    }
    if (!availableCourses.length) {
      dispatch(showMessage({ message: '희망 수업 과정을 선택해주세요.', variant: 'error' }));
      return;
    }
    if (!availableTime.length) {
      dispatch(showMessage({ message: '수업 가능 시간을 선택해주세요.', variant: 'error' }));
      return;
    }
    if (!pledge) {
      dispatch(showMessage({ message: '교재 사용 안내를 확인해주세요.', variant: 'error' }));
      return;
    }
    setLoading(true);

    // // 로딩 테스트 코드 -----------------------
    // // await new Promise((resolve, reject) => {
    // //   setTimeout(resolve, 2000);
    // // });
    // // setLoading(false);
    // // ----------------------------------------

    data.availableSubjects = availableSubjects;
    data.availableCourses = availableCourses;
    data.availableDateTime = availableTime;
    data.pledge = pledge;
    data.uid = user.uid;
    data.email = user.email;
    data.userType = user.userType;
    data.matchingStatus = 0;

    dispatch(setApplication({ step, data, type: 'mentor' }));
    const newData = {
      ...Object.values(mentor)[0],
      ...Object.values(mentor)[1],
      ...data,
    };
    dispatch(saveApplication({ data: newData, type: 'mentor' })).then((v) => {
      if (v !== undefined) {
        dispatch(
          updateUser({
            ...user,
            pledge,
            mentoringInfoExist: true,
            // name: newData.name,
            // birth: newData.birth,
            // gender: newData.gender,
          })
        );
        setStep(step + 1);
        dispatch(setInit());
      } else {
        dispatch(showMessage({ message: '정보 저장에 실패했습니다.', variant: 'error' }));
      }

      setLoading(false);
    });
  };

  return (
    <Box>
      <Box className="text-center">
        <Typography variant="h1">멘토링 정보 입력하기</Typography>
        <Typography component="p" color="error" variant="body3" className="!mt-4">
          * 표시된 항목은 필수 입력 항목입니다.
        </Typography>
      </Box>
      <Box className="p-12 md:p-16" component="form" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} columnGap={1} columnSpacing={1}>
            <Typography className="text-left !font-bold pb-2">수업 가능 과목*</Typography>
            <Typography
              className="text-left !text-xs pb-4"
              color={(theme) => theme.palette.error.main}
            >
              다중 선택이 가능합니다.
            </Typography>
            <Box className="flex flex-wrap">
              {subjectList.map((item, index) => (
                <BasicCheckbox
                  key={index}
                  name="availableSubjects"
                  label={item}
                  data={availableSubjects}
                  actionChange={() => handleCheckbox(item, 'availableSubjects')}
                />
              ))}
            </Box>
          </Grid>
          {extraSubjects && (
            <Grid item xs={12}>
              <Typography className="text-left !font-bold pb-2">
                희망 과목 작성 (기타 선택 시)
              </Typography>
              <TextField
                id="extraSubjects"
                name="extraSubjects"
                variant="standard"
                placeholder="희망하시는 과목을 입력해주세요."
                InputProps={{ className: 'p-2' }}
                defaultValue={rows.extraSubjects}
                hiddenLabel
                fullWidth
                disabled={!availableSubjects.includes('기타')}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography className="text-left !font-bold pb-2">희망 수업 과정*</Typography>
            <Typography
              className="text-left !text-xs pb-4"
              color={(theme) => theme.palette.error.main}
            >
              다중 선택이 가능합니다.
            </Typography>
            <Box className="flex flex-wrap">
              {courseList.map((item, index) => (
                <BasicCheckbox
                  key={index}
                  name="availableCourses"
                  label={item}
                  data={availableCourses}
                  actionChange={() => handleCheckbox(item, 'availableCourses')}
                />
              ))}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography className="text-left !font-bold pb-2">수업 가능 시간대*</Typography>
            <Typography
              className="text-left !text-xs pb-4"
              color={(theme) => theme.palette.error.main}
            >
              한국 시간을 기준으로 선택하세요. (시간대는 다중 선택이 가능합니다.)
            </Typography>
            <Box className="relative">
              {days.map((item, index) => (
                <Box key={index} className="flex items-center gap-2 py-1">
                  <SquareCheckbox
                    key={index}
                    name="availableDay"
                    label={item}
                    data={availableDay}
                    actionChange={() => handleDayCheckbox(item, 'availableDay')}
                  />
                  <Box className="inline-flex w-full overflow-auto">
                    {times.map((val, i) => (
                      <TimeCheckbox
                        key={val}
                        name="availableTime"
                        label={val}
                        dataKey={days[index]}
                        data={availableTime}
                        actionChange={() =>
                          handleCheckbox(`${days[index]}-${val}`, 'availableTime')
                        }
                      />
                    ))}
                  </Box>
                  <div className="absolute right-0 w-8 h-11 bg-gradient-to-l from-blue-50"></div>
                </Box>
              ))}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              className="flex flex-col gap-7 justify-center items-center px-16 py-10 w-full bg-[#FFDCB8]"
              component="span"
              sx={{ border: '2px dashed #E4782A' }}
            >
              <Typography className="text-center" variant="h1">
                교재 사용에 관한 저작권 안내
              </Typography>
              <Typography className="text-left" fontWeight="regular">
                아래와 같은 행위를 할 경우 법적인 문제가 발생할 수 있음을 알려드립니다.
                <br />
                <br />
                • 배남프 수업 이외의 목적으로 사용하는 경우
                <br />
                • 개인의 영리를 위해 사용하는 경우
                <br />
                • 타인과 공유하는 경우
                <br />
              </Typography>
              <Box className="flex items-center">
                <Checkbox
                  value={pledge}
                  checked={pledge}
                  onChange={() => handlePledgeCheckbox(!pledge)}
                  color="primary"
                />
                <Typography variant="body3">위 내용을 확인했습니다 (필수)</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box className="flex flex-row items-center justify-center pt-24">
          <Button
            fullWidth
            className="formButton"
            variant="contained"
            color="inherit"
            onClick={actionPrevStep}
          >
            이전
          </Button>
          <Button fullWidth className="formButton" type="submit" variant="contained">
            신청완료
          </Button>
        </Box>
      </Box>
      <Backdrop
        sx={{
          color: (theme) => theme.palette.primary.main,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <Box className="flex justify-center items-center p-8 bg-white rounded-lg">
          <CircularProgress color="inherit" />
        </Box>
      </Backdrop>
    </Box>
  );
};

export default MentorDetail;
