import { Box, Typography } from '@mui/material';
import React from 'react';

const MyTitle = ({ title }) => {
  return (
    <Box className="w-full max-w-5xl mb-8 md:mb-6">
      <Typography variant="h1" fontSize="2.5rem">
        {title}
      </Typography>
    </Box>
  );
};

export default MyTitle;
