import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/userSlice';

const TimeBlocks = (props) => {
  const user = useSelector(selectUser);
  const { mentee, mentor } = props;

  // 0: empty, 1: 멘티, 2: 멘토, 3: 멘티+멘토
  const timeBlockMap = {
    월: Array.from(
      {
        length: 24,
      },
      () => 0
    ),
    화: Array.from(
      {
        length: 24,
      },
      () => 0
    ),
    수: Array.from(
      {
        length: 24,
      },
      () => 0
    ),
    목: Array.from(
      {
        length: 24,
      },
      () => 0
    ),
    금: Array.from(
      {
        length: 24,
      },
      () => 0
    ),
    토: Array.from(
      {
        length: 24,
      },
      () => 0
    ),
    일: Array.from(
      {
        length: 24,
      },
      () => 0
    ),
  };

  // 멘티 가능시간 저장하기
  mentee.availableDateTime.map((e) => {
    timeBlockMap[e.split('-')[0]][(e.split('-')[1] *= 1)] = 1;
  });

  // 멘토 가능시간 저장하기
  mentor.availableDateTime.map((e) => {
    if (timeBlockMap[e.split('-')[0]][(e.split('-')[1] *= 1)] == 1) {
      timeBlockMap[e.split('-')[0]][(e.split('-')[1] *= 1)] = 3;
    } else {
      timeBlockMap[e.split('-')[0]][(e.split('-')[1] *= 1)] = 2;
    }
  });

  const weekDayList = ['월', '화', '수', '목', '금', '토', '일'];
  const timeList = ['00', '03', '06', '09', '12', '15', '18', '21', '24'];
  const legends =
    user.userType === 'mentor'
      ? {
          멘티: (theme) => theme.palette.figma.yellow03,
          멘토: (theme) => theme.palette.figma.orange03,
          '멘티+멘토': (theme) => theme.palette.figma.orange01,
        }
      : {
          멘토: (theme) => theme.palette.figma.orange03,
          멘티: (theme) => theme.palette.figma.yellow03,
          '멘티+멘토': (theme) => theme.palette.figma.orange01,
        };

  return (
    <Box className="flex flex-col gap-4 p-5">
      <Box className="flex justify-start w-full">
        <Box className="flex gap-4">
          {Object.entries(legends).map(([key, value]) => (
            <Box key={key} className="flex justify-between items-center gap-2">
              <Box className="w-4 h-4" sx={{ backgroundColor: value }} />
              <Typography variant="body3">{key}</Typography>
            </Box>
          ))}
        </Box>
      </Box>
      {weekDayList.map((day, index) => (
        <Box key={index} className="flex w-full gap-8">
          <Box className="flex items-start px-2">{day}</Box>
          <Box className="flex flex-1 flex-col w-full">
            <Box className="flex">
              {timeBlockMap[day].map((v, index) => {
                let bgColor = 'white';

                if (v !== 0) {
                  // 멘티
                  bgColor = Object.values(legends)[v - 1];
                }

                return (
                  <Box
                    key={index}
                    className="flex flex-1 h-4 border border-black/10 box-border border-solid"
                    sx={{ backgroundColor: bgColor }}
                  />
                );
              })}
            </Box>
            <Box className="flex justify-between">
              {timeList.map((time, index) => (
                <Typography key={index} variant="body3">
                  {time}
                </Typography>
              ))}
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default TimeBlocks;
