import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { doc, getDoc } from 'firebase/firestore';
import { db } from 'firebase_config';

const initialState = {
  leftMenus: [],
  rightMenus: [],
};

export const fetchMenus = createAsyncThunk('menus/fetchMenus', async () => {
  const docRef = doc(db, 'Menus', 'menuList');
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  }
  // doc.data() will be undefined in this case
  // console.log('No such document!');
  return undefined;
});

export const menuSlice = createSlice({
  name: 'menus',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMenus.fulfilled, (state, action) => {
      state.leftMenus = action.payload.leftMenus;
      state.rightMenus = action.payload.rightMenus;
    });
  },
});

export const selectLeftMenus = (state) => state.menus.leftMenus;
export const selectRightMenus = (state) => state.menus.rightMenus;

export default menuSlice.reducer;
