import { Box, Typography } from '@mui/material';
import { ReactComponent as MailIcon } from 'assets/icons/mail.svg';
import { Background } from './Footer.style';

function Footer() {
  return (
    <Background>
      <Box className="flex flex-col md:flex-row justify-between">
        <Box className="flex items-center">
          <Typography component="a" variant="body1" href="/">
            배남프
          </Typography>
          <Typography className="text-slate-200 !text-sm pl-4">배워서 남주자 프로젝트</Typography>
        </Box>
        <Box className="flex items-center info">
          <Typography component="a" href="/privacy-policy">
            개인정보처리방침
          </Typography>
          <Typography component="a" href="/terms-of-service">
            서비스이용약관
          </Typography>
          <Typography className="flex items-center pr-4">
            <MailIcon />
            <span className="pl-1">baenampit@gmail.com</span>
          </Typography>
        </Box>
      </Box>
      <Typography variant="body3" color="#cecece">
        Copyright © 2023 배남프. All rights reserved.
      </Typography>
    </Background>
  );
}

export default Footer;
