import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Root from 'components/Root';
import withRouter from 'utils/withRouter';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import {
  addQna,
  addReply,
  deleteQna,
  deleteReply,
  fetchQna,
  fetchReplies,
  updateQna,
  updateReply,
} from 'store/qnaSlice';
import { showMessage } from 'store/messageSlice';
import './style.css';
import { selectUser } from 'store/userSlice';

const Qna = (props) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const user = useSelector(selectUser);
  const prevId = useSelector((state) => state.qna.currentId);
  const qnaData = useSelector((state) => state.qna.question);
  const qnaReplyCount = useSelector((state) => state.qna.totalReplyCount);
  const [uid, setUid] = React.useState(null); // 로컬스토리지에 저장된 고유 아이디
  const [privateCheck, setPrivateCheck] = React.useState(false);
  const [qData, setQData] = React.useState(qnaData);
  const [status, setStatus] = React.useState(props.status);
  const [replyEdit, setReplyEdit] = React.useState([]);
  const [replyList, setReplyList] = React.useState([]);
  const [userEmail, setUserEmail] = React.useState('');

  const handleChangePrivateCheck = (event) => {
    setPrivateCheck(event.target.checked);
  };

  const handleAddQuestion = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    formData.append('id', prevId + 1);
    formData.append('uid', uid);

    const data = Object.fromEntries(formData);
    if (!data.title) {
      dispatch(showMessage({ message: '제목을 입력해주세요.', variant: 'error' }));
      return false;
    }
    if (!data.content) {
      dispatch(showMessage({ message: '내용을 입력해주세요.', variant: 'error' }));
      return false;
    }
    const { value: password } = await Swal.fire({
      width: '30rem',
      padding: '2rem',
      title: '비밀번호를 입력해주세요.',
      html: '<span class="text-base">비밀번호는 글 수정 및 삭제시 사용됩니다.</span>',
      input: 'password',
      inputPlaceholder: '4~12자리의 영문, 숫자만 사용 가능합니다.',
      inputAttributes: {
        autocapitalize: 'off',
        autocorrect: 'off',
        maxlength: 12,
        pattern: '[a-zA-Z0-9]{4,12}',
      },
      allowEnterKey: false,
      showCancelButton: true,
      confirmButtonText: '작성하기',
      cancelButtonText: '취소',
      inputValidator: (val) => {
        if (!val) {
          return '비밀번호를 입력해주세요.';
        }
        if (val.length < 4 || val.length > 12) {
          return '비밀번호는 4~12자리의 영문, 숫자만 사용 가능합니다.';
        }
        if (!val.match('[a-zA-Z0-9]{4,12}')) {
          return '특수문자는 사용할 수 없습니다.';
        }
        return null;
      },
    });
    if (password) {
      const hashPassword = CryptoJS.AES.encrypt(
        String(password),
        process.env.REACT_APP_SALT
      ).toString();
      data.password = hashPassword;
      data.hasAnswer = false;
      data.isPrivate = privateCheck;
      data.email = userEmail;
      dispatch(addQna(data)).then(() => {
        dispatch(showMessage({ message: '질문이 등록되었습니다.', variant: 'success' }));
        props.navigate('/QnA', { replace: true });
      });
    }
    return true;
  };

  const handleDeleteQna = async (event, qnaId) => {
    event.preventDefault();
    await Swal.fire({
      width: '30rem',
      padding: '2rem',
      title: '해당 질문을 삭제하시겠습니까?',
      html: '<span class="text-base">비밀번호를 입력해주세요.</span>',
      input: 'password',
      inputAttributes: {
        autocapitalize: 'off',
        autocorrect: 'off',
        maxlength: 12,
        pattern: '[a-zA-Z0-9]{4,12}',
      },
      allowEnterKey: false,
      showCancelButton: true,
      confirmButtonText: '확인',
      cancelButtonText: '취소',
      inputValidator: (val) => {
        const decryptPassword = CryptoJS.AES.decrypt(
          qData.password,
          process.env.REACT_APP_SALT
        ).toString(CryptoJS.enc.Utf8);
        if (!val) {
          return '비밀번호를 입력해주세요.';
        }
        if (val !== decryptPassword) {
          return '비밀번호가 일치하지 않습니다.';
        }
        return null;
      },
    })
      .then((result) => {
        if (result.isConfirmed) {
          if (replyList && replyList.length > 0) {
            replyList.forEach((r) => {
              dispatch(deleteReply({ qnaId: qnaId, replyId: r.rId }));
            });
          }
          return true;
        }
      })
      .then((result) => {
        if (result) {
          dispatch(deleteQna(qnaId)).then(() => {
            dispatch(showMessage({ message: '질문이 삭제되었습니다.', variant: 'success' }));
            props.navigate('/QnA', { replace: true });
          });
        }
      });
  };

  const handleEditQnaStatus = async (event, edit) => {
    event.preventDefault();
    await Swal.fire({
      width: '30rem',
      padding: '2rem',
      title: '해당 질문을 수정하시겠습니까?',
      html: '<span class="text-base">비밀번호를 입력해주세요.</span>',
      input: 'password',
      inputAttributes: {
        autocapitalize: 'off',
        autocorrect: 'off',
        maxlength: 12,
        pattern: '[a-zA-Z0-9]{4,12}',
      },
      allowEnterKey: false,
      showCancelButton: true,
      confirmButtonText: '확인',
      cancelButtonText: '취소',
      inputValidator: (val) => {
        const decryptPassword = CryptoJS.AES.decrypt(
          qData.password,
          process.env.REACT_APP_SALT
        ).toString(CryptoJS.enc.Utf8);
        if (!val) {
          return '비밀번호를 입력해주세요.';
        }
        if (val !== decryptPassword) {
          return '비밀번호가 일치하지 않습니다.';
        }
        return null;
      },
    }).then((result) => {
      if (result.isConfirmed) {
        setStatus(edit);
        setPrivateCheck(qData.isPrivate);
      } else {
        setStatus('detail');
      }
    });
  };

  const handleEditQna = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const data = Object.fromEntries(formData);
    await Swal.fire({
      width: '30rem',
      padding: '2rem',
      title: '해당 질문을 수정하시겠습니까?',
      allowEnterKey: false,
      showCancelButton: true,
      confirmButtonText: '수정하기',
      cancelButtonText: '취소',
    }).then((result) => {
      if (result.isConfirmed) {
        const updateData = { ...qData, ...data };
        delete updateData.replies;
        delete updateData.createdAt;
        dispatch(updateQna(updateData)).then(() => {
          dispatch(showMessage({ message: '질문이 수정되었습니다.', variant: 'success' }));
          setStatus('detail');
        });
      }
    });
  };

  const handleAddReply = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    formData.append('uid', uid);
    const data = Object.fromEntries(formData);

    if (!data.reply) {
      dispatch(showMessage({ message: '댓글을 입력해주세요.', variant: 'error' }));
      return false;
    }
    const { value: password } = await Swal.fire({
      width: '30rem',
      padding: '2rem',
      title: '비밀번호를 입력해주세요.',
      html: '<span class="text-base">비밀번호는 글 수정 및 삭제 시 사용됩니다.</span>',
      input: 'password',
      inputPlaceholder: '4~12자리의 영문, 숫자만 사용 가능합니다.',
      inputAttributes: {
        autocapitalize: 'off',
        autocorrect: 'off',
        maxlength: 12,
        pattern: '[a-zA-Z0-9]{4,12}',
      },
      allowEnterKey: false,
      showCancelButton: true,
      confirmButtonText: '작성하기',
      cancelButtonText: '취소',
      inputValidator: (val) => {
        if (!val) {
          return '비밀번호를 입력해주세요.';
        }
        if (val.length < 4 || val.length > 12) {
          return '비밀번호는 4~12자리의 영문, 숫자만 사용 가능합니다.';
        }
        if (!val.match('[a-zA-Z0-9]{4,12}')) {
          return '특수문자는 사용할 수 없습니다.';
        }
        return null;
      },
    });

    if (password) {
      const hashPassword = CryptoJS.AES.encrypt(
        String(password),
        process.env.REACT_APP_SALT
      ).toString();
      data.password = hashPassword;
      data.email = userEmail;
      delete data.createdAt;
      dispatch(addReply({ param: data, qnaId: qData.docId, userType: user.userType })).then(() => {
        dispatch(showMessage({ message: '댓글이 등록되었습니다.', variant: 'success' }));
        dispatch(fetchReplies(qData.docId));
        event.target.reset();
      });
    }
    return true;
  };

  const handleDeleteReply = async (r) => {
    await Swal.fire({
      width: '30rem',
      padding: '2rem',
      title: '해당 댓글을 삭제하시겠습니까?',
      html: '<span class="text-base">비밀번호를 입력해주세요.</span>',
      input: 'password',
      inputAttributes: {
        autocapitalize: 'off',
        autocorrect: 'off',
        maxlength: 12,
        pattern: '[a-zA-Z0-9]{4,12}',
      },
      allowEnterKey: false,
      showCancelButton: true,
      confirmButtonText: '확인',
      cancelButtonText: '취소',
      inputValidator: (val) => {
        const decryptPassword = CryptoJS.AES.decrypt(
          r.password,
          process.env.REACT_APP_SALT
        ).toString(CryptoJS.enc.Utf8);
        if (!val) {
          return '비밀번호를 입력해주세요.';
        }
        if (val !== decryptPassword) {
          return '비밀번호가 일치하지 않습니다.';
        }
        return null;
      },
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteReply({ qnaId: qData.docId, replyId: r.rId })).then(() => {
          dispatch(showMessage({ message: '댓글이 삭제되었습니다.', variant: 'success' }));
          dispatch(fetchReplies(qData.docId));
        });
      }
    });
  };

  const handleEditReplyStatus = async (rId, rPassword, type) => {
    if (type === 'replyEdit') {
      await Swal.fire({
        width: '30rem',
        padding: '2rem',
        title: '해당 댓글을 수정하시겠습니까?',
        html: '<span class="text-base">비밀번호를 입력해주세요.</span>',
        input: 'password',
        inputAttributes: {
          autocapitalize: 'off',
          autocorrect: 'off',
          maxlength: 12,
          pattern: '[a-zA-Z0-9]{4,12}',
        },
        allowEnterKey: false,
        showCancelButton: true,
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        inputValidator: (val) => {
          const decryptPassword = CryptoJS.AES.decrypt(
            rPassword,
            process.env.REACT_APP_SALT
          ).toString(CryptoJS.enc.Utf8);
          if (!val) {
            return '비밀번호를 입력해주세요.';
          }
          if (val !== decryptPassword) {
            return '비밀번호가 일치하지 않습니다.';
          }
          return null;
        },
      }).then((result) => {
        if (result.isConfirmed) {
          const newReplyEdit = replyEdit.map((r) => {
            if (r.id === rId) {
              return { ...r, status: !r.status };
            }
            return r;
          });
          setReplyEdit(newReplyEdit);
        }
      });
    }
  };

  const handleCancelEditReply = (rId) => {
    const newReplyEdit = replyEdit.map((r) => {
      if (r.id === rId) {
        return { ...r, status: !r.status };
      }
      return r;
    });
    setReplyEdit(newReplyEdit);
    const replyTextField = document.getElementById(`reply-${rId}`);
    if (replyTextField) {
      replyTextField.value = replyList.find((item) => item.rId === rId)?.reply;
    }
  };

  const handleEditReply = async (event, rData) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const data = Object.fromEntries(formData);

    const updateData = { ...rData, ...data };
    delete updateData.createdAt;

    dispatch(updateReply({ param: updateData, qnaId: qData.docId })).then(() => {
      dispatch(showMessage({ message: '댓글이 수정되었습니다.', variant: 'success' }));
      dispatch(fetchReplies(qData.docId));
    });
  };

  useEffect(() => {
    if (user) {
      setUserEmail(user.email);
      setUid(user.uid);
    }
  }, [user]);

  useEffect(() => {
    if (id) {
      dispatch(fetchQna(id));
    }
  }, [dispatch, id, status]);

  useEffect(() => {
    if (qnaData) {
      const emailSplit = qnaData.email?.split('@');
      const hashEmail =
        emailSplit && emailSplit[0].slice(0, 3) + '*'.repeat(emailSplit[0].length - 3);
      const userEmail = hashEmail && `${hashEmail}@${emailSplit[1]}`;
      setQData({ ...qnaData, email: user.userType === 'admin' ? qnaData.email : userEmail });
      setReplyEdit(
        qnaData?.replies?.map((r) => {
          return { id: r.rId, status: true };
        })
      );
      if (status === 'edit') {
        setPrivateCheck(qnaData.isPrivate);
      }
      setReplyList(qnaData?.replies);
    }
  }, [qnaData]);

  if (status === 'new') {
    return (
      <Root activeMenu="질문 게시판">
        <Box className="py-20 px-20 md:px-60">
          <Box className="pb-4 flex flex-row justify-between border-b border-1">
            <Box>
              <Typography variant="h1" sx={{ fontSize: '1.5rem', fontWeight: 'bold', pb: 1 }}>
                질문 작성하기
              </Typography>
              <Typography
                variant="body1"
                color="text.disabled"
                sx={{ fontSize: '0.8rem', fontWeight: 'bold' }}
              >
                *는 필수 입력 항목입니다.
              </Typography>
            </Box>
            <Box className="cursor-pointer" onClick={() => props.navigate(-1, { replace: true })}>
              <CloseIcon sx={{ fontSize: '1.5rem' }} />
            </Box>
          </Box>
          <Grid component="form" container spacing={2} sx={{ mt: 2 }} onSubmit={handleAddQuestion}>
            <Grid item xs={12}>
              <Typography className="text-left pb-2">이메일*</Typography>
              <Typography className="!font-medium !text-slate-500">{userEmail}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className="text-left pb-2">제목*</Typography>
              <TextField
                id="title"
                name="title"
                variant="outlined"
                size="small"
                placeholder="제목을 입력해주세요."
                hiddenLabel
                fullWidth
                sx={{ '& .MuiOutlinedInput-root': { fontSize: '0.85rem', fontWeight: 500 } }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className="text-left pb-2">내용*</Typography>
              <TextField
                multiline
                rows={8}
                id="content"
                name="content"
                variant="outlined"
                size="small"
                placeholder="내용을 입력해주세요."
                hiddenLabel
                fullWidth
                sx={{ '& .MuiOutlinedInput-root': { fontSize: '0.85rem', fontWeight: 500 } }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox checked={privateCheck} onChange={handleChangePrivateCheck} />}
                label="비밀글로 등록하시겠습니까?"
              />
            </Grid>
            <Grid item xs={12} className="flex justify-center">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="small"
                sx={{ display: 'block' }}
              >
                작성하기
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Root>
    );
  }

  if (status === 'edit') {
    return (
      <Root activeMenu="질문 게시판">
        <Box className="py-20 px-20 md:px-60">
          <Box className="pb-4 flex flex-row justify-between border-b border-1">
            <Box>
              <Typography variant="h1" sx={{ fontSize: '1.5rem', fontWeight: 'bold', pb: 1 }}>
                질문 수정하기
              </Typography>
              <Typography
                variant="body1"
                color="text.disabled"
                sx={{ fontSize: '0.8rem', fontWeight: 'bold' }}
              >
                *는 필수 입력 항목입니다.
              </Typography>
            </Box>
            <Box className="cursor-pointer" onClick={() => setStatus('detail')}>
              <CloseIcon sx={{ fontSize: '1.5rem' }} />
            </Box>
          </Box>
          <Grid component="form" container spacing={2} sx={{ mt: 2 }} onSubmit={handleEditQna}>
            <Grid item xs={12}>
              <Typography className="text-left pb-2">이메일</Typography>
              <Typography className="!font-medium !text-slate-500">{userEmail}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className="text-left pb-2">제목*</Typography>
              <TextField
                id="title"
                name="title"
                variant="outlined"
                size="small"
                placeholder="제목을 입력해주세요."
                defaultValue={qData?.title}
                hiddenLabel
                fullWidth
                sx={{ '& .MuiOutlinedInput-root': { fontSize: '0.85rem', fontWeight: 500 } }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className="text-left pb-2">내용*</Typography>
              <TextField
                multiline
                rows={8}
                id="content"
                name="content"
                variant="outlined"
                size="small"
                placeholder="내용을 입력해주세요."
                defaultValue={qData?.content}
                hiddenLabel
                fullWidth
                sx={{ '& .MuiOutlinedInput-root': { fontSize: '0.85rem', fontWeight: 500 } }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox checked={privateCheck} onChange={handleChangePrivateCheck} />}
                label="비밀글로 등록하시겠습니까?"
              />
            </Grid>
            <Grid item xs={12} className="flex justify-center">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="small"
                sx={{ display: 'block' }}
              >
                수정하기
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Root>
    );
  }

  return (
    <Root activeMenu="질문 게시판">
      <Box className="py-20 px-20 md:px-40 lg:px-60">
        <Box className="pb-4 border-b border-1">
          <Typography variant="h1" sx={{ fontSize: '1rem', fontWeight: 500, pb: 4 }}>
            질문 게시판
          </Typography>
          <Typography variant="h1" sx={{ fontSize: '1.5rem', fontWeight: 'bold', pb: 1 }}>
            {qData?.title}
          </Typography>
          <Box className="flex flex-row items-center justify-between">
            <Box className="flex flex-row items-center">
              <Typography variant="body1" color="text.disabled" sx={{ fontSize: '0.8rem' }}>
                {qData?.email}
              </Typography>
              <Typography
                variant="body1"
                color="text.disabled"
                sx={{ fontSize: '0.8rem', fontWeight: 500, ml: 2 }}
              >
                작성일 {qData?.createdAt}
              </Typography>
            </Box>
            {qData?.uid === uid && (
              <Box className="flex flex-row items-center">
                <Typography
                  variant="body1"
                  color="primary"
                  sx={{ fontSize: '0.8rem', mr: 2, cursor: 'pointer' }}
                  onClick={(e) => handleEditQnaStatus(e, 'edit')}
                >
                  수정
                </Typography>
                <Typography
                  variant="body1"
                  color="error"
                  sx={{ fontSize: '0.8rem', cursor: 'pointer' }}
                  onClick={(e) => handleDeleteQna(e, qData.docId)}
                >
                  지우기
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
        <Box className="py-4 border-b border-1" sx={{ fontWeight: 500, minHeight: 300 }}>
          {qData?.content}
        </Box>
        <Box className="py-2 text-right">
          <Button variant="outlined" onClick={() => props.navigate(-1)}>
            목록
          </Button>
        </Box>
        <Box className="py-4">
          <Typography variant="h1" sx={{ fontSize: '1rem', fontWeight: 'bold', pb: 1 }}>
            댓글
            <Typography component="span" color="error">
              {qnaReplyCount}
            </Typography>
          </Typography>
          {replyList &&
            replyList.map((item) => {
              const disableStatus = replyEdit.find((i) => i.id === item.rId)?.status;
              const emailSplit = item.email?.split('@');
              const hashEmail =
                emailSplit && emailSplit[0].slice(0, 3) + '*'.repeat(emailSplit[0].length - 3);
              const userEmail = hashEmail && `${hashEmail}@${emailSplit[1]}`;
              return (
                <Box
                  key={item.rId}
                  sx={{ fontWeight: 500, my: 1 }}
                  component="form"
                  onSubmit={(e) => handleEditReply(e, item)}
                >
                  <Box className="flex flex-row items-center justify-between pb-2">
                    <Box className="flex flex-row items-center">
                      <Typography variant="body1" color="text.disabled" sx={{ fontSize: '0.8rem' }}>
                        {userEmail === 'bae******@gmail.com' ? '관리자' : userEmail}
                      </Typography>
                      <Typography
                        variant="body1"
                        color="text.disabled"
                        sx={{ fontSize: '0.8rem', fontWeight: 500, ml: 2 }}
                      >
                        작성일 {item.createdAt}
                      </Typography>
                    </Box>
                    {item.uid === uid && (
                      <Box className="flex flex-row items-center">
                        {disableStatus ? (
                          <Typography
                            variant="body1"
                            color="primary"
                            sx={{ fontSize: '0.8rem', mr: 2, cursor: 'pointer' }}
                            onClick={() =>
                              handleEditReplyStatus(item.rId, item.password, 'replyEdit')
                            }
                          >
                            수정
                          </Typography>
                        ) : (
                          <>
                            <Typography
                              variant="body1"
                              color="text.disabled"
                              sx={{ fontSize: '0.8rem', mr: 2, cursor: 'pointer' }}
                              onClick={() => handleCancelEditReply(item.rId)}
                            >
                              취소
                            </Typography>
                            <Typography
                              component="button"
                              type="submit"
                              variant="body1"
                              color="primary"
                              sx={{ fontSize: '0.8rem', mr: 2, cursor: 'pointer' }}
                            >
                              저장
                            </Typography>
                          </>
                        )}
                        <Typography
                          variant="body1"
                          color="error"
                          sx={{ fontSize: '0.8rem', cursor: 'pointer' }}
                          onClick={() => handleDeleteReply(item)}
                        >
                          지우기
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  <TextField
                    multiline
                    // rows={2}
                    id={`reply-${item.rId}`}
                    name="reply"
                    size="small"
                    variant="standard"
                    placeholder="댓글을 입력해주세요."
                    defaultValue={item.reply}
                    hiddenLabel
                    fullWidth
                    sx={{
                      '& .MuiInputBase-input': {
                        fontSize: '0.85rem',
                        fontWeight: 500,
                        border: 'none',
                      },
                    }}
                    disabled={disableStatus}
                  />
                </Box>
              );
            })}
          <Box
            sx={{ mt: 4, backgroundColor: '#fafafa', p: 3, borderRadius: 2 }}
            component="form"
            onSubmit={handleAddReply}
          >
            <Typography variant="body2" sx={{ fontSize: '0.8rem' }} className="text-left pb-1">
              이메일
            </Typography>
            <Typography className="!font-medium !text-slate-500">{userEmail}</Typography>
            <Typography variant="body2" sx={{ fontSize: '0.8rem' }} className="text-left pt-4 pb-1">
              댓글 내용*
            </Typography>
            <TextField
              multiline
              rows={3}
              id="reply"
              name="reply"
              variant="outlined"
              size="small"
              placeholder="댓글을 입력해주세요."
              hiddenLabel
              fullWidth
              sx={{
                '& .MuiOutlinedInput-root': { fontSize: '0.8rem', fontWeight: 500 },
              }}
            />
            <Box className="flex justify-end">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="small"
                sx={{ display: 'block', color: '#fff', mt: 2, fontSize: '0.85rem' }}
              >
                작성하기
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Root>
  );
};

export default withRouter(Qna);
