/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Box, Button, Typography } from '@mui/material';
import { ReactComponent as Face } from 'assets/face.svg';
import withRouter from 'utils/withRouter';

const styleWrapper = (theme) => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  & h2 {
    margin-top: ${theme.spacing(9)};
    margin-bottom: ${theme.spacing(2)};
  }
  & button {
    margin-top: ${theme.spacing(10)};
  }
`;

const NoMatch = (props) => {
  return (
    <Box css={styleWrapper}>
      <Face />
      <Typography variant="h2">앗! 죄송해요. 화면을 불러오지 못했어요.</Typography>
      <Typography variant="body2">홈으로 이동해 주세요.</Typography>
      <Button
        variant="outlined"
        onClick={() => {
          props.navigate(-1);
        }}
      >
        이전 화면으로 가기
      </Button>
    </Box>
  );
};

export default withRouter(NoMatch);
