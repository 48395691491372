import { Box } from '@mui/material';
import Root from 'components/Root';
import Apply from './components/Apply';
import ApplyProcess from './components/ApplyProcess';
import Subjects from './components/Subjects';
import DetailSchedule from './components/DetailSchedule';

const Process = () => {
  return (
    <Root activeMenu="신청 및 일정 안내">
      <Box className="flex flex-col items-center">
        <Apply />
        <ApplyProcess />
        <Subjects />
        <DetailSchedule />
      </Box>
    </Root>
  );
};

export default Process;
