import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, IconButton, Snackbar, SnackbarContent, Typography, styled } from '@mui/material';
import { amber, blue, green } from '@mui/material/colors';
import { hideMessage, selectMessageOptions, selectMessageState } from 'store/messageSlice';

const StyledSnackbar = styled(Snackbar)(({ variant }) => ({
  '& .message-content': {
    ...(variant === 'success' && {
      backgroundColor: green[600],
      color: '#FFFFFF',
    }),

    ...(variant === 'error' && {
      backgroundColor: '#E4782A',
      color: '#FFFFFF',
    }),

    ...(variant === 'info' && {
      backgroundColor: blue[600],
      color: '#FFFFFF',
    }),

    ...(variant === 'warning' && {
      backgroundColor: amber[600],
      color: '#FFFFFF',
    }),
  },
}));

const variantIcon = {
  success: 'check_circle',
  warning: 'warning',
  error: 'error_outline',
  info: 'info',
  hardware: 'hardware',
};

const Message = () => {
  const dispatch = useDispatch();
  const state = useSelector(selectMessageState);
  const options = useSelector(selectMessageOptions);

  return (
    <StyledSnackbar
      {...options}
      open={state}
      onClose={() => dispatch(hideMessage())}
      ContentProps={{
        variant: 'body2',
        headlineMapping: {
          body1: 'div',
          body2: 'div',
        },
      }}
    >
      <SnackbarContent
        sx={{ bgcolor: options.color }}
        className="message-content"
        message={
          <div className="flex items-center">
            {variantIcon[options.variant] && (
              <Icon color="inherit">{variantIcon[options.variant]}</Icon>
            )}
            <Typography className="mx-8 pl-2 whitespace-pre-line">{options.message}</Typography>
          </div>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => dispatch(hideMessage())}
            size="large"
          >
            <Icon>close</Icon>
          </IconButton>,
        ]}
      />
    </StyledSnackbar>
  );
};

export default memo(Message);
