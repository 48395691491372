import { Box, Divider } from '@mui/material';
import InfoBox from '../components/InfoBox';
import Waiting from '../components/Waiting';
import { remoteConfig, getValue } from 'firebase_config';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUser, selectedUserApplication } from 'store/userSlice';
import FirestorePath from 'services/firebase_path';
import MyTitle from '../components/MyTitle';
import TimeBlocks from './components/TimeBlocks';
import Info from './components/Info';
import matchingConverter from 'models/matching_model';
import { getCollectionStream } from 'services/firebase_services';
import { orderBy, where } from 'firebase/firestore';
import withRouter from 'utils/withRouter';
import SomethingWrong from '../components/SomethingWrong';
import MentorMatching from './components/MentorMatching';

const Matching = () => {
  const user = useSelector(selectUser);
  const userApplication = useSelector(selectedUserApplication);
  const remoteDate = getValue(remoteConfig, 'open_datetime_matching')._value;
  // const today = dayjs(new Date()).format('YYYY-MM-DD');
  // const openDay = dayjs(remoteDate).format('YYYY-MM-DD');
  const today = new Date();
  const openDay = new Date(remoteDate);
  const closeDay = new Date('2024-03-20 23:59:59');
  const test = new Date('2023-08-04 00:00:00');

  const [matchingList, setMatchingList] = useState([]);
  const [approvedMentee, setApprovedMentee] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  const subjectIcon = {
    국어: '📚',
    영어: '🇺🇸',
    수학: '🧮',
    사회: '🌏',
    과학: '🧬',
    역사: '🛖',
    기타: '🎸',
  };

  useEffect(() => {
    // console.log('openDay');
    // console.log(openDay);
    // console.log('today');
    // console.log(today);
    // console.log('test');
    // console.log(test);
    // console.log('openDay <= today');
    // console.log(openDay <= today);
    // console.log('openDay - test');
    // console.log(openDay - test);
    setIsLoading(true);
    if (openDay <= today) {
      setIsLoading(false);
    }
  }, [openDay, today]);

  useEffect(() => {
    if (user && openDay - test >= 0 && openDay <= today) {
      // matching 가져와서 업데이트해주기
      async function updateMatching() {
        const unsubscribe = await getCollectionStream(
          FirestorePath.matchings(),
          (querySnapshot) => {
            if (!querySnapshot.empty) {
              const docs = [];
              querySnapshot.forEach((doc) => {
                docs.push(matchingConverter.fromFirestore(doc));
              });

              // querySnapshot.docChanges().forEach((change) => {
              //   if (change.type === 'added') {
              //     console.log('New city: ', change.doc.data());
              //   }
              //   if (change.type === 'modified') {
              //     console.log('Modified city: ', change.doc.data());
              //   }
              //   if (change.type === 'removed') {
              //     console.log('Removed city: ', change.doc.data());
              //   }
              // });
              setMatchingList(docs);

              let mentee = [];
              docs.map((d) => {
                const approved = d.approvedMentee;
                const menteeList = d.menteeList;
                if (user.userType === 'mentor') {
                  mentee = [menteeList[approved]];
                } else {
                  mentee.push({
                    aid: d.mentorAid,
                    birth: d.mentorBirth,
                    contact: d.mentorContact,
                    availableCourses: d.mentorCourses,
                    availableDateTime: d.mentorDateTime,
                    email: d.mentorEmail,
                    extraSubjects: d.mentorExtraSubjects,
                    gender: d.mentorGender,
                    name: d.mentorName,
                    personality: d.mentorPersonality,
                    availableSubjects: d.mentorSubjects,
                    uid: d.mentorUid,
                    userType: d.userType,
                  });
                }
              });
              setApprovedMentee(mentee);
            }
          },
          [
            user.userType === 'mentor'
              ? where('mentorUid', '==', user.uid)
              : // where('mentorUid', '==', 'hRlf0Xd9mFVLGO7sm5g14fp7Ivu2')
                where('approvedMentee', '==', userApplication.aid),
            where('state', '==', 1),
            orderBy('appliedAt', 'desc'),
          ]
        );
        return unsubscribe;
      }

      updateMatching();
    }
  }, [user, setMatchingList, userApplication]);

  let rederContent;

  if (!userApplication) {
    rederContent = <Waiting />;
  } else {
    rederContent = (
      <Box className="flex flex-col justify-start items-center w-full px-4 py-10">
        <MyTitle title="매칭" />
        {matchingList.length ? (
          // 매칭후(멘티, 멘토)
          <InfoBox title="매칭 상세보기">
            <Box>{user.userType === 'mentor' ? '멘티(학생)' : '멘토(선생님)'} 정보</Box>
            {matchingList
              .slice(0, user.userType === 'mentor' ? 1 : matchingList.length)
              .map((matching, index) => (
                <Box
                  key={index}
                  className="flex flex-col justify-center w-full border rounded-xl box-border"
                >
                  <Box className="flex w-full justify-start py-5 px-8">
                    {subjectIcon[matching.subject.slice(0, 2)]} {matching.subject}
                  </Box>
                  <Divider />
                  <Box className="flex flex-col p-8 gap-4">
                    <Info partner={approvedMentee[index]} contactVisible={true} />
                    <TimeBlocks mentee={approvedMentee[index]} mentor={userApplication} />
                  </Box>
                </Box>
              ))}
          </InfoBox>
        ) : closeDay - today > 0 ? (
          // 매칭전(멘티, 멘토)
          // 내 정보 페이지 - 매칭 페이지(매칭 신청 전)
          <>
            {user.userType === 'mentor' || user.userType === 'admin' ? (
              // 매칭전(멘토)
              <MentorMatching
                application={userApplication}
                isLoading={isLoading}
                matchingStatus={userApplication.matchingStatus}
              />
            ) : (
              // 매칭전(멘티)
              <Waiting />
            )}
          </>
        ) : (
          <SomethingWrong isMentor={user.userType === 'mentor' || user.userType === 'admin'} />
        )}
      </Box>
    );
  }
  return rederContent;
};

export default withRouter(Matching);
