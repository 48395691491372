export const COMMON = {
  ORDINAL: 5,
  TOTAL_MENTOR: 402,
  TOTAL_MENTEE: 246,
};

// admin 페이지 등에서 쿼리된 결과물에서 admin 계정 지워주기 위해 사용
export const adminAccountList = [
  'bhgt4563@gmail.com',
  'sorahyun83@gmail.com',
  'ljeongmin0908@gmail.com',
  'dpwls2588@gmail.com',
  'baenampit@gmail.com',
];
