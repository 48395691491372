import Home from 'pages/home/Home';
import NoMatch from 'pages/NoMatch';
import SignIn from 'pages/signin/SignIn';
import SignUp from 'pages/signup/SignUp';
import { Route, Routes, Redirect } from 'react-router-dom';
import About from 'pages/about/About';
import PrivacyPolicy from 'pages/policy/PrivacyPolicy';
import Applications from 'pages/applications';
import MyPage from 'pages/my_page/MyPage';
import Faq from 'pages/faq/Faq';
import TermsOfService from 'pages/policy/TermsOfService';
import QnaMain from 'pages/qna/index';
import Qna from 'pages/qna/Qna';
import Process from 'pages/process/Process';
import ResetPassword from 'pages/resetPassword/index';
import ProtectedRoute from './ProtectedRoute';
import ApplicationTutee from 'pages/application_tutee/ApplicationTutee';
import SelectMenteeTest from 'pages/my_page/matching/select_mentee/SelectMenteeTest';
import SelectMentee from 'pages/my_page/matching/SelectMentee';
import ApproveMatching from 'pages/admin/ApproveMatching';
import CheckStatus from 'pages/admin/CheckStatus';
import CheckPlanningStatus from 'pages/admin/CheckPlanningStatus';
import RecruitmentEnd from 'components/informations/RecruitmentEnd';
import CheckLessonLog from 'pages/admin/CheckLessonLog';
import AdminUsers from 'pages/admin/userManagement/AdminUsers';
import AdminClasses from 'pages/admin/classManagement/AdminClasses';

const Router = () => {
  return (
    <Routes>
      <Route path="*" element={<NoMatch />} />
      <Route path="/" element={<Home />} />
      {/* 로그인, 회원가입, 비밀번호 찾기 */}
      <Route
        path="/signin"
        element={
          <ProtectedRoute>
            <SignIn />
          </ProtectedRoute>
        }
      />
      <Route
        path="/bnp-sign-up"
        element={<ProtectedRoute>{true ? <SignUp /> : <RecruitmentEnd />}</ProtectedRoute>}
      />
      <Route path="/reset-password" element={<ResetPassword />} />
      {/* 배남프 소개 */}
      <Route path="/about" element={<About />} />
      {/* 진행 일정 */}
      <Route path="/process" element={<Process />} />
      {/* 자주 묻는 질문 */}
      <Route path="/faq" element={<Faq />} />
      {/* 내 정보, 멘토링 정보 */}
      <Route path="/mypage" element={<MyPage />} />
      <Route path="/mentoring-info" element={<Applications />} />
      {/* 질문게시판 */}
      <Route path="/QnA" element={<QnaMain />} />
      <Route path="/QnA/:id" element={<Qna status="detail" />} />
      <Route path="/QnA/:id/edit" element={<Qna status="edit" />} />
      <Route path="/QnA/new" element={<Qna status="new" />} />
      {/* 약관 */}
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-of-service" element={<TermsOfService />} />
      {/* 어드민 */}
      <Route path="/admin/users" element={<AdminUsers />} />
      <Route path="/admin/classes" element={<AdminClasses />} />
      <Route path="/admin/matchings" element={<ApproveMatching />} />
      <Route path="/admin/test" element={<ApplicationTutee />} />
      <Route path="/admin/matching-test" element={<SelectMenteeTest />} />
      <Route path="/admin/check-status" element={<CheckStatus />} />
      <Route path="/admin/check-planning-status" element={<CheckPlanningStatus />} />
      <Route path="/admin/check-lesson-log" element={<CheckLessonLog />} />
      {/* 매칭하기 */}
      <Route path="/matching/mentor" element={<SelectMentee />} />
    </Routes>
  );
};

export default Router;
