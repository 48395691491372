import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { limit, orderBy, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import FirestorePath from 'services/firebase_path';
import { getCollectionFuture, setDocument } from 'services/firebase_services';
import { selectUser } from 'store/userSlice';
import styled from 'styled-components';
import UsersMentees from './mentees/UsersMentees';
import UsersMentors from './mentors/UsersMentors';

const AdminUsers = () => {
  const user = useSelector(selectUser);
  const [userType, setUserType] = useState('init');

  useEffect(() => {
    if (user) {
    }
  }, [user]);

  function loadData(type) {
    setUserType(type);
  }

  if (user) {
    return user.userType === 'admin' ? (
      <Box className="flex flex-col w-full p-10">
        <Box className="flex flex-row gap-8 mb-8">
          <Button variant="outlined" onClick={() => loadData('mentee')}>
            멘티(학생)
          </Button>
          <Button variant="outlined" onClick={() => loadData('mentor')}>
            멘토(선생님)
          </Button>
        </Box>
        {userType === 'mentee' ? (
          <UsersMentees />
        ) : userType === 'mentor' ? (
          <UsersMentors />
        ) : (
          <Box>버튼을 클릭해주세요.</Box>
        )}
      </Box>
    ) : (
      <Navigate to="/" />
    );
  } else {
    return <CircularProgress />;
  }
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: 20px 0px;
`;

const TestButton = styled.button`
  display: flex;
  justify-content: center;
  width: 240px;
  background-color: lightblue;
  padding: 4px;
`;

const Application = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-bottom: 10px;

  width: 640px;
`;

const ApproveButton = styled.button`
  display: flex;
  justify-content: center;
  width: 80px;
  background-color: ${(isActive) => {
    return isActive.isActive ? 'grey' : 'lightBlue';
  }};
  border-radius: 20px;
  padding: 4px;
`;

export default AdminUsers;

// tunV5ePNIPMfZtSDbbuX | 최은우 | 1
// L0MZCbqLxg6VKzM5dmoJ | 김아연 멘토 | 1
// KIAXSlszEEDgEG0nLJBb | 권시안 | 1
