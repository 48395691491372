export class ApplicationModel {
  constructor(
    email,
    belong,
    extraBelong,
    prayerRequest,
    aid,
    uid,
    userType,
    pledge,
    personality,
    photoPath,
    name,
    gender,
    extraSubjects,
    contact,
    birth,
    availableSubjects,
    availableDateTime,
    availableCourses,
    createdAt,
    matchingStatus,
    matchingStatusBySubject
  ) {
    this.email = email;
    this.belong = belong;
    this.extraBelong = extraBelong;
    this.prayerRequest = prayerRequest;
    this.aid = aid;
    this.uid = uid;
    this.userType = userType;
    this.pledge = pledge;
    this.personality = personality;
    this.photoPath = photoPath;
    this.name = name;
    this.gender = gender;
    this.extraSubjects = extraSubjects;
    this.contact = contact;
    this.birth = birth;
    this.availableSubjects = availableSubjects;
    this.availableDateTime = availableDateTime;
    this.availableCourses = availableCourses;
    this.createdAt = createdAt;
    this.matchingStatus = matchingStatus;
    this.matchingStatusBySubject = matchingStatusBySubject;
  }

  toString() {
    return `
    ${this.email}, 
    ${this.belong}, 
    ${this.extraBelong}, 
    ${this.prayerRequest}, 
    ${this.aid},
    ${this.uid}, 
    ${this.userType}, 
    ${this.pledge}, 
    ${this.personality}, 
    ${this.photoPath}, 
    ${this.name}, 
    ${this.gender}, 
    ${this.extraSubjects}, 
    ${this.contact}, 
    ${this.birth}, 
    ${this.availableSubjects}, 
    ${this.availableDateTime}, 
    ${this.availableCourses}, 
    ${this.createdAt}, 
    ${this.matchingStatus}, 
    ${this.matchingStatusBySubject}, 
    `;
  }
}

// Firestore data converter
const applicationConverter = {
  toFirestore: (application) => {
    return {
      email: application.email,
      belong: application.belong,
      extraBelong: application.extraBelong,
      prayerRequest: application.prayerRequest,
      aid: application.aid,
      uid: application.uid,
      userType: application.userType,
      pledge: application.pledge,
      personality: application.personality,
      photoPath: application.photoPath,
      name: application.name,
      gender: application.gender,
      extraSubjects: application.extraSubjects,
      contact: application.contact,
      birth: application.birth,
      availableSubjects: application.availableSubjects,
      availableDateTime: application.availableDateTime,
      availableCourses: application.availableCourses,
      createdAt: application.createdAt,
      matchingStatus: application.matchingStatus,
      matchingStatusBySubject: application.matchingStatusBySubject,
    };
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return new ApplicationModel(
      data.email,
      data.belong ?? null,
      data.extraBelong ?? null,
      data.prayerRequest ?? null,
      data.aid,
      data.uid,
      data.userType,
      data.pledge,
      data.personality,
      data.photoPath ?? null,
      data.name,
      data.gender,
      data.extraSubjects ?? null,
      data.contact,
      data.birth,
      data.availableSubjects,
      data.availableDateTime,
      data.availableCourses,
      data.createdAt,
      data.matchingStatus ?? 0,
      data.matchingStatusBySubject ?? {},
      data.guardianName ?? null,
      data.guardianContact ?? null
    );
  },
};

export default applicationConverter;
