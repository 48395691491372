import { Box } from '@mui/material';

const Loading = () => {
  return (
    <Box className="flex justify-center items-center w-full h-full bg-white/75 absolute top-0 z-[9999]">
      <img src="/images/loading.gif" alt="loading..." width={100} />
    </Box>
  );
};

export default Loading;
