import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import Radio from 'elements/CustomRadio';
import { CustomBasicCheckbox as Checkbox } from 'elements/CustomCheckbox';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setApplication } from 'store/applicationSlice';
import { showMessage } from 'store/messageSlice';

const personalityList = ['외향적', '내향적', '합리적', '감성적', '계획적', '즉흥적'];

const MentorInfo = ({ rows, step, setStep, actionPrevStep }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [gender, setGender] = useState(rows.gender);
  const [personality, setPersonality] = useState(rows.personality);

  const handleRadioChange = (event) => {
    setGender(event);
  };

  const handleCheckbox = (value, type) => {
    const list = personality[type];
    if (list.includes(value)) {
      setPersonality({
        ...personality,
        [type]: list.filter((item) => item !== value),
      });
    } else {
      setPersonality({
        ...personality,
        [type]: [...list, value],
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData(e.target);
    formData.delete('mentee');
    formData.delete('mentor');
    const data = Object.fromEntries(formData.entries());
    if (data.name === '') {
      dispatch(showMessage({ message: '이름을 입력해주세요.', variant: 'error' }));
      return;
    }
    if (data.contact === '') {
      dispatch(showMessage({ message: '전화번호를 입력해주세요.', variant: 'error' }));
      return;
    }
    if (gender === '') {
      dispatch(showMessage({ message: '성별을 선택해주세요.', variant: 'error' }));
      return;
    }
    if (data.birth === '') {
      dispatch(showMessage({ message: '생년월일을 입력해주세요.', variant: 'error' }));
      return;
    }
    data.gender = gender;
    data.personality = personality;
    dispatch(setApplication({ step, data, type: 'mentor' }));
    setStep(step + 1);
    setLoading(false);
  };

  return (
    <Box>
      <Box className="text-center">
        <Typography variant="h1">멘토링 정보 입력하기</Typography>
        <Typography component="p" color="error" variant="body3" className="!mt-4">
          * 표시된 항목은 필수 입력 항목입니다.
        </Typography>
      </Box>
      <Box className="p-12 md:p-16" component="form" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Typography className="text-left !font-bold pb-2">이름*</Typography>
            <TextField
              id="name"
              name="name"
              variant="standard"
              placeholder="이름을 입력해주세요."
              InputProps={{ className: 'p-2' }}
              defaultValue={rows.name}
              hiddenLabel
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography className="text-left !font-bold pb-2">연락처*</Typography>
            <TextField
              id="contact"
              name="contact"
              variant="standard"
              placeholder="하이픈(-) 없이 숫자만 입력해주세요."
              InputProps={{ className: 'p-2' }}
              defaultValue={rows.contact}
              hiddenLabel
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography className="text-left !font-bold pb-2">성별*</Typography>
            <Box className="flex !flex-nowrap items-center">
              <Radio title="남자" value="M" checked={gender === 'M'} onChange={handleRadioChange} />
              <Radio title="여자" value="F" checked={gender === 'F'} onChange={handleRadioChange} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography className="text-left !font-bold pb-2">생년월일*</Typography>
            <TextField
              id="birth"
              name="birth"
              type="date"
              variant="standard"
              InputProps={{ className: 'p-2' }}
              defaultValue={rows.birth}
              hiddenLabel
              fullWidth
            />
          </Grid>
        </Grid>
        <Box className="flex flex-row items-center justify-center pt-24">
          <Button
            fullWidth
            className="formButton"
            variant="outlined"
            color="inherit"
            onClick={actionPrevStep}
          >
            취소
          </Button>
          <Button
            fullWidth
            className="formButton"
            type="submit"
            variant="contained"
            disabled={loading}
          >
            다음
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default MentorInfo;
