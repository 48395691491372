import { Box, Dialog, Typography } from '@mui/material';
import Info from './components/Info';
import TimeBlocks from './components/TimeBlocks';

const MenteeInfoDialog = (props) => {
  const { open, handleClose, mentee, mentor } = props;

  const availableSub = mentee.availableSubjects ?? [];

  return (
    <Dialog
      maxWidth={'lg'}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: { borderRadius: 20, width: '70%' },
      }}
    >
      <Box className="flex flex-col justify-center items-center p-10 gap-4">
        <Box className="flex flex-col w-full p-8 gap-4">
          <Info partner={mentee} contactVisible={false} />
          <Box className="flex flex-col justify-start w-full px-12">
            <Typography
              variant="body3"
              // color={(theme) => theme.palette.error.main}
              fontWeight="bold"
            >
              - 신청과목: {availableSub.join(',')}
            </Typography>
            <Typography
              variant="body3"
              // color={(theme) => theme.palette.error.main}
              fontWeight="bold"
            >
              - 기타참고사항: {mentee.extraSubjects}
            </Typography>
          </Box>
          <TimeBlocks mentee={mentee} mentor={mentor} />
        </Box>
        <Box
          className="flex justify-center items-center w-1/2 h-14 bg-[#E4782A] rounded-xl cursor-pointer"
          onClick={handleClose}
          sx={{ color: 'white' }}
        >
          닫기
        </Box>
      </Box>
    </Dialog>
  );
};

export default MenteeInfoDialog;
