import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Box, Button, Divider, Grid, Link, TextField, Typography } from '@mui/material';
import Root from 'components/Root';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { firebaseAuth } from 'firebase_config';
import { useDispatch } from 'react-redux';
import { showMessage } from 'store/messageSlice';

const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loginToApp = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);
    signInWithEmailAndPassword(firebaseAuth, data.email, data.password)
      .then((userAuth) => {
        if (userAuth.user.emailVerified === false) {
          dispatch(showMessage({ message: '이메일 인증을 완료해주세요.', variant: 'error' }));
          navigate('/signin');
          return;
        }
        navigate('/');
      })
      .catch((err) => {
        console.log(err.code);
        switch (err.code) {
          case 'auth/user-not-found':
            return dispatch(
              showMessage({
                message: '존재하지 않는 이메일입니다.',
                variant: 'error',
              })
            );
          case 'auth/wrong-password':
            return dispatch(showMessage({ message: '비밀번호를 확인해주세요.', variant: 'error' }));
          case 'auth/email-already-in-use':
            return dispatch(
              showMessage({ message: '이미 사용 중인 이메일입니다.', variant: 'error' })
            );
          case 'auth/weak-password':
            return dispatch(
              showMessage({ message: '비밀번호는 8자 이상이어야 합니다.', variant: 'error' })
            );
          case 'INVALID_PASSWORD':
            return dispatch(showMessage({ message: '비밀번호를 확인해주세요.', variant: 'error' }));
          case 'auth/network-request-failed':
            return dispatch(
              showMessage({ message: '네트워크 연결을 확인해주세요.', variant: 'error' })
            );
          case 'auth/invalid-email':
            return dispatch(showMessage({ message: '이메일을 확인해주세요', variant: 'error' }));
          case 'auth/internal-error':
            return dispatch(
              showMessage({
                message: '서버 오류입니다. 잠시 후 다시 시도해주세요.',
                variant: 'error',
              })
            );
          case 'auth/too-many-requests':
            return dispatch(
              showMessage({
                message: '비정상적인 접근으로 인해 차단되었습니다.\n잠시 후 다시 시도해주세요.',
                variant: 'error',
              })
            );
          default:
            return dispatch(
              showMessage({ message: '로그인에 실패 하였습니다.', variant: 'error' })
            );
        }
      });
  };

  return (
    <Root>
      <Wrapper>
        <Typography variant="h1" component="h1" pb="4rem">
          로그인
        </Typography>
        <Box className="px-12 md:px-16" component="form" onSubmit={loginToApp}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <TextField
                id="email"
                name="email"
                variant="outlined"
                placeholder="이메일을 입력해주세요."
                InputProps={{ className: '!rounded-xl' }}
                required
                label="이메일"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="password"
                name="password"
                variant="outlined"
                type="password"
                placeholder="8~20자 이내로 입력해주세요."
                InputProps={{ className: '!rounded-xl' }}
                required
                label="비밀번호"
                fullWidth
              />
            </Grid>
          </Grid>
          <Box className="pt-10 w-1/2 m-auto text-center">
            <Button fullWidth className="formButton" type="submit" variant="contained">
              로그인
            </Button>
          </Box>
          <Box className="py-10">
            <Divider className="mt-10" />
          </Box>
          <Box className="m-auto flex flex-col items-center gap-4">
            <Box className='flex items-center gap-2'>
              <Typography variant="body2">
                비밀번호를 잊으셨나요?
              </Typography>
              <Link
                className="!underline !underline-offset-4"
                sx={{ color: 'warning.main' }}
                href="/reset-password"
              >
                비밀번호 재설정
              </Link>
            </Box>
            <Box className='flex items-center gap-2'>
              <Typography variant="body2">
                아직 배남프 회원이 아니신가요?
              </Typography>
              <Link
                className="!underline !underline-offset-4 hover:!text-sky-700"
                href="/bnp-sign-up"
              >
                회원가입
              </Link>
            </Box>
          </Box>
        </Box>
      </Wrapper>
    </Root>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;
  min-width: 100vw;
  height: calc(100vh - 148px);
`;
export default SignIn;
