import { Box, MenuItem, Select, Typography } from '@mui/material';
import Loading from 'components/Loading';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserApplications, selectUser, selectUserApplication } from 'store/userSlice';

const InfoMenus = ({ menuIndex, setMenuIndex }) => {
  const menuList = ['✍️ 내 정보', '🤝 매칭'];
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  const userApplications = useSelector(getUserApplications);

  const [age, setAge] = useState(0);

  if (user.userType === 'mentor' || user.userType === 'admin') {
    menuList.push('📆 수업계획서');
  }

  const handleChange = (event) => {
    setAge(event.target.value);
    dispatch(selectUserApplication(event.target.value));
  };

  if (!userApplications?.length) {
    return <Loading />;
  }

  return (
    <Box className="flex">
      <Box
        className="flex flex-col bg-white lg:w-64 w-full h-auto px-8 py-10 gap-5 lg:m-0 mx-4 mt-10 lg:rounded-none rounded-lg"
        sx={{ boxShadow: 1 }}
      >
        {user.userType === 'mentee' ? (
          <Select value={age} onChange={handleChange} displayEmpty>
            {userApplications.map((e, index) => {
              return (
                <MenuItem key={e.aid} value={index}>
                  {e.gender === 'M' ? '🙋‍♂️ ' : '🙋‍♀️ '}
                  {e.name}
                </MenuItem>
              );
            })}
          </Select>
        ) : (
          <></>
        )}
        {menuList.map((menu, index) => (
          <Box
            key={index}
            className="flex justify-start items-center px-10 py-4 rounded-lg hover:bg-gray-100 cursor-pointer"
            onClick={() => setMenuIndex(index)}
            sx={
              menuIndex == index
                ? { backgroundColor: 'rgb(243 244 246)' }
                : { backgroundColor: 'white' }
            }
          >
            <Typography>{menu}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default InfoMenus;
