import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { db } from 'firebase_config';
import { collection, doc, getDocs, query, serverTimestamp, setDoc, updateDoc, where } from 'firebase/firestore';

const initialState = {
    data: null,
    docId: null,
};

export const fetchLessonLog = createAsyncThunk('lessonLog/fetchLessonLog', async (uid) => {
    const fetchRef = collection(db, 'LessonLogs');
    const queryRef = query(fetchRef, where('uid', '==', uid));
    const querySnapshot = await getDocs(queryRef);

    if (querySnapshot.empty) {
        return null;
    }

    let logData = [];
    querySnapshot.forEach((doc) => {
        if (doc.exists()) {
            logData.push({
                ...doc.data(),
                docId: doc.id,
            });
        }
    });

    return logData;
});


export const saveLessonLog = createAsyncThunk('lessonLog/saveLessonLog', async ({ data, uid, term, aid }) => {
    try {
        const newData = { [term]: { ...data, createdAt: serverTimestamp() }, uid, aid };
        const docRef = doc(collection(db, 'LessonLogs'));
        await setDoc(docRef, newData);

        return docRef.id;
    } catch (error) {
        throw error;
    }
});

export const updateLessonLog = createAsyncThunk('lessonLog/updateLessonLog', async ({ data, docId, term }) => {
    try {
        const newData = { [term]: { ...data, createdAt: serverTimestamp() } };
        const docRef = await updateDoc(doc(db, 'LessonLogs', docId), newData);

        return { docRef, newData };
    } catch (error) {
        throw error;
    }
});

export const editLessonLog = createAsyncThunk('lessonLog/editLessonLog', async ({ data, docId, term }) => {
    try {
        const newData = { [term]: { ...data, updatedAt: serverTimestamp() } };
        const docRef = await updateDoc(doc(db, 'LessonLogs', docId), newData);

        return { docRef, newData };
    } catch (error) {
        throw error;
    }
});

const lessonLogSlice = createSlice({
    name: 'lessonLog',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchLessonLog.fulfilled, (state, action) => {
                state.data = action.payload;
            })
            .addCase(saveLessonLog.fulfilled, (state, action) => {
                state.docId = action.payload;
            })
    }

});


export const selectLogData = (state) => state.lessonLog.data;

export default lessonLogSlice.reducer;