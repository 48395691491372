import { Button, styled } from '@mui/material';

const CustomButton = styled(Button)(({ theme }) => ({
  width: '100%',
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: '6rem',
  padding: '0.5rem 1rem',
  margin: 5,
  textTransform: 'none',
  '&.Mui-disabled': {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
}));

export default CustomButton;
