import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import styled from 'styled-components';

import React, { useState } from 'react';
import Root from 'components/Root';
import { useDispatch } from 'react-redux';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import TermsModal from './TermsModal';
import { showMessage } from 'store/messageSlice';
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { firebaseAuth } from 'firebase_config';
import Loading from 'components/Loading';
import { useNavigate } from 'react-router-dom';
import { signUp } from 'store/userSlice';
import Swal from 'sweetalert2';

const SignUpOver = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({
    uid: '',
    name: '',
    isMale: false,
    birth: '',
    age: 0,
    userType: null,
    email: '',
    over14: false,
    privacyPolicy: false,
    termsOfService: false,
    createdAt: '',
  });
  const [password, setPassword] = useState('');
  const [passwordCheck, setPasswordCheck] = useState('');
  const [showPass, setShowPass] = useState(false);
  const [showPassConfirm, setShowPassConfirm] = useState(false);
  const [checked, setChecked] = useState({ over14: false, terms: false });

  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState('');

  const emailRegEx =
    /^[A-Za-z0-9]([-_.]?[A-Za-z0-9])*@[A-Za-z0-9]([-_.]?[A-Za-z0-9])*\.[A-Za-z]{2,}$/;
  const passwordRegEx = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,20}$/;

  const agreementAge = (
    <AgreementText>
      본인은 <b>14세 이상</b>입니다. <b>(필수)</b>
    </AgreementText>
  );

  const agreementTerms = (
    <AgreementText>
      <b>개인정보처리방침</b>과 <b>서비스이용약관</b>에 동의하시겠습니까? <b>(필수)</b>
    </AgreementText>
  );

  const handleSelectUserType = (event) => {
    setUserInfo({ ...userInfo, userType: event.target.value });
  };

  const handleChange = (key) => {
    setChecked({ ...checked, [key]: !checked[key] });
    if (key === 'terms') {
      setUserInfo({ ...userInfo, privacyPolicy: !checked[key], termsOfService: !checked[key] });
      return;
    }
    setUserInfo({ ...userInfo, [key]: !userInfo[key] });
  };

  const handleOpen = (v) => {
    setModalOpen(true);
    if (v === 'privacy') setModalType('privacy');
    else setModalType('terms');
  };
  const handleClose = () => {
    setModalOpen(false);
    setModalType('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // 회원 유형 선택 검사
    if (!userInfo?.userType) {
      dispatch(showMessage({ message: '회원 유형을 선택해주세요.', variant: 'error' }));
      return;
    }
    // 이메일 유효성 검사
    if (userInfo?.email === '') {
      dispatch(showMessage({ message: '이메일을 입력해주세요.', variant: 'error' }));
      return;
    } else if (!emailRegEx.test(userInfo?.email)) {
      dispatch(showMessage({ message: '이메일 형식이 올바르지 않습니다.', variant: 'error' }));
      return;
    }
    // 비밀번호 유효성 검사
    if (password.length < 8 || password.length > 20) {
      dispatch(
        showMessage({
          message: '비밀번호는 8자리 이상 20자리 이하로 입력해주세요.',
          variant: 'error',
        })
      );
      return;
    } else if (!password.match(/[A-Z]/)) {
      dispatch(
        showMessage({ message: '비밀번호에 하나 이상의 대문자를 포함해주세요.', variant: 'error' })
      );
      return;
    } else if (!password.match(/[0-9]/)) {
      dispatch(
        showMessage({ message: '비밀번호에 하나 이상의 숫자를 포함해주세요.', variant: 'error' })
      );
      return;
    } else if (!password.match(/[!@#$%^&*]/)) {
      dispatch(
        showMessage({
          message: '비밀번호에 하나 이상의 특수문자(!@#$%^&*)를 포함해주세요.',
          variant: 'error',
        })
      );
      return;
    }
    // 비밀번호 확인 검사
    if (passwordCheck === '') {
      dispatch(showMessage({ message: '비밀번호 확인을 입력해주세요.', variant: 'error' }));
      return;
    } else if (password !== passwordCheck) {
      dispatch(showMessage({ message: '비밀번호가 일치하지 않습니다.', variant: 'error' }));
      return;
    }
    // 14세 이상 동의 검사
    if (!userInfo?.over14) {
      dispatch(showMessage({ message: '14세 이상 동의를 해주세요.', variant: 'error' }));
      return;
    }
    // 약관 동의 검사
    if (!userInfo?.privacyPolicy || !userInfo?.termsOfService) {
      dispatch(showMessage({ message: '약관 동의를 해주세요.', variant: 'error' }));
      return;
    }
    setIsLoading(true);
    await createUserWithEmailAndPassword(firebaseAuth, userInfo?.email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        sendEmailVerification(firebaseAuth.currentUser).then(() => {
          dispatch(signUp({ user, userInfo })).then((res) => {
            if (res.payload) {
              setIsLoading(false);
              Swal.fire({
                icon: 'success',
                html: '<p>입력하신 이메일로\n인증 URL이 발송되었습니다.</p>',
                confirmButtonText: '확인',
                confirmButtonColor: '#169DC5',
                allowOutsideClick: false,
                allowEscapeKey: false,
              }).then((res) => {
                if (res.isConfirmed) {
                  setIsLoading(false);
                  navigate('/signin');
                }
              });
            }
          });
        });
      })
      .catch((error) => {
        setIsLoading(false);
        const errorCode = error.code;
        if (errorCode === 'auth/email-already-in-use') {
          dispatch(showMessage({ message: '이미 사용 중인 이메일입니다.', variant: 'error' }));
          return;
        }
      });
  };

  return (
    <Root>
      {isLoading && <Loading />}
      <Wrapper>
        <Typography variant="h1" component="h1" paddingBottom="1rem">
          회원가입
        </Typography>
        <Box className="my-11" component="form" onSubmit={handleSubmit}>
          <Grid container rowGap={4} className="px-12 md:px-16 max-w-[600px]">
            <Grid item xs={12} sm={6} className="flex items-center justify-start">
              <SelectBox className={userInfo?.userType === 'mentor' ? 'checked' : ''}>
                <input
                  type="radio"
                  name="userType"
                  value="mentor"
                  checked={userInfo?.userType === 'mentor'}
                  onChange={handleSelectUserType}
                />
                <img src="/images/signup/mentor.svg" alt="멘토" />
                <Typography variant="h2" component="h2" paddingTop="1rem">
                  멘토(선생님)
                </Typography>
              </SelectBox>
            </Grid>
            <Grid item xs={12} sm={6} className="flex items-center justify-end">
              <SelectBox className={userInfo?.userType === 'mentee' ? 'checked' : ''}>
                <input
                  type="radio"
                  name="userType"
                  value="mentee"
                  checked={userInfo?.userType === 'mentee'}
                  onChange={handleSelectUserType}
                />
                <img src="/images/signup/mentee.svg" alt="멘티" />
                <Typography variant="h2" component="h2" paddingTop="1rem">
                  멘티(학생)
                </Typography>
              </SelectBox>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="email"
                name="email"
                type="email"
                variant="outlined"
                placeholder="이메일을 입력해주세요."
                InputProps={{ className: '!rounded-xl' }}
                required
                label="이메일"
                fullWidth
                value={userInfo?.email}
                onChange={(e) => setUserInfo({ ...userInfo, email: e.target.value })}
                error={userInfo?.email !== '' && !emailRegEx.test(userInfo?.email)}
                helperText={
                  userInfo?.email !== '' && !emailRegEx.test(userInfo?.email)
                    ? '이메일 형식이 올바르지 않습니다.'
                    : ''
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="password"
                name="password"
                type={showPass ? 'text' : 'password'}
                variant="outlined"
                placeholder="비밀번호를 입력해주세요."
                InputProps={{
                  className: '!rounded-xl',
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPass(!showPass)}>
                        {showPass ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                required
                label="비밀번호"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                error={password !== '' && !passwordRegEx.test(password)}
                helperText={
                  password !== '' && !passwordRegEx.test(password)
                    ? '8~20자의 영문 대소문자, 숫자, 특수문자를 조합하여 입력해주세요.'
                    : ''
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="passwordCheck"
                name="passwordCheck"
                type={showPassConfirm ? 'text' : 'password'}
                variant="outlined"
                placeholder="비밀번호를 한번 더 입력해주세요."
                InputProps={{
                  className: '!rounded-xl',
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassConfirm(!showPassConfirm)}>
                        {showPassConfirm ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                required
                label="비밀번호 확인"
                fullWidth
                value={passwordCheck}
                onChange={(e) => setPasswordCheck(e.target.value)}
                error={passwordCheck !== '' && password !== passwordCheck}
                helperText={
                  passwordCheck !== '' &&
                  password !== passwordCheck &&
                  '비밀번호가 일치하지 않습니다.'
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={userInfo?.over14 || false}
                      onChange={() => handleChange('over14')}
                    />
                  }
                  label={agreementAge}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked.terms || false}
                      onChange={() => handleChange('terms')}
                    />
                  }
                  label={agreementTerms}
                />
              </FormGroup>
              <ul style={{ gap: 12, paddingLeft: 30 }}>
                <li>
                  <Typography
                    className="!text-sm !font-normal underline underline-offset-2 cursor-pointer"
                    color={(theme) => theme.palette.primary.main}
                    component="a"
                    onClick={() => handleOpen('privacy')}
                  >
                    개인정보처리방침 →
                  </Typography>
                </li>
                <li>
                  <Typography
                    className="!text-sm !font-normal underline underline-offset-2 cursor-pointer"
                    color={(theme) => theme.palette.primary.main}
                    component="a"
                    onClick={() => handleOpen('service')}
                  >
                    서비스이용약관 →
                  </Typography>
                </li>
              </ul>
            </Grid>
          </Grid>
          <Box className="w-[375px] mt-16 mx-auto">
            <Typography className="text-center !text-sm !font-normal">
              가입하기를 완료하면 입력하신 이메일로 인증 URL이 발송됩니다.
              <br />
              이메일 인증이 완료되면 정상적으로 로그인 하실 수 있습니다.
            </Typography>
            <Button type="submit" variant="contained" className="!mt-8 !p-2" fullWidth>
              회원가입하기
            </Button>
          </Box>
        </Box>
        {modalOpen && modalType !== '' && (
          <TermsModal modalOpen={modalOpen} handleClose={handleClose} modalType={modalType} />
        )}
      </Wrapper>
    </Root>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 0;
  width: 100vw;
  min-height: calc(100vh - 148px);
`;

const SelectBox = styled.label`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #a3a3a3;
  background: #fff;
  width: 215px;
  height: 203px;
  flex-shrink: 0;
  & > input {
    display: none;
  }
  &:hover {
    border: 3px solid #169dc5;
  }
  &.checked {
    border: 3px solid #169dc5;
  }
`;

const AgreementText = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
`;

export default SignUpOver;
