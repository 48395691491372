export default class FirestorePath {
  static users() {
    return 'Users/';
  }

  static user(uid) {
    return `Users/${uid}`;
  }

  static tutees() {
    return 'Tutees/';
  }

  static tutee(tid) {
    return `Tutees/${tid}`;
  }

  static menus() {
    return 'Menus/';
  }

  static application(aid) {
    return `Applications/${aid}`;
  }

  static applications() {
    return 'Applications/';
  }

  static notice(nid) {
    return `Notices/${nid}`;
  }

  static notices() {
    return 'Notices/';
  }

  static matching(mid) {
    return `Matchings/${mid}`;
  }

  static matchings() {
    return 'Matchings/';
  }

  static planning(pid) {
    return `Plannings/${pid}`;
  }

  static plannings() {
    return 'Plannings/';
  }

  static lessonlogs() {
    return 'LessonLogs/';
  }

  static lessonlog(lid) {
    return `LessonLogs/${lid}`;
  }
}
