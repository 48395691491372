import CustomButton from './CustomButton';

function CustomRadio(props) {
  const { checked, value, onChange, title } = props;

  return (
    <CustomButton
      variant={checked ? 'contained' : 'outlined'}
      color="primary"
      disabled={checked}
      onClick={() => onChange(value)}
    >
      {title}
    </CustomButton>
  );
}

export default CustomRadio;
