import { Box, Button, Typography } from '@mui/material';
import { increment, limit, orderBy, serverTimestamp, where } from 'firebase/firestore';
import applicationConverter from 'models/application_model';
import matchingConverter from 'models/matching_model';
import tuteeConverter, { TuteeModel } from 'models/tutee_model';
import { useState } from 'react';
// import userConverter from 'models/user_model';
// import React from 'react';
import FirestorePath from 'services/firebase_path';
import {
  addDocument,
  deleteDocument,
  getCollectionFuture,
  getCollectionStream,
  getDocumentFuture,
  setDocument,
} from 'services/firebase_services';
import styled from 'styled-components';

const ApplicationTutee = () => {
  const { unsub, setUnsub } = useState(() => {});
  const [subject, setSubject] = useState('');
  const [mentor, setMentor] = useState({});
  const [mentee, setMentee] = useState({});
  const [applicationList, setApplicationList] = useState([]);
  const [matchingList, setMatchingList] = useState([]);

  const saveMatchingData = async (e) => {
    // 매칭하기 버튼 클릭 시 동작할 함수
    const menteeList = [mentee];
    const menteeUidList = Array.from(new Set([menteeList[0].uid]));
    const menteeAidList = [menteeList[0].aid];

    const array = [[menteeList[0].aid, applicationConverter.toFirestore(menteeList[0])]];
    const menteeMap = Object.fromEntries(array);

    const { uid, email } = mentor;
    const {
      aid,
      contact,
      name,
      gender,
      birth,
      availableCourses,
      availableSubjects,
      availableDateTime,
      extraSubjects,
      personality,
    } = mentor;

    // 1. matching doc 생성
    const object = {
      subject: subject, // 과목 이름
      state: 1, // 매칭 승인 상태- 0:매칭 승인 대기중- 1:매칭 승인- 2:매칭 보류
      appliedAt: serverTimestamp(), // 매칭신청 시점. - firestore 함수 serverTimestamp 사용(서버시간으로 저장됨)
      approvedAt: serverTimestamp(), // 매칭승인 시점. - firestore 함수 serverTimestamp 사용(서버시간으로 저장됨)
      approvedMentee: mentee.aid, // 승인된 멘티 application id
      mentorUid: uid, // 멘토 user id
      mentorAid: aid, // 멘토 application id
      mentorEmail: email, // 멘토 이메일
      mentorContact: contact, // 멘토 연락처(전화번호)
      mentorName: name, // 멘토 이름
      mentorGender: gender, // 멘토 성별
      mentorBirth: birth, // 멘토 생일(나이)
      mentorCourses: availableCourses, // 멘토 수업 과정ex) ['유초등저학년(1~2학년)', '중등', '고등']
      mentorSubjects: availableSubjects, // 멘토 수업 가능 과목
      mentorExtraSubjects: extraSubjects, // 멘토 기타 과목
      mentorDateTime: availableDateTime, // 멘토 수업 가능 시간대
      mentorPersonality: personality, // 멘토 성향
      menteeUidList: menteeUidList, // 멘티들 user id 리스트(쿼리를 위한)
      menteeAidList: menteeAidList, // 멘티들 application id 리스트(쿼리를 위한)
      menteeList: menteeMap, // 멘티 Application Object 리스트
      userType: 'mentor',
    };

    console.log('object');
    console.log(object);

    const docId = await addDocument(
      FirestorePath.matchings(),
      matchingConverter.toFirestore(object),
      'mid',
      true
    );

    console.log(docId);

    // 2. application 에 매칭 진행 상태값 matchingStatus 넣어주기
    menteeList.map(async (mentee) => {
      // console.log('여기');
      // console.log(mentee.matchingStatusBySubject[subject]);
      const newMap = {
        ...mentee.matchingStatusBySubject,
        [subject]:
          mentee.matchingStatusBySubject[subject] === undefined
            ? 1
            : mentee.matchingStatusBySubject[subject] + 1,
      };
      const doc = await setDocument(
        FirestorePath.application(mentee.aid),
        { ...mentee, matchingStatus: increment(1), matchingStatusBySubject: newMap }, //0: 매칭전, 1: 매칭중(멘토가 골랐음), 2:매칭완료
        true
      );
      if (doc === undefined) {
        // console.log('no data');
      } else {
        // console.log('do something');
        // console.log(doc);
      }
    });

    // 2.2. 멘토 application에도 상태값 넣어주기
    const mentorDoc = await setDocument(
      FirestorePath.application(mentor.aid),
      { ...mentor, matchingStatus: 1 }, //0: 매칭전, 1: 매칭중(멘토가 골랐음), 2:매칭완료
      true
    );
    if (mentorDoc === undefined) {
      console.log('no data');
    } else {
      console.log('do something');
      console.log(mentorDoc);
    }
  };

  return (
    <>
      <div>Firestore CRUD 테스트</div>
      <br />
      <TestButton
        onClick={async () => {
          const object = {
            // mid: '', // 매칭 doc id. firestore 자동 생성
            subject: '과학', // 과목 이름
            state: 1, // 매칭 승인 상태- 0:매칭 승인 대기중- 1:매칭 승인- 2:매칭 보류 - 3:매칭 반려
            appliedAt: serverTimestamp(), // 매칭신청 시점. - firestore 함수 serverTimestamp 사용(서버시간으로 저장됨)
            approvedAt: serverTimestamp(), // 매칭승인 시점. - firestore 함수 serverTimestamp 사용(서버시간으로 저장됨)
            approvedMentee: 'sFfMDHDAGtlXuKaoE6hZ', // 승인된 멘티 application id
            mentorUid: 'eEYOGSYFMJN4SWOz7YgCh32ot1j2', // 멘토 user id
            mentorAid: '1GvxIlfeDanhwXpi3olv', // 멘토 application id
            mentorEmail: 'bhgt4563@gmail.com', // 멘토 이메일
            mentorContact: '01051340962', // 멘토 연락처(전화번호)
            mentorName: '윤한규', // 멘토 이름
            mentorGender: 'M', // 멘토 성별
            mentorBirth: '1996-05-02', // 멘토 생일(나이)
            mentorCourses: [
              '유초등저학년(1~2학년)',
              '초등중학년(3~4학년)',
              '초등고학년(5~6학년)',
              '중등',
              '고등',
            ], // 멘토 수업 과정ex) ['유초등저학년(1~2학년)', '중등', '고등']
            mentorSubjects: ['영어', '과학', '역사', '기타'], // 멘토 수업 가능 과목
            mentorExtraSubjects: '프로그래밍', // 멘토 기타 과목
            mentorDateTime: [
              '월-18-19',
              '월-19-20',
              '월-20-21',
              '월-21-22',
              '화-18-19',
              '화-19-20',
              '화-20-21',
              '화-21-22',
              '수-18-19',
              '수-19-20',
              '수-20-21',
              '수-21-22',
              '목-18-19',
              '목-19-20',
              '목-20-21',
              '목-21-22',
              '금-18-19',
              '금-19-20',
              '금-20-21',
              '금-21-22',
              '토-09-10',
              '토-10-11',
              '토-11-12',
              '토-12-13',
              '토-13-14',
              '토-14-15',
              '토-15-16',
              '토-16-17',
              '토-17-18',
              '토-18-19',
              '토-19-20',
              '토-20-21',
              '토-21-22',
              '일-09-10',
              '일-10-11',
              '일-11-12',
              '일-12-13',
              '일-18-19',
              '일-19-20',
              '일-20-21',
              '일-21-22',
            ], // 멘토 수업 가능 시간대
            mentorPersonality: ['외향적', '계획적', '감성적'], // 멘토 성향
            menteeUidList: ['MJmfxQSYn8f84doREGDl6h0gleA3'], // 멘티들 user id 리스트(쿼리를 위한)
            menteeAidList: ['sFfMDHDAGtlXuKaoE6hZ', 'bt0HR4QbPp9wqjM33zlp'], // 멘티들 application id 리스트(쿼리를 위한)
            // menteeList: null,
            menteeList: {
              sFfMDHDAGtlXuKaoE6hZ: {
                uid: 'MJmfxQSYn8f84doREGDl6h0gleA3', // 멘티 user id
                aid: 'sFfMDHDAGtlXuKaoE6hZ', // 멘티 application id
                email: 'bhgt9510@naver.com', // 멘티 이메일
                contact: 'hongGS', // 멘티 연락처(카카오ID)
                name: '홍길순', // 멘티 이름
                gender: 'F', // 멘티 성별
                birth: '2017-06-15', // 멘티 생일(나이)
                availableCourses: [
                  '유초등저학년(1~2학년)',
                  '초등중학년(3~4학년)',
                  '초등고학년(5~6학년)',
                ], // 멘토 수업 과정ex) [‘유초등저학년(1~2학년)’, ‘중등', ‘고등']
                availableSubjects: ['과학', '기타'], // 멘티 수업 요청 과목
                extraSubjects: '피아노', // 멘티 기타 과목
                availableDateTime: [
                  '월-16-17',
                  '월-17-18',
                  '월-19-20',
                  '월-18-19',
                  '화-16-17',
                  '화-17-18',
                  '화-18-19',
                  '화-19-20',
                  '수-17-18',
                  '수-16-17',
                  '수-18-19',
                  '수-19-20',
                  '목-16-17',
                  '목-17-18',
                  '목-18-19',
                  '목-19-20',
                  '금-16-17',
                  '금-17-18',
                  '금-18-19',
                  '금-19-20',
                  '토-16-17',
                  '토-17-18',
                  '토-19-20',
                  '토-18-19',
                  '토-13-14',
                  '토-14-15',
                  '토-15-16',
                  '토-12-13',
                  '일-13-14',
                  '일-15-16',
                  '일-14-15',
                  '일-16-17',
                  '일-17-18',
                  '일-18-19',
                  '일-19-20',
                  '일-06-07',
                  '일-07-08',
                  '일-08-09',
                  '일-09-10',
                  '일-20-21',
                  '일-21-22',
                ], // 멘티 수업 가능 시간대
                personality: {
                  mentee: ['내향적', '감성적', '계획적'],
                  mentor: ['외향적', '합리적', '즉흥적'],
                }, // 멘티 성향
              },
              bt0HR4QbPp9wqjM33zlp: {
                uid: 'MJmfxQSYn8f84doREGDl6h0gleA3', // 멘티 user id
                aid: 'bt0HR4QbPp9wqjM33zlp', // 멘티 application id
                email: 'bhgt9510@naver.com', // 멘티 이메일
                contact: '4444', // 멘티 연락처(카카오ID)
                name: '44444', // 멘티 이름
                gender: 'M', // 멘티 성별
                birth: '2012-06-12', // 멘티 생일(나이)
                availableCourses: [
                  '유초등저학년(1~2학년)',
                  '초등중학년(3~4학년)',
                  '초등고학년(5~6학년)',
                  '중등',
                  '고등',
                ], // 멘토 수업 과정ex) [‘유초등저학년(1~2학년)’, ‘중등', ‘고등']
                availableSubjects: ['과학', '사회', '역사'], // 멘티 수업 요청 과목
                // extraSubjects: '', // 멘티 기타 과목
                availableDateTime: [
                  '월-19-20',
                  '월-20-21',
                  '수-19-20',
                  '수-20-21',
                  '금-19-20',
                  '금-20-21',
                  '토-10-11',
                  '토-11-12',
                  '토-12-13',
                  '토-19-20',
                  '토-20-21',
                  '토-21-22',
                  '일-14-15',
                  '일-15-16',
                  '일-16-17',
                  '일-17-18',
                ], // 멘티 수업 가능 시간대
                personality: {
                  mentee: ['내향적', '즉흥적'],
                  mentor: ['계획적', '감성적'],
                }, // 멘티 성향
              },
            }, // 멘티 Application Object 리스트
          };
          const docId = await addDocument(
            FirestorePath.matchings(),
            matchingConverter.toFirestore(object),
            'mid',
            true
          );
          console.log(docId);
        }}
      >
        [CREATE] - doc 하나 만들기
      </TestButton>
      <br />
      <TestButton
        onClick={async () => {
          const object = {};

          // console.log(mentor);
          saveMatchingData();

          // const docId = await addDocument(
          //   FirestorePath.matchings(),
          //   matchingConverter.toFirestore(object),
          //   'mid',
          //   true
          // );
          // console.log(docId);
        }}
      >
        [CREATE] - 매칭 만들기
      </TestButton>
      <br />
      <TestButton
        onClick={async () => {
          const tid = 'Wrl4YCDnLafy5MVRJh4h';

          const doc = await getDocumentFuture(FirestorePath.tutee(tid), (data, docId) =>
            tuteeConverter.fromFirestore(data)
          );
          if (doc === undefined) {
            console.log('no data');
          } else {
            console.log('do something');
            console.log(doc);
          }
        }}
      >
        [READ] - 튜티 정보 하나 읽어오기
      </TestButton>
      <br />
      <TestButton
        onClick={async () => {
          // 멘토 가져오기
          const docs = await getCollectionFuture(
            FirestorePath.applications(),
            (data, docId) => applicationConverter.fromFirestore(data),
            [
              where('name', '==', '유운하안규우'),
              // where('userType', '==', 'mentee'),
              // where('state', '!=', 1),
              // where('email', '==', 'bhgt9510@naver.com'),
              // orderBy('name', 'desc'),
              // limit(2),
            ]
          );
          if (docs.length === 0) {
            console.log('no data');
          } else {
            console.log('do something');
            // 테스트 계정 멘티는 지워주기
            const temp = docs.filter(
              (doc) =>
                doc.email !== 'bhgt9510@naver.com' &&
                // doc.email !== 'bhgt4563@gmail.com' &&
                doc.email !== 'cwcw0908@gmail.com' &&
                doc.email !== 'ljeongmin0908@gmail.com'
            );
            console.log(temp);

            temp.map((d) => {
              console.log(d.name + ' - ' + d.aid);
            });

            setMentor(temp[0]);
            setSubject('수학');
          }

          // 멘티 가져오기
          const menteeDocs = await getCollectionFuture(
            FirestorePath.applications(),
            (data, docId) => applicationConverter.fromFirestore(data),
            [
              where('name', '==', '규규2'),
              // where('userType', '==', 'mentee'),
              // where('state', '!=', 1),
              // where('email', '==', 'bhgt9510@naver.com'),
              // orderBy('name', 'desc'),
              // limit(2),
            ]
          );
          if (menteeDocs.length === 0) {
            console.log('no data');
          } else {
            console.log('do something');
            // 테스트 계정 멘티는 지워주기
            const temp = menteeDocs.filter(
              (doc) =>
                // doc.email !== 'bhgt9510@naver.com' &&
                doc.email !== 'bhgt4563@gmail.com' &&
                doc.email !== 'cwcw0908@gmail.com' &&
                doc.email !== 'ljeongmin0908@gmail.com'
            );
            console.log(temp);

            temp.map((d) => {
              console.log(d.name + ' - ' + d.aid);
            });

            setMentee(temp[0]);
          }
        }}
      >
        [READ] - %%% 멘토 정보 쿼리해서 여러개 읽어오기
      </TestButton>
      <Box className="flex gap-4">
        <Typography>{mentor.name ?? 'ㅡㅡ'}</Typography>
        <Typography> == </Typography>
        <Typography>{mentee.name ?? '--'}</Typography>
      </Box>
      <br />
      <TestButton
        onClick={async () => {
          const docs = await getCollectionFuture(
            FirestorePath.matchings(),
            (data, docId) => matchingConverter.fromFirestore(data),
            [
              where('menteeAidList', 'array-contains', 'CfbOa9IDY0cHtQiFIZ2XHdv2fWn1'),
              // where('userType', '==', 'mentee'),
              // where('state', '!=', 1),
              // where('email', '==', 'bhgt9510@naver.com'),
              // orderBy('name', 'desc'),
              // limit(2),
            ]
          );
          if (docs.length === 0) {
            console.log('no data');
          } else {
            console.log('do something');
            // 테스트 계정 멘티는 지워주기
            const temp = docs.filter(
              (doc) =>
                doc.email !== 'bhgt9510@naver.com' &&
                doc.email !== 'bhgt4563@gmail.com' &&
                doc.email !== 'cwcw0908@gmail.com' &&
                doc.email !== 'ljeongmin0908@gmail.com'
            );
            console.log(temp);

            temp.map((d) => {
              console.log(d.mentorName + ' - ' + d.subject);
            });

            setMatchingList(temp);
          }
        }}
      >
        [READ] - @@@ 매칭 정보 쿼리해서 여러개 읽어오기
      </TestButton>
      <br />
      {matchingList.map((app, index) => {
        return (
          <Box className="flex gap-4">
            <Typography>{app.mentorName}</Typography>
            <Typography>{app.subject}</Typography>
            <Button
              onClick={async () => {
                const a = app;

                const doc = await setDocument(
                  // 반려 로직
                  FirestorePath.matching(a.mid),
                  {
                    ...a,
                    state: 3, // 매칭 승인 상태- 0:매칭 승인 대기중- 1:매칭 승인- 2:매칭 보류 - 3:매칭 반려
                    userType: 'mentor',
                    approvedAt: null,
                    approvedMentee: null,
                  },
                  true
                );
                if (doc === undefined) {
                  console.log('no data');
                } else {
                  console.log('do something');
                  console.log(doc);
                }
              }}
            >
              반려
            </Button>
            <Button
              onClick={async () => {
                const a = app;

                const doc = await setDocument(
                  // 승인 로직
                  FirestorePath.matching(a.mid),
                  {
                    ...a,
                    state: 1, // 매칭 승인 상태- 0:매칭 승인 대기중- 1:매칭 승인- 2:매칭 보류 - 3:매칭 반려
                    userType: 'mentor',
                    approvedAt: serverTimestamp(),
                    approvedMentee: 'Lz4y3fkBDTrJlN7HoQqu',
                  },
                  true
                );
                if (doc === undefined) {
                  console.log('no data');
                } else {
                  console.log('do something');
                  console.log(doc);
                }
              }}
            >
              승인
            </Button>
          </Box>
        );
      })}
      <TestButton
        onClick={async () => {
          const docs = await getCollectionFuture(
            FirestorePath.applications(),
            (data, docId) => applicationConverter.fromFirestore(data),
            [
              where('name', 'in', [
                '김동찬',
                '김믿음',
                '김사랑',
                '김소망',
                '박준서',
                '박지수',
                '신예담',
                '양준',
                '오지엘',
                '이다엘',
                '이은결',
                // '장별',
                '전보라',
                '전세움',
                '전심',
                '전예별',
                '전원',
                '전진',
                '주사랑',
                '주사무엘',
                '채하영',
                '채하임',
              ]),
              // where('userType', '==', 'mentee'),
              // where('matchingStatus', '==', 1),
              // where('email', '==', 'bhgt9510@naver.com'),
              // orderBy('name', 'desc'),
              // limit(2),
            ]
          );
          if (docs.length === 0) {
            console.log('no data');
          } else {
            console.log('do something');
            // 테스트 계정 멘티는 지워주기
            const temp = docs.filter(
              (doc) =>
                doc.email !== 'bhgt9510@naver.com' &&
                doc.email !== 'bhgt4563@gmail.com' &&
                doc.email !== 'cwcw0908@gmail.com' &&
                doc.email !== 'ljeongmin0908@gmail.com'
            );
            console.log(temp);

            temp.map((d) => {
              console.log(d.name + '\n');
              d.prayerRequest.map((e) => console.log(e));
            });

            setApplicationList(temp);
          }
        }}
      >
        [READ] - 기도제목 불러오기
      </TestButton>
      <br />
      <TestButton
        onClick={async () => {
          // const temp = {
          //   email: 'tutee1@gmail.com',
          //   name: '튜티1',
          //   tid: 'Wrl4YCDnLafy5MVRJh4h',
          // };
          // applicationList.map(async (temp) => {
          //   // const array = temp.availableSubjects.map((e) => [e, 0]);
          //   // const tttt = Object.fromEntries(array);
          //   const doc = await setDocument(
          //     FirestorePath.application(temp.aid),
          //     { ...temp, matchingStatus: 0 },
          //     true
          //   );
          //   if (doc === undefined) {
          //     console.log('no data');
          //   } else {
          //     console.log('do something');
          //     console.log(doc);
          //   }
          // });
          // const index = 0;
          // const array = applicationList[index].availableSubjects.map((e) => [e, 0]);
          // const tttt = Object.fromEntries(array);
          // const doc = await setDocument(
          //   FirestorePath.application(applicationList[index].aid),
          //   { ...applicationList[index], matchingStatus: 0 },
          //   true
          // );
          // if (doc === undefined) {
          //   console.log('no data');
          // } else {
          //   console.log('do something');
          //   console.log(doc);
          // }
          // 앞서 사용한 addDocument 대체 가능!!
        }}
      >
        [UPDATE] - 튜티 doc 정보 업데이트하기
      </TestButton>
      <br />
      <TestButton
        onClick={async () => {
          const tid = 'fnSQj22dYL33wTrXm0cl';

          const isCompleted = await deleteDocument(FirestorePath.tutee(tid));
          if (isCompleted) {
            console.log('delete complete');
          } else {
            console.log('delete fail');
          }
        }}
      >
        [DELETE] - 튜티 doc 삭제하기
      </TestButton>
      <br />
      <TestButton
        onClick={async () => {
          // unsubscripbe는 함수
          // 페이지를 벗어나는 순간에 실행해줘야 함
          const unsubscribe = await getCollectionStream(
            FirestorePath.applications(),
            (querySnapshot) => {
              const docs = [];
              querySnapshot.forEach((doc) => {
                docs.push(applicationConverter.fromFirestore(doc));
              });

              querySnapshot.docChanges().forEach((change) => {
                if (change.type === 'added') {
                  console.log('New city: ', change.doc.data());
                }
                if (change.type === 'modified') {
                  console.log('Modified city: ', change.doc.data());
                }
                if (change.type === 'removed') {
                  console.log('Removed city: ', change.doc.data());
                }
              });
              setApplicationList(docs);
            },
            [where('uid', '==', 'md0tTkvpTegvmQwPPNuzfaTQUZb2'), orderBy('createdAt', 'desc')]
          );
        }}
      >
        [UPDATE-stream] - doc 정보 실시간 업데이트하기
      </TestButton>
      <br />
    </>
  );
};

const TestButton = styled.button`
  display: flex;
  justify-content: center;
  width: 240px;
  background-color: lightblue;
  padding: 4px;
`;

export default ApplicationTutee;
