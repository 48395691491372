import { Link } from 'react-router-dom';
import styled from 'styled-components';

const size = {
  mobile: 540,
  tablet: 1049,
  desktop: 1050,
};

const device = {
  mobile: `@media screen and (max-width: ${size.mobile}px)`,
  tablet: `@media screen and (max-width: ${size.tablet}px)`,
  desktop: `@media screen and (min-width: ${size.desktop}px)`,
};

export const Background = styled.footer`
  background-color: black;
  font-size: 1rem;
  color: #cecece;
  width: 100%;
  padding: 1rem 2.5rem;
  & .info {
    * {
      font-size: 0.8rem;
    }
    a {
      padding-right: 1rem;
    }
  }
  @media sreen and (max-width: 599px) {
    padding: 1.6rem;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: ${(props) => props.align};
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  flex-direction: ${(props) => props.direction};
  ${device.tablet} {
    flex-direction: column;
    align-items: center;
  }
  ${device.mobile} {
    max-width: 430px;
  }
`;

export const InfoPart = styled.div`
  display: flex;
  flex-direction: column;
  ${device.tablet} {
    width: 510px;
  }
  ${device.mobile} {
    width: 100%;
    max-width: 350px;
  }
`;

export const InfoList = styled.div`
  padding: 3px 0px;
`;

export const CompanyName = styled.span`
  color: #eeeeee;
  font-weight: 500;
  font-size: 20px;
  padding-top: 5px;
  padding-bottom: 62px;
`;

export const InfoTitle = styled.span`
  color: white;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  padding-bottom: 3px;
  padding-right: 10px;
`;

export const InfoValue = styled(InfoTitle)`
  font-weight: bold;
`;

export const Links = styled.div`
  width: 500px;
  display: flex;
  justify-content: space-between;
  a {
    color: white;
    font-weight: 700;
    font-size: 14px;
  }
  ${device.tablet} {
    width: 510px;
    padding-bottom: 130px;
  }
  ${device.mobile} {
    width: 100%;
    max-width: 350px;
    padding-bottom: 120px;
  }
`;

export const Menu = styled(Link)`
  color: white;
  font-weight: 700;
  font-size: 18px;
`;

export const Copyright = styled.span`
  color: white;
  font-weight: 300;
  font-size: 16px;
  text-align: end;
  ${device.tablet} {
    width: 510px;
    text-align: start;
    padding-top: 3px;
  }
  ${device.mobile} {
    width: 100%;
    max-width: 350px;
    text-align: start;
    padding-top: 3px;
  }
`;
