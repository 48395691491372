import Root from 'components/Root';
import { Box, Typography } from '@mui/material';
import styled from 'styled-components';
import AboutHeader from './components/AboutHeader';
import AboutBnp from './components/AboutBnp';
import AboutVisionMission from './components/AboutVisionMission';
import AboutKeyValues from './components/AboutKeyValues';

const About = () => {
  return (
    <Root activeMenu="소개합니다">
      <AboutHeader />
      <AboutBnp />
      <AboutVisionMission />
      <AboutKeyValues />
    </Root>
  );
};

export default About;
