import { Box, Divider, Typography } from '@mui/material';
import React from 'react';

const InfoBox = ({ title, children }) => {
  return (
    <Box className="flex flex-col justify-start px-10 py-10 md:px-10 md:py-10 my-4 md:my-2 w-full max-w-5xl box-border bg-white rounded-2xl">
      <Typography className="pb-2" variant="h2">
        {title}
      </Typography>
      <Divider />
      <Box className="flex flex-col px-4 pt-8 gap-4">{children}</Box>
    </Box>
  );
};

export default InfoBox;
