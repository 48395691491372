import { db } from 'firebase_config';

import {
  setDoc,
  getDoc,
  deleteDoc,
  // deleteField,
  // updateDoc,
  getDocs,
  doc,
  collection,
  query,
  onSnapshot,
  getCountFromServer,
  // where,
} from 'firebase/firestore';

/**
 * Document 를 (한 번 호출, 하나만 반환) 가져오는 함수.
 * `Document ID` 를 아는 경우 사용
 *
 * @param path - Collection/Document 경로. `ex) FirestorePath.xxx(docId)`
 * @param builder - model class 로 변환하는 함수 `ex) (data, docId)=>UserModel.fromFirestore(data)
 * @returns 가져온 Document 를 반환. 데이터가 없으면 undefined 반환.
 */
export async function getDocumentFuture(path, builder) {
  const ref = doc(db, path);
  const documentSnapshot = await getDoc(ref).then((s) => {
    return s.data() == null ? null : builder(s, s.id);
  });
  return documentSnapshot;
}

/**
 * Document 를 (한 번 호출, 여러개 반환, 쿼리가능) 가져오는 함수.
 *
 * @param path - Collection 경로. `ex) FirestorePath.xxxs()`
 * @param builder - model class 로 변환하는 함수 `ex) (data, docId)=>UserModel.fromFirestore(data)
 * @param queries - query 문. `where`, `orderBy`, `limit` 사용 가능. `ex) [where(), orderBy(), limit()]`
 * @returns model로 변환된 Documents 를 반환. 데이터가 없으면 빈 array 반환.
 */
export async function getCollectionFuture(path, builder, queries) {
  try {
    const ref = collection(db, path);
    const q = query(ref, ...queries);
    const querySnapshot = await getDocs(q);
    const docs = [];
    querySnapshot.forEach((d) => {
      docs.push(builder(d, d.id));
    });
    return docs;
  } catch (e) {
    console.log(e);
    return undefined;
  }
}

/**
 * Document 를 개수를 반환하는 함수.
 *
 * @param path - Collection 경로. `ex) FirestorePath.xxxs()`
 * @param queries - query 문. `where`, `orderBy`, `limit` 사용 가능. `ex) [where(), orderBy(), limit()]`
 * @returns Documents length 를 정수로 반환. 데이터가 없으면 0 반환.
 */
export async function getCollectionFutureCount(path, queries) {
  try {
    const ref = collection(db, path);
    const q = query(ref, ...queries);
    const querySnapshot = await getCountFromServer(q);
    const docs = querySnapshot.data().count;
    return docs;
  } catch (e) {
    console.log(e);
    return undefined;
  }
}

/**
 * Document 를 (변경될때마다 수신, 여러개 반환, 쿼리가능) 가져오는 함수.
 *
 * @param path - Collection 경로. `ex) FirestorePath.xxxs()`
 * @param builder - model class 로 변환하는 함수 `ex) (data, docId)=>UserModel.fromFirestore(data)
 * @param queries - query 문. `where`, `orderBy`, `limit` 사용 가능. `ex) [where(), orderBy(), limit()]`
 * @returns model로 변환된 Documents 를 반환. 데이터가 없으면 빈 array 반환.
 */
export async function getCollectionStream(path, snapshot, queries) {
  const ref = collection(db, path);
  const q = query(ref, ...queries);
  const unsubscribe = onSnapshot(q, snapshot, (error) => {
    console.log(error);
  });

  return unsubscribe;

  // const querySnapshot = await getDocs(q);
  // const docs = [];
  // querySnapshot.forEach((d) => {
  //   docs.push(builder(d, d.id));
  // });
  // return docs;
}

/**
 * 새로운 Document 를 생성하는 함수. 지정한 Collection에 doc을 생성하고
 * `idKey`를 key로 하는 필드에 doc id 를 추가한다.
 *
 * @param path - Collection 경로. `ex) FirestorePath.xxx()`
 * @param data - `Object` 타입의 doc data. `ex) {xxx: 'xxx'}`
 * @param idKey - document id를 저장하는 필드의 key 값. `ex) 'uid'`
 * @param merge - document를 병합할지 여부. default 값은 false.
 * @returns 생성된 Document 의 ID를 반환한다.
 */
export async function addDocument(path, data, idKey, merge = false) {
  try {
    const ref = doc(collection(db, path));
    data[idKey] = ref.id;
    await setDoc(ref, data, { merge });

    return ref.id;
  } catch (e) {
    console.log(e);
    return undefined;
  }
}

/**
 * Document 를 새롭게 생성하거나 업데이트하는 함수.
 * docId 를 입력하는 경우 doc 이 존재하면 수정/덮어쓰기 하고
 * 존재하지 않으면 새롭게 생성.
 *
 * @param path - Collection/Document 경로. `ex) FirestorePath.xxx(docId)`
 * @param data - `Object` 타입의 doc data. `ex) {xxx: 'xxx'}`
 * @param merge - document를 병합할지 여부. default 값은 false.
 * @returns 생성 성공 여부를 bool 로 반환함.
 */
export async function setDocument(path, data, merge = false) {
  try {
    const ref = doc(db, path);
    await setDoc(ref, data, { merge });
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

// /// 기존 여러개의 데이터 수정
// export async function bulkSet(path, datas, merge = false) {
//   try {
//     datas.forEach(async (data) => {
//       const ref = doc(db, path);
//       await setDoc(ref, data, { merge });
//     });

//     return true;
//   } catch (e) {
//     console.log(e);
//     return false;
//   }
// }

// /// 하나의 Field Value 삭제
// export async function deleteDocumentFieldValue(path, fields) {
//   try {
//     const ref = doc(db, path);
//     await updateDoc(
//       ref,
//       fields.reduce((accumulator, value) => {
//         return { ...accumulator, [value]: deleteField() };
//       }, {})
//     );
//     return true;
//   } catch (e) {
//     console.log(e);
//     return false;
//   }
// }

/**
 * docId 를 아는 경우 해당 Document 하나 삭제.
 *
 * @param path - Collection/Document 경로. `ex) FirestorePath.xxx(docId)`
 * @returns 삭제 성공 여부를 bool 로 반환함.
 */
export async function deleteDocument(path) {
  try {
    const ref = doc(db, path);
    await deleteDoc(ref);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}
