import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import {
  collection,
  doc,
  getDocs,
  serverTimestamp,
  orderBy,
  query,
  limit,
  startAfter,
  getDoc,
  updateDoc,
  deleteDoc,
  addDoc,
  where,
} from 'firebase/firestore';
import { db } from 'firebase_config';

const initialState = {
  data: [],
  question: [],
  totalCount: 0,
  totalPage: 0,
  hasNext: false,
  currentId: 0,
  totalReplyCount: 0,
};

export const fetchQnas = createAsyncThunk('qna/fetchQnas', async (page, { getState }) => {
  const fetchRef = collection(db, 'Qnas');
  const PAGE_SIZE = 10;
  const qnas = [];
  let queryRef;

  if (page > 1) {
    const { lastVisible } = getState().qna;
    queryRef = query(
      fetchRef,
      orderBy('createdAt', 'desc'),
      startAfter(lastVisible),
      limit(PAGE_SIZE)
    );
  } else {
    queryRef = query(fetchRef, orderBy('createdAt', 'desc'), limit(PAGE_SIZE));
  }

  const querySnapshot = await getDocs(queryRef);

  querySnapshot.forEach((d) => {
    const newData = {
      ...d.data(),
      docId: d.id,
      createdAt: dayjs.unix(d.data().createdAt.seconds).format('YYYY-MM-DD HH:mm:ss'),
    };
    qnas.push(newData);
  });

  const lastVisibleDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

  const totalDocsQuerySnapshot = await getDocs(query(fetchRef));
  const totalDocs = totalDocsQuerySnapshot.size;
  const totalPage = Math.ceil(totalDocs / PAGE_SIZE);
  const lastDocQuerySnapshot = await getDocs(
    query(fetchRef, orderBy('createdAt', 'desc'), limit(1))
  );
  let lastId;
  lastDocQuerySnapshot.forEach((d) => {
    lastId = d.data().id;
  });

  const nextQuerySnapshot = await getDocs(
    query(
      fetchRef,
      orderBy('createdAt', 'asc'),
      startAfter(querySnapshot.docs[querySnapshot.docs.length - 1]),
      limit(1)
    )
  );
  const hasNext = nextQuerySnapshot.size > 0;

  return { qnas, hasNext, totalPage, totalDocs, lastId, lastVisible: lastVisibleDoc };
});

export const fetchQna = createAsyncThunk('qna/fetchQna', async (id, { dispatch }) => {
  const q = query(collection(db, 'Qnas'), where('id', '==', id));
  const qSnapshot = await getDocs(q);
  let docId;
  let qData;
  qSnapshot.forEach((d) => {
    docId = d.id;
    qData = {
      ...d.data(),
      docId,
      createdAt: dayjs.unix(d.data().createdAt.seconds).format('YYYY-MM-DD HH:mm:ss'),
    };
  });

  const querySnapshot = await getDocs(collection(db, `Qnas/${docId}/Replies`));
  const replies = [];
  const totalReplies = querySnapshot.size;

  querySnapshot.forEach((r) => {
    const newData = {
      ...r.data(),
      rId: r.id,
      createdAt: dayjs.unix(r.data().createdAt.seconds).format('YYYY-MM-DD HH:mm:ss'),
    };
    replies.push(newData);
  });

  if (qData) {
    const data = {
      ...qData,
      replies,
    };
    return { data, totalReplies };
  }

  return { data: null, totalReplies: 0 };
});

export const fetchReplies = createAsyncThunk('qna/fetchReplies', async (id) => {
  const fetchRef = collection(db, `Qnas/${id}/Replies`);
  const queryRef = query(fetchRef, orderBy('createdAt', 'desc'));

  const querySnapshot = await getDocs(queryRef);
  const replies = [];
  const totalReplies = querySnapshot.size;

  querySnapshot.forEach((r) => {
    const newData = {
      ...r.data(),
      rId: r.id,
      createdAt: dayjs.unix(r.data().createdAt.seconds).format('YYYY-MM-DD HH:mm:ss'),
    };
    replies.push(newData);
  });

  return { replies, totalReplies };
});

export const addQna = createAsyncThunk('qna/addQna', async (param) => {
  const data = { ...param, createdAt: serverTimestamp() };
  const res = await addDoc(collection(db, 'Qnas'), data);
  return res;
});

export const deleteQna = createAsyncThunk('qna/deleteQna', async (id) => {
  await deleteDoc(doc(db, 'Qnas', id));
  return id;
});

export const updateQna = createAsyncThunk('qna/updateQna', async (param) => {
  const docId = param.docId;
  delete param.docId;
  const data = { ...param, updatedAt: serverTimestamp() };
  const res = await updateDoc(doc(db, 'Qnas', docId), data);
  return res;
});

export const addReply = createAsyncThunk('qna/addReply', async ({ param, qnaId, userType }) => {
  const data = { ...param, createdAt: serverTimestamp() };
  const res = await addDoc(collection(db, `Qnas/${qnaId}/Replies`), data);
  const qnaRef = doc(db, 'Qnas', qnaId);
  const qnaSnap = await getDoc(qnaRef);
  if (qnaSnap.exists()) {
    if (userType === 'admin') {
      await updateDoc(qnaRef, {
        hasAnswer: true,
      });
    }
  }
  return res;
});

export const deleteReply = createAsyncThunk('qna/deleteReply', async ({ qnaId, replyId }) => {
  await deleteDoc(doc(db, `Qnas/${qnaId}/Replies`, replyId));
  const querySnapshot = await getDocs(collection(db, `Qnas/${qnaId}/Replies`));
  const replies = [];
  const totalReplies = querySnapshot.size;

  querySnapshot.forEach((r) => {
    const newData = {
      ...r.data(),
      rId: r.id,
      createdAt: dayjs.unix(r.data().createdAt.seconds).format('YYYY-MM-DD HH:mm:ss'),
    };
    replies.push(newData);
  });
  return { replies, totalReplies };
});

export const updateReply = createAsyncThunk('qna/updateReply', async ({ param, qnaId }) => {
  const replyId = param.rId;
  const data = { ...param, updatedAt: serverTimestamp() };
  await updateDoc(doc(db, `Qnas/${qnaId}/Replies`, replyId), data);
  const querySnapshot = await getDocs(collection(db, `Qnas/${qnaId}/Replies`));
  const replies = [];
  const totalReplies = querySnapshot.size;

  querySnapshot.forEach((r) => {
    const newData = {
      ...r.data(),
      rId: r.id,
      createdAt: dayjs.unix(r.data().createdAt.seconds).format('YYYY-MM-DD HH:mm:ss'),
    };
    replies.push(newData);
  });
  return { replies, totalReplies };
});

export const qnaSlice = createSlice({
  name: 'qna',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchQnas.fulfilled, (state, action) => {
        const { qnas, totalDocs, lastId, hasNext, totalPage, lastVisible } = action.payload;
        state.data = qnas;
        state.totalCount = totalDocs;
        state.currentId = Number(lastId);
        state.hasNext = hasNext;
        state.totalPage = totalPage;
        state.lastVisible = lastVisible;
      })
      .addCase(fetchQna.fulfilled, (state, action) => {
        const { data, totalReplies } = action.payload;
        state.question = data;
        state.totalReplyCount = totalReplies;
      })
      .addCase(fetchReplies.fulfilled, (state, action) => {
        const { replies, totalReplies } = action.payload;
        state.question.replies = replies;
        state.totalReplyCount = totalReplies;
      })
      .addCase(deleteReply.fulfilled, (state, action) => {
        const { replies, totalReplies } = action.payload;
        state.question.replies = replies;
        state.totalReplyCount = totalReplies;
      })
      .addCase(updateReply.fulfilled, (state, action) => {
        const { replies, totalReplies } = action.payload;
        state.question.replies = replies;
        state.totalReplyCount = totalReplies;
      })
      .addCase(deleteQna.fulfilled, (state, action) => {
        const id = action.payload;
        state.data = state.data.filter((d) => d.docId !== id);
      });
  },
});

export default qnaSlice.reducer;
