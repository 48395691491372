import Root from 'components/Root';
import AboutKeyValues from 'pages/about/components/AboutKeyValues';
import AboutBnp from 'pages/about/components/AboutBnp';
import ApplyProcess from 'pages/process/components/ApplyProcess';
import Subjects from 'pages/process/components/Subjects';
import DetailSchedule from 'pages/process/components/DetailSchedule';
import { Box } from '@mui/material';
import HomeLanding from './components/HomeLanding';

const Home = (props) => {
  return (
    <Root bgTransparent>
      <HomeLanding />
      <AboutBnp />
      <ApplyProcess />
      <AboutKeyValues />
      <Subjects />
      <Box className="flex justify-center">
        <DetailSchedule />
      </Box>
    </Root>
  );
};

export default Home;
