import { Box } from '@mui/material';
import Waiting from '../components/Waiting';
import { getValue } from 'firebase/remote-config';
import { remoteConfig } from 'firebase_config';
import MyTitle from '../components/MyTitle';
import MentorPlanning from '../mentorPlanning';

const Syllabus = () => {
  const remoteDate = getValue(remoteConfig, 'open_datetime_syllabus')._value;
  const today = new Date();
  // TODO: 런칭 시점 지나면 아래 분기 지우기
  const openDay = new Date(remoteDate);
  const timeBlockList = Array.from({
    length: 24,
    3: 0,
    5: 1,
    6: 1,
    7: 2,
    8: 2,
    9: 2,
  });
  const timeList = ['00', '03', '06', '09', '12', '15', '18', '21', '24'];
  const legends = {
    멘티: (theme) => theme.palette.figma.yellow03,
    멘토: (theme) => theme.palette.figma.orange03,
    '멘티+멘토': (theme) => theme.palette.figma.orange01,
  };

  return true ? (
    <Box className="flex flex-col justify-start items-center w-full px-4 py-10">
      <MyTitle title="수업계획서" />
      <MentorPlanning />
    </Box>
  ) : (
    <Waiting />
  );
};

export default Syllabus;
