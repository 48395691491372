// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import {
  getAuth,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  updateProfile,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import { getFirestore, serverTimestamp } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getRemoteConfig, getValue, fetchAndActivate } from 'firebase/remote-config';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FB_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FB_API_ID,
  measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firebaseAuth = getAuth(app);
const analytics = getAnalytics(app);
const remoteConfig = getRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = 3600000 * 3;
remoteConfig.defaultConfig = {
  open_datetime_matching: { _value: '2024-02-24 00:00:00' },
  open_datetime_syllabus: { _value: '2024-06-24 00:00:00' },
};

export const googleProvider = new GoogleAuthProvider();
export {
  firebaseAuth,
  createUserWithEmailAndPassword,
  updateProfile,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  analytics,
  signOut,
  ref,
  uploadBytes,
  getDownloadURL,
  remoteConfig,
  fetchAndActivate,
  getValue,
  serverTimestamp,
};

export const db = getFirestore(app);
export const bnp5 = getFirestore(app, 'bnp5');
export const firebaseStorage = getStorage(app);
