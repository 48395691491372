import { Box, Button, Typography } from '@mui/material';
import { COMMON } from 'constants';
import { Link } from 'react-router-dom';

// import { collection, deleteDoc, getDocs, query, where, doc } from 'firebase/firestore';
// import { db } from 'firebase_config';
// import { useEffect } from 'react';
const HomeLanding = () => {
  // const q = query(collection(db, 'Applications'), where('createdAt', '<', new Date('2024-01-01')));

  // useEffect(() => {
  //   async function getApplications() {
  //     const querySnapshot = await getDocs(q);
  //     console.log(querySnapshot);
  //     querySnapshot.forEach(async (_doc) => {
  //       console.log('delete');
  //       await deleteDoc(doc(db, 'Applications', _doc.id));
  //     });
  //   }

  //   getApplications();
  // }, []);
  return (
    <Box className="flex flex-col justify-center items-center bg-no-repeat bg-cover bg-[url(assets/home/home.svg)] min-h-[70vh]">
      <Box className="flex flex-col justify-center items-start md:min-w-[70vw]">
        <Box className="flex md:flex-row flex-row md:items-end items-start md:mb-2 max-w-[32rem]">
          <Typography
            color="primary.lightBlue"
            fontSize="2.5rem"
            fontWeight="bold"
            lineHeight="120%"
          >
            배워서 남주자 프로젝트 {COMMON.ORDINAL}기<span style={{ color: 'white' }}>가 </span>
            진행
            <span style={{ color: 'white' }}>됩니다</span>
          </Typography>
        </Box>
        <Typography color="text.white" fontSize="1.25rem" lineHeight="140%" fontWeight="regular">
          내가 배운 것들을 나누며 변화를 만들어 냅니다.
          <br />
          배워서 남주자 프로젝트와 함께 귀한 한걸음을 내디뎌 보세요.
        </Typography>
        <Box className="flex flex-row items-center gap-3 w-full pt-8">
          {/* <Link to="/process">
            <Button variant="contained" color="primary">
              <Typography className="p-2 break-keep">신청하기</Typography>
            </Button>
          </Link> */}
          <Button className="!hover:bg-transparent" variant="text" color="error" href="/about">
            <Typography className="hover:underline underline-offset-8 p-2">
              배남프 더 알아보기
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default HomeLanding;
