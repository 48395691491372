import { Box, Grid, Typography } from '@mui/material';
import vision from 'assets/about/vision.svg';
import mission from 'assets/about/mission.svg';

const AboutVisionMission = () => {
  return (
    <Box className="flex justify-center md:py-20 p-20">
      <Box className="flex flex-col justify-center items-start max-w-5xl" sx={{ width: '1024px' }}>
        <Typography variant="h1" color="primary" className="!pb-4">
          비전과 미션
        </Typography>
        {/* 비전 파트 */}
        <Box className="flex sm:flex-row flex-col w-full">
          <Grid container>
            <img src={vision} alt="vision" />
          </Grid>
          <Grid container className="sm:py-0 py-16">
            <Grid className="flex justify-center items-center" item xs={12}>
              <Box className="flex flex-col justify-center sm:items-start items-center">
                <Typography
                  color="text.black"
                  fontSize="3rem"
                  fontFamily="NanumMyeongjo"
                  fontWeight="regular"
                  lineHeight="120%"
                >
                  Our vision
                </Typography>
                <Typography
                  color="text.black"
                  fontSize="1.25rem"
                  fontWeight="regular"
                  lineHeight="130%"
                >
                  한 손에는 지혜를 한 손에는 사랑을
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box className="flex sm:flex-row flex-col-reverse w-full">
          <Grid container className="sm:py-0 py-16">
            <Grid className="flex justify-center items-center" item xs={12}>
              <Box className="flex flex-col justify-center sm:items-start items-center sm:text-left text-center">
                <Typography
                  color="text.black"
                  fontSize="3rem"
                  fontFamily="NanumMyeongjo"
                  fontWeight="regular"
                  lineHeight="120%"
                >
                  Our mission
                </Typography>
                <Typography
                  color="text.black"
                  fontSize="1.25rem"
                  fontWeight="regular"
                  lineHeight="130%"
                >
                  공간의 한계를 넘어
                  <br />
                  하나님의 자녀들에게 전해지는, 꿈과 사랑
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <img src={mission} alt="mission" />
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default AboutVisionMission;
