import { Box, Typography } from '@mui/material';
import logosvg from 'assets/logosvg.svg';
import { COMMON } from 'constants';

const AboutBnp = () => {
  return (
    <Box className="flex justify-center md:py-20 p-20">
      <Box className="flex flex-col justify-center items-start max-w-5xl">
        <Box className="flex flex-row items-end">
          <Box className="md:w-auto w-30 pr-4">
            <img className="md:w-40 w-30" src={logosvg} alt="logosvg" />
          </Box>
          <Typography variant="h1" color="primary" className="!mb-4">
            배남프란?
          </Typography>
        </Box>
        <Typography
          className="!mb-4 max-w-[45rem]"
          color="neutral.500"
          fontSize="1.65rem"
          fontFamily="NanumMyeongjo"
          fontWeight="regular"
          lineHeight="120%"
        >
          배워서 남주자 프로젝트(배남프)는 다음 세대를 영성과 실력으로 세상을 변화시키는 주역으로
          세워나가고자 합니다.
        </Typography>
        <Typography
          color="text.secondary"
          fontSize="1.25rem"
          fontWeight="regular"
          lineHeight="130%"
          className="!mb-8"
        >
          온누리교회 대학청년부가 주관하는 배남프는 '배워서 남주자 프로젝트'의 줄임말로, 'Hope,
          Wisdom, Love' 비전에 따라 일대일 온라인 튜터링을 제공하는 교육 선교 프로젝트입니다.
          <br />
          <br />
          배남프는 코로나로 인해 직접 선교지를 방문할 수 없는 상황에서 선교사님의 사역을 섬기기 위해
          시작되었습니다. 2020년부터 <b>{COMMON.TOTAL_MENTOR}</b>명의 대학청년 멘토들이 유치부부터
          고등부까지 <b>{COMMON.TOTAL_MENTEE}</b>명의 멘티들과 함께 했습니다. 전 세계의 멘티들에게 필요한
          교과목 학습을 지원하며, 하나님의 비전과 꿈을 심어주었습니다.
          <br />
          <br />
          앞으로 배남프를 통해 선교사님의 자녀뿐만 아니라 국내 다문화 가정 자녀, 더 나아가 교육
          사각지대에 있는 아이들을 돕고자 합니다. 예수님의 사랑을 경험한 크리스천 청년들의 자발적인
          아름다운 섬김을 통해 다음 세대가 전 세계를 품는 하나님 나라의 리더로 세워지길 기대하고
          소망합니다.
        </Typography>
      </Box>
    </Box>
  );
};

export default AboutBnp;
