import { Box, Typography } from '@mui/material';
import subjects from 'assets/process/subjects.svg';
import React from 'react';

const Subjects = () => {
  return (
    <Box className="w-full flex justify-center h-52" sx={{ background: '#E1F5F8' }}>
      <img className="object-cover" src={subjects} alt="subjects" />
    </Box>
  );
};

export default Subjects;
