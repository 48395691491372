import Root from 'components/Root';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, Grid, Pagination, Typography } from '@mui/material';
import withRouter from 'utils/withRouter';
import { fetchQnas } from 'store/qnaSlice';
import { useDispatch, useSelector } from 'react-redux';
import LockIcon from '@mui/icons-material/Lock';
import CircularProgress from '@mui/material/CircularProgress';
import Swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
import { selectUser } from 'store/userSlice';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    borderStyle: 'none',
    borderTop: `2px solid ${theme.palette.primary.main}`,
    borderBottom: '1px solid #169DC580',
    color: theme.palette.primary.main,
    fontWeight: 700,
    padding: '0.75rem',
  },
  [`&.${tableCellClasses.body}`]: {
    padding: '0.75rem',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#FAFAFA',
  },
}));

const Main = (props) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const qnaData = useSelector((state) => state.qna.data);
  const totalPage = useSelector((state) => state.qna.totalPage);
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleToDetail = async (data) => {
    if (data.isPrivate && user.userType != 'admin') {
      await Swal.fire({
        width: '28rem',
        padding: '1rem',
        title: '비밀번호를 입력해 주세요.',
        input: 'password',
        inputAttributes: {
          autocapitalize: 'off',
          autocorrect: 'off',
          maxlength: 12,
          pattern: '[a-zA-Z0-9]{4,12}',
        },
        allowEnterKey: false,
        showCancelButton: true,
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        inputValidator: (val) => {
          const decryptPassword = CryptoJS.AES.decrypt(
            data.password,
            process.env.REACT_APP_SALT
          ).toString(CryptoJS.enc.Utf8);
          if (!val) {
            return '비밀번호를 입력해주세요.';
          }
          if (val !== decryptPassword) {
            return '비밀번호가 일치하지 않습니다.';
          }
          return null;
        },
      }).then((result) => {
        if (result.isConfirmed) {
          props.navigate(`/QnA/${data.id}`);
        }
      });
    } else {
      props.navigate(`/QnA/${data.id}`);
    }
  };

  React.useEffect(() => {
    // 페이지 이동시 데이터를 가져옴
    setLoading(true);
    dispatch(fetchQnas(page)).then(() => setTimeout(() => setLoading(false)), 2000);
  }, [dispatch, page]);

  React.useEffect(() => {
    if (qnaData) {
      setRows(qnaData);
    }
  }, [qnaData]);

  let renderContent;
  if (loading) {
    renderContent = (
      <TableRow>
        <StyledTableCell sx={{ border: 0 }} align="center" colSpan={5}>
          <Box className="py-44">
            <CircularProgress />
            <Typography variant="body1" sx={{ fontSize: '1rem' }}>
              잠시만 기다려주세요.
            </Typography>
          </Box>
        </StyledTableCell>
      </TableRow>
    );
  } else if (rows.length === 0) {
    renderContent = (
      <TableRow>
        <StyledTableCell sx={{ border: 0 }} align="center" colSpan={5}>
          <Box className="py-44">
            <Typography
              variant="body1"
              color="text.secondary"
              component="span"
              className="!font-bold"
            >
              질문이 없어요.
              <br />첫 질문을 남겨보세요!
            </Typography>
          </Box>
        </StyledTableCell>
      </TableRow>
    );
  } else {
    renderContent = rows.map((row) => {
      const emailSplit = row.email.split('@');
      const hashEmail = emailSplit[0].slice(0, 3) + '*'.repeat(emailSplit[0].length - 3);
      const userEmail = `${hashEmail}@${emailSplit[1]}`;
      return (
        <StyledTableRow key={row.id} onClick={() => handleToDetail(row)}>
          <StyledTableCell align="center">
            <Typography variant="body3">{row.id}</Typography>
          </StyledTableCell>
          <StyledTableCell align="center">
            <Typography variant="body3">{userEmail}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body3" className="flex items-center">
              {row.title}
              {row.isPrivate ? (
                <LockIcon color="primary" sx={{ ml: 2, fontSize: '0.85rem' }} />
              ) : (
                ''
              )}
            </Typography>
          </StyledTableCell>
          <StyledTableCell align="center" sx={{ fontWeight: 300 }}>
            <Typography variant="body3">{row.createdAt}</Typography>
          </StyledTableCell>
          <StyledTableCell align="center">
            {row.hasAnswer ? (
              <Typography variant="body3" color="primary.dark">
                답변완료
              </Typography>
            ) : (
              <Typography variant="body3" color="text.secondary">
                답변 진행중
              </Typography>
            )}
          </StyledTableCell>
        </StyledTableRow>
      );
    });
  }

  return (
    <Root activeMenu="질문 게시판">
      <Box className="py-20 px-20 lg:px-40">
        <Box className="pb-10">
          <Typography variant="h1" sx={{ fontSize: '1.5rem', fontWeight: 'bold', pb: 2 }}>
            질문 게시판
          </Typography>
          <Typography variant="body1" sx={{ fontSize: '1rem', fontWeight: 500 }}>
            배남프 관련 질문을 남겨주시면 빠른 시일 내에 답변드리겠습니다.
            <br />
            보다 더 정확한 답변을 위해 질문 작성 시 실명을 사용해주시면 감사하겠습니다 :)
          </Typography>
        </Box>
        <TableContainer
          component={Paper}
          square
          sx={{ minHeight: 500, boxShadow: 'none', borderBottom: '2px solid #169DC5' }}
        >
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center" width={80}>
                  번호
                </StyledTableCell>
                <StyledTableCell align="center" width={120}>
                  작성자
                </StyledTableCell>
                <StyledTableCell align="center" width={350}>
                  제목
                </StyledTableCell>
                <StyledTableCell align="center" width={180}>
                  작성날짜
                </StyledTableCell>
                <StyledTableCell align="center" width={120}>
                  답변
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderContent}</TableBody>
          </Table>
        </TableContainer>
        <Grid container className="flex justify-between mt-10">
          <Grid item xs={4} className="flex items-center" />
          <Grid item xs={4} className="flex items-center justify-center">
            <Pagination
              color="primary"
              showFirstButton
              showLastButton
              count={totalPage || 1}
              page={page}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={4} className="flex items-center justify-end">
            <Button
              variant="contained"
              color="primary"
              size="small"
              sx={{ display: 'block' }}
              onClick={() => props.navigate('/QnA/new', { state: user })}
            >
              질문하기
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Root>
  );
};

export default withRouter(Main);
