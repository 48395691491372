export class MatchingModel {
  constructor(
    mid, // 매칭 doc id. firestore 자동 생성
    subject, // 과목 이름
    state, // 매칭 승인 상태- 0:매칭 승인 대기중- 1:매칭 승인- 2:매칭 보류
    appliedAt, // 매칭신청 시점. - firestore 함수 serverTimestamp 사용(서버시간으로 저장됨)
    approvedAt, // 매칭승인 시점. - firestore 함수 serverTimestamp 사용(서버시간으로 저장됨)
    approvedMentee, // 승인된 멘티 application id
    mentorUid, // 멘토 user id
    mentorAid, // 멘토 application id
    mentorEmail, // 멘토 이메일
    mentorContact, // 멘토 연락처(전화번호)
    mentorName, // 멘토 이름
    mentorGender, // 멘토 성별
    mentorBirth, // 멘토 생일(나이)
    mentorCourses, // 멘토 수업 과정ex) [‘유초등저학년(1~2학년)’, ‘중등', ‘고등']
    mentorSubjects, // 멘토 수업 가능 과목
    mentorExtraSubjects, // 멘토 기타 과목
    mentorDateTime, // 멘토 수업 가능 시간대
    mentorPersonality, // 멘토 성향
    menteeUidList, // 멘티들 user id 리스트(쿼리를 위한)
    menteeAidList, // 멘티들 application id 리스트(쿼리를 위한)
    menteeList, // 멘티 Application Object 리스트
    userType // 구분을 위한 유저타입. 항상 'mentor'
  ) {
    this.mid = mid;
    this.subject = subject;
    this.state = state;
    this.appliedAt = appliedAt;
    this.approvedAt = approvedAt;
    this.approvedMentee = approvedMentee;
    this.mentorUid = mentorUid;
    this.mentorAid = mentorAid;
    this.mentorEmail = mentorEmail;
    this.mentorContact = mentorContact;
    this.mentorName = mentorName;
    this.mentorGender = mentorGender;
    this.mentorBirth = mentorBirth;
    this.mentorCourses = mentorCourses;
    this.mentorSubjects = mentorSubjects;
    this.mentorExtraSubjects = mentorExtraSubjects;
    this.mentorDateTime = mentorDateTime;
    this.mentorPersonality = mentorPersonality;
    this.menteeUidList = menteeUidList;
    this.menteeAidList = menteeAidList;
    this.menteeList = menteeList;
    this.userType = userType;
  }

  toString() {
    return `
      ${this.mid}, 
      ${this.subject}, 
      ${this.state}, 
      ${this.appliedAt}, 
      ${this.approvedAt}, 
      ${this.approvedMentee},
      ${this.mentorUid}, 
      ${this.mentorAid}, 
      ${this.mentorEmail}, 
      ${this.mentorContact}, 
      ${this.mentorName}, 
      ${this.mentorGender}, 
      ${this.mentorBirth}, 
      ${this.mentorCourses}, 
      ${this.mentorSubjects}, 
      ${this.mentorExtraSubjects}, 
      ${this.mentorDateTime}, 
      ${this.mentorPersonality}, 
      ${this.menteeUidList}, 
      ${this.menteeAidList}, 
      ${this.menteeList}, 
      ${this.userType}, 
      `;
  }
}

// Firestore data converter
const matchingConverter = {
  toFirestore: (matching) => {
    return {
      mid: matching.mid,
      subject: matching.subject,
      state: matching.state,
      appliedAt: matching.appliedAt,
      approvedAt: matching.approvedAt,
      approvedMentee: matching.approvedMentee,
      mentorUid: matching.mentorUid,
      mentorAid: matching.mentorAid,
      mentorEmail: matching.mentorEmail,
      mentorContact: matching.mentorContact,
      mentorName: matching.mentorName,
      mentorGender: matching.mentorGender,
      mentorBirth: matching.mentorBirth,
      mentorCourses: matching.mentorCourses,
      mentorSubjects: matching.mentorSubjects,
      mentorExtraSubjects: matching.mentorExtraSubjects,
      mentorDateTime: matching.mentorDateTime,
      mentorPersonality: matching.mentorPersonality,
      menteeUidList: matching.menteeUidList,
      menteeAidList: matching.menteeAidList,
      menteeList: matching.menteeList,
      userType: matching.userType,
    };
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return new MatchingModel(
      data.mid,
      data.subject,
      data.state,
      data.appliedAt,
      data.approvedAt,
      data.approvedMentee,
      data.mentorUid,
      data.mentorAid,
      data.mentorEmail,
      data.mentorContact,
      data.mentorName,
      data.mentorGender,
      data.mentorBirth,
      data.mentorCourses,
      data.mentorSubjects,
      data.mentorExtraSubjects,
      data.mentorDateTime,
      data.mentorPersonality,
      data.menteeUidList,
      data.menteeAidList,
      data.menteeList,
      data.userType ?? null
    );
  },
};

export default matchingConverter;
