import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import Root from 'components/Root';
import { sendPasswordResetEmail } from 'firebase/auth';
import { firebaseAuth } from 'firebase_config';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from 'store/userSlice';
import styled from 'styled-components';
import Swal from 'sweetalert2';

const errorMessage = (msg) => {
  if (msg === 'auth/invalid-email') {
    return '올바른 이메일 형식으로 입력해주세요!';
  } else if (msg === 'auth/user-not-found') {
    return '존재하지 않는 계정입니다!';
  }
};

const resetPassword = () => {
  const navigate = useNavigate();

  const sendPasswordReset = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);
    if (data.email) {
      sendPasswordResetEmail(firebaseAuth, data.email)
        .then(() => {
          Swal.fire({
            icon: 'success',
            title: '비밀번호 재설정 이메일을 보냈습니다.',
            text: '이메일을 확인해주세요.',
            confirmButtonText: '확인',
            allowOutsideClick: false,
            allowEscapeKey: false,
          }).then((result) => {
            if (result.isConfirmed) {
              navigate('/signin');
            }
          });
        })
        .catch((err) => {
          Swal.fire({
            icon: 'error',
            title: '비밀번호 재설정 이메일을 보내는데 실패했습니다.',
            text: errorMessage(err.code),
            confirmButtonText: '확인',
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
        });
    }
  };

  return (
    <Root>
      <Wrapper>
        <Typography variant="h1" component="h1" paddingBottom="1rem">
          비밀번호 재설정
        </Typography>
        <Typography className="text-center" variant="body2" component="p" paddingBottom="3rem">
          비밀번호 재설정을 위해 계정 이메일을 입력해주세요.
          <br />
          입력하신 이메일로 비밀번호 재설정 메일이 전송됩니다.
        </Typography>
        <Box className="w-5/12" component="form" onSubmit={sendPasswordReset}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <TextField
                id="email"
                name="email"
                variant="outlined"
                placeholder="이메일을 입력해주세요."
                InputProps={{ className: '!rounded-xl' }}
                required
                label="이메일"
                fullWidth
              />
            </Grid>
          </Grid>
          <Box className="pt-16 w-5/12 m-auto text-center">
            <Button fullWidth className="formButton" type="submit" variant="contained">
              다음
            </Button>
          </Box>
        </Box>
      </Wrapper>
    </Root>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  min-width: 100vw;
  height: calc(100vh - 148px);
`;

export default resetPassword;
