import { Box, Typography } from '@mui/material';
import process1 from 'assets/process/process-1.svg';
import process2 from 'assets/process/process-2.svg';
import React from 'react';

const ApplyProcess = () => {
  return (
    <Box
      className="w-full flex flex-col justify-center items-center py-12 md:py-36 "
      sx={{ bgcolor: 'primary.main' }}
    >
      <Box className="flex flex-col justify-center items-center max-w-4xl text-center px-8">
        <Typography className="pb-4" variant="h1" fontSize="2.25rem" color="white">
          배워서 남주자 프로젝트는 이렇게 진행돼요
        </Typography>
        <Typography className="pb-16 " variant="body1" color="white">
          배남프에 신청하시면 아래 단계에 따라 멘토링이 진행됩니다.
          <br />
          진행 단계는 멘토와 멘티 모두 동일합니다.
        </Typography>
        <Box className="flex md:flex-row flex-col md:gap-0 gap-10">
          <Box className="w-auto">
            <img src={process1} alt="process" />
          </Box>
          <Box className="w-auto">
            <img src={process2} alt="process" />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ApplyProcess;
