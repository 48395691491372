import { where } from 'firebase/firestore';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { selectUser } from 'store/userSlice';
import {
  Box,
  Button,
  ListItem,
  ListItemButton,
  ListItemText,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import applicationConverter from 'models/application_model';
import userConverter from 'models/user_model';
import { useEffect, useState } from 'react';
import FirestorePath from 'services/firebase_path';
import { getCollectionFuture } from 'services/firebase_services';

const CheckPlanningStatus = () => {
  const user = useSelector(selectUser);

  const [buttonState, setButtonState] = useState(0);
  const [sumComponent, setSumComponent] = useState(<Box> </Box>);

  const [mentorLength, setMentorLength] = useState(0);
  const [menteeLength, setMenteeLength] = useState(0);
  const [matchingLength, setMatchingLength] = useState(0);
  const [goodMentorLength, setGoodMentorLength] = useState(0);

  if (user) {
    if (user.userType === 'admin') {
      return (
        <Box className="flex flex-col p-20">
          <Box className="pb-12">
            <Typography fontSize={'2rem'} fontWeight={'bold'}>
              수업계획서 현황 체크 어드민 페이지
            </Typography>
            <Typography fontSize={'1rem'} color="red">
              관리자만 접근할 수 있는 페이지입니다. 아래 버튼을 클릭해서 데이터를 조회할 수
              있습니다.
              <br />
              버튼 클릭 당 서버 부하가 큰 작업입니다. 과하게 사용시 서버비용이 발생할 수 있습니다.
              <br />
            </Typography>
          </Box>
          {/* <Box>
            <Box className="py-4">
              가입+멘토링정보 완료한 멘토: {mentorLength} 명
              <br />
              가입+멘토링정보 완료한 멘티: {menteeLength} 명
            </Box>
          </Box>
          <Box className="py-4">매칭 총: {matchingLength} 건</Box>
          <Box className="py-4">
            완료 멘토 수: {goodMentorLength} 명<br />
            미완료 멘토 수(전체-완료): {mentorLength - goodMentorLength} 명
          </Box> */}
          <AccountStatus />
        </Box>
      );
    }
    return <Navigate to="/" />;
  }
  return <Box> </Box>;
};

function renderRow(props) {
  const { index, style } = props;

  return (
    <ListItem style={style} key={index} component="div" disablePadding>
      <ListItemButton>
        <ListItemText primary={`${index} ${index + 1}`} />
      </ListItemButton>
    </ListItem>
  );
}

export default CheckPlanningStatus;

const AccountStatus = () => {
  const [applicationList, setApplicationList] = useState([]);
  const [menteeList, setMenteeList] = useState({});
  const [tableState, setTableState] = useState(0);

  const [matchingStatus, setMatchingStatus] = useState({});
  let rowIndex = 0;

  const handleChange = (event, index) => {
    // Array.from({
    //   length: 24,
    //   3: 0,
    //   5: 1,
    //   6: 1,
    //   7: 2,
    //   8: 2,
    //   9: 2,
    // })
    // console.log(event.target.value + '--' + index);
    matchingStatus[index] = event.target.value;
    setMatchingStatus({ ...matchingStatus });
  };

  const columns = {
    0: [
      { id: 'no.', label: 'no.', minWidth: 10, align: 'left' },
      { id: '멘토이름', label: '멘토이름', minWidth: 70, align: 'left' },
      { id: '멘토연락처', label: '멘토연락처', minWidth: 30, align: 'left' },
      { id: '멘티이름', label: '멘티이름', minWidth: 70, align: 'left' },
      { id: '수업일시', label: '수업일시', minWidth: 100, align: 'left' },
      { id: '수업목표', label: '수업목표', minWidth: 70, align: 'left' },
      { id: '과목', label: '과목', minWidth: 70, align: 'left' },
      { id: '과목상세', label: '과목상세', minWidth: 70, align: 'left' },
    ],
  };

  // 가입+멘토링정보 완료 멘티 불러오는 함수
  async function updateMentee(userType, index) {
    let tempMenteeList = [];

    // application 불러오기
    const docs = await getCollectionFuture(
      FirestorePath.plannings(),
      (snapshot, docId) => {
        const data = snapshot.data();
        return {
          mentorName: data.mentorName,
          mentorContact: data.mentorContact,
          subject: data.subject,
          subjectDetail: data.subjectDetail,
          menteeName: data.menteeName,
          lessonDT: data.lessonDT,
          lessonGoal: data.lessonGoal,
          lessons: data.lessons,
          aid: data.aid,
        };
      },
      [
        // where('userType', '==', userType),
        // where('matchingStatus', '<', 2),
        // where('availableSubjects', 'array-contains', subject),
        // where('availableCourses', 'array-contains-any', courses),
      ]
    );
    if (docs.length === 0) {
      console.log('no data');
    } else {
      console.log('do something');
      //   console.log(docs);
      // 테스트 계정 멘티는 지워주기
      const temp = docs.filter((doc) => doc.mentorName !== '윤한규' && doc.mentorName !== '이정민');
      const result = temp.sort((a, b) => (a.mentorName < b.mentorName ? -1 : 1));
      console.log(result);
      tempMenteeList = [...result];
    }

    // 업데이트하기
    setMenteeList({ ...menteeList, [index]: [...tempMenteeList] });
    setTableState(index);
  }

  const menteeListLength = Object.keys(menteeList).length;

  return (
    <Box className="flex flex-col w-full pt-12">
      <Box className="flex flex-row gap-8">
        <Button onClick={() => updateMentee('mentor', 0)}>
          1. 수업계획서 작성 완료한 🌼멘토 불러오기 -{' '}
          {menteeListLength ? menteeList[0]?.length ?? '0' : '--'}명
        </Button>
      </Box>
      {menteeListLength ? (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer sx={{}}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns[tableState].map((column, index) => (
                    <TableCell
                      key={index}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {menteeList[tableState].map((mentee, menteeIndex) => {
                  if (tableState === 0) {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={mentee.aid + menteeIndex}>
                        <TableCell align="left">{++rowIndex}</TableCell>
                        <TableCell align="left">{mentee.mentorName}</TableCell>
                        <TableCell align="left">{mentee.mentorContact}</TableCell>
                        <TableCell align="left">{mentee.menteeName}</TableCell>
                        <TableCell align="left">{mentee.lessonDT}</TableCell>
                        <TableCell align="left">{mentee.lessonGoal}</TableCell>
                        <TableCell align="left">{mentee.subject}</TableCell>
                        <TableCell align="left">{mentee.subjectDetail}</TableCell>
                      </TableRow>
                    );
                  }
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      ) : (
        <CircularProgress />
      )}
    </Box>
  );
};
