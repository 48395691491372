import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import userConverter from 'models/user_model';
import { doc, setDoc, serverTimestamp, Firestore } from 'firebase/firestore';
import { getDocumentFuture, setDocument } from 'services/firebase_services';
import { db } from 'firebase_config';
import FirestorePath from 'services/firebase_path';

const Status = {
  IDLE: 'idle',
  LOADING: 'Loading',
};

/// 회원가입 시 실행
/// Auth, DB에 유저 데이터 생성
export const signUp = createAsyncThunk('user/signUp', async ({ user, userInfo }) => {
  const { uid } = user;
  const data = { ...userInfo, createdAt: serverTimestamp(), uid };
  const docRef = doc(db, 'Users', uid);
  await setDoc(docRef, data);

  return true;
});

/// 로그인 시 실행
/// DB에서 유저 데이터 가져오고 store 에 저장
export const signIn = createAsyncThunk('user/signIn', async (auth) =>
  getDocumentFuture(FirestorePath.user(auth.uid), (data, docId) =>
    userConverter.fromFirestore(data)
  )
);

/// 멘토링 정보 입력 완료 시 실행
/// DB 유저 데이터 업데이트
export const updateUser = createAsyncThunk('user/updateUser', async (user) => {
  const { uid } = user;
  const data = user;

  const isDone = await setDocument(FirestorePath.user(uid), data, true);

  // if (isDone) {
  //   console.log('update complete');
  // } else {
  //   console.log('something wrong');
  // }

  return user;
});

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: undefined,
    applications: [],
    selectedApplicationIndex: 0,
    status: Status.IDLE,
  },
  reducers: {
    logout: (state) => {
      state.user = undefined;
      state.applications = undefined;
    },
    updateUserApplications: (state, action) => {
      state.applications = action.payload;
    },
    selectUserApplication: (state, action) => {
      state.selectedApplicationIndex = action.payload;
    },
  },
  extraReducers: (builder) => {
    const { IDLE, LOADING } = Status;

    builder
      .addCase(signIn.pending, (state, action) => {
        state.status = LOADING;
        // console.log('signIn pending');
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.user = action.payload;
        state.status = IDLE;
        // console.log('signIn complete');
      })
      .addCase(signIn.rejected, (state, action) => {
        // console.log('signIn rejected!!');
        // console.log(action);
      })
      .addCase(updateUser.pending, (state, action) => {
        state.status = LOADING;
        // console.log('updateUser pending');
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.user = action.payload;
        state.status = IDLE;
        // console.log('updateUser complete');
      })
      .addCase(updateUser.rejected, (state, action) => {
        // console.log('updateUser rejected!!');
        // console.log(action);
      })
      .addCase(signUp.fulfilled, (state, action) => {
        // TODO: 회원가입하면서 자동 로그인 할거면 아래 주석해제
        // state.user = action.payload;
        // console.log('회원가입 완료');
      })
      .addCase(signUp.rejected, (state, action) => {
        // console.log('signUp rejected!!');
        // console.log(action);
      });
  },
});

export const { logout, updateUserApplications, selectUserApplication } = userSlice.actions;

// selectors
export const selectUser = (state) => state.user.user;
export const getUserApplications = (state) => state.user.applications;
export const getSelectedApplicationIndex = (state) => state.user.selectedApplicationIndex ?? 0;
export const selectedUserApplication = (state) =>
  state.user.applications[state.user.selectedApplicationIndex];

export default userSlice.reducer;
