import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveApplication, setApplication } from 'store/applicationSlice';
import { showMessage } from 'store/messageSlice';
import withRouter from 'utils/withRouter';

const MenteeApply = (props) => {
  const { rows, step, actionPrevStep } = props;
  const dispatch = useDispatch();
  const applicationData = useSelector((state) => state.application.mentee);
  const [isDone, setIsDone] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    // const formData = new FormData(e.target);
    // const data = Object.fromEntries(formData.entries());
    // if (data.email === '') {
    //   dispatch(showMessage({ message: '이메일 주소를 입력해주세요.', variant: 'error' }));
    //   return;
    // }
    // const newData = { ...applicationData, [step]: data };
    // dispatch(setApplication({ step, data, type: 'mentee' }));
    // dispatch(saveApplication({ data: newData, type: 'mentee' }));
    // setIsDone(true);
  };

  return (
    <Box className="pb-12 md:pb-16" component="form" onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography className="text-center pb-8" variant="h1" fontSize="2.25rem">
            멘토링 정보 입력 완료
          </Typography>
          <Typography className="text-center" color={(theme) => theme.palette.error.main}>
            설문을 성공적으로 완료하셨습니다 🎉
            <br />
            <br />
          </Typography>
          <Typography className="text-center">
            [내 정보] 페이지로 이동하셔서
            <br />
            배남프에 신청한 멘티(학생) 수만큼 추가로 멘토링 정보를 작성해주세요 :)
          </Typography>
        </Grid>
        <Grid item xs={12} className="flex justify-center">
          <Box className="flex md:flex-row flex-col items-center justify-center pt-8">
            {/* <Button
              fullWidth
              className="formButton"
              variant="outlined"
              color="inherit"
              onClick={() => props.navigate('/mentoring-info')}
            >
              추가 입력하기
            </Button> */}
            <Button
              fullWidth
              className="formButton"
              type="submit"
              variant="contained"
              onClick={() => props.navigate('/mypage')}
            >
              <Typography>내 정보 페이지로 이동</Typography>
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default withRouter(MenteeApply);
