import { Box, Button } from '@mui/material';
import Root from 'components/Root';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/userSlice';
import { Navigate } from 'react-router-dom';
import withRouter from 'utils/withRouter';
import BasicInfo from './basic_info/BasicInfo';
import InfoMenus from './InfoMenus';
import Matching from './matching/Matching';
import Syllabus from './syllabus/Syllabus';

const MyPage = (props) => {
  const user = useSelector(selectUser);
  const [menuIndex, setMenuIndex] = useState(0);

  useEffect(() => {
    if (user) {
    }
  }, [user]);

  const stepComponents = {
    0: <BasicInfo user={user} />,
    1: <Matching />,
    2: <Syllabus />,
  };

  if (user) {
    return (
      <Root activeMenu="마이페이지" bgColor="#F5F5F5">
        <Box className="flex lg:flex-row flex-col min-h-[92vh]">
          <InfoMenus menuIndex={menuIndex} setMenuIndex={setMenuIndex} />
          {stepComponents[menuIndex]}
        </Box>
      </Root>
    );
  } else {
    return <Navigate to="/" />;
  }
  return <Box> </Box>;
};

export default withRouter(MyPage);
