import { Box, Card, CardContent, Divider, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchPlanning } from 'store/planningSlice';
import { fetchLessonLog } from 'store/lessonLogSlice';

const LessonLogWidget = ({ uid }) => {
  const dispatch = useDispatch();
  const [logData, setLogData] = useState([]);
  const [data, setData] = useState(null);
  const [lessons, setLessons] = useState([]);
  const [logId, setLogId] = useState(null);

  useEffect(() => {
    // console.log('uid 업데이트마다: ', uid);
    dispatch(fetchLessonLog(uid)).then((res) => {
      const result = res.payload;
      const temp = [];
      if (result !== null) {
        setLogData(result);
      } else {
        setLogData([]);
        // console.log('수업일지 없음');
      }
    });
    dispatch(fetchPlanning(uid)).then((res) => {
      const result = res.payload;
      const temp = [];
      if (result !== null) {
        Object.keys(result.lessons).forEach((key) => {
          // key를 id로 추가
          temp.push({ ...result.lessons[key], id: Number(key) });
        });
        setLessons(temp);
      } else {
        setLessons([]);
        // console.log('수업계획서 없음');
      }
    });
  }, [uid]);

  useEffect(() => {
    // console.log('로그데이터: ', logData);
    if (logData.length) {
      const temp = [];
      Object.keys(logData[0])
        .filter((key) => !isNaN(key))
        .forEach((key) => {
          temp.push({ ...logData[0][key], id: Number(key) });
        });
      setData(temp);
      setLogId(logData[0].docId);
    } else {
      setData(null);
      // console.log('수업일지 없어서 데이터 없음');
    }
  }, [logData, setLogData]);

  return !lessons.length ? (
    <Box className="flex flex-col justify-start px-10 py-10 md:px-10 md:py-10 my-4 md:my-2 w-full max-w-5xl box-border bg-white rounded-2xl">
      <Typography className="pb-2" variant="h2">
        수업일지
      </Typography>
      <Typography className="!font-normal pb-2">
        매 회차 수업을 진행한 이후 일지를 작성해주세요.
      </Typography>
      <Divider />
    </Box>
  ) : (
    <Box className="flex flex-col justify-start px-10 py-10 md:px-10 md:py-10 my-4 md:my-2 w-full max-w-5xl box-border bg-white rounded-2xl">
      <Typography className="pb-2" variant="h2">
        수업일지
      </Typography>
      <Typography className="!font-normal pb-2">
        매 회차 수업을 진행한 이후 일지를 작성해주세요.
      </Typography>
      <Divider />
      <Box className="flex flex-col px-4 pt-4 gap-2">
        {data?.map((item, index) => (
          <Card sx={{ minWidth: 275, p: 2, mb: 1 }} className="flex justify-between" key={index}>
            <CardContent>
              <Box className="flex">
                <Typography gutterBottom>{item.lessonTerm}</Typography>
                <Typography className="pl-4" gutterBottom>
                  {item.lessonDate}
                </Typography>
              </Box>
              <Typography className="!font-normal">
                수업진도:
                <Typography className="pl-2 !font-normal" component="span">
                  {item.title}
                </Typography>
              </Typography>
              <Box className="flex">
                <Typography className="!font-normal">
                  학습온도:
                  <Typography className="pl-2 !font-normal" component="span">
                    {item.lessonTemp}
                  </Typography>
                </Typography>
                <Typography className="pl-4 !font-normal">
                  관계온도:
                  <Typography className="pl-2 !font-normal" component="span">
                    {item.relationTemp}
                  </Typography>
                </Typography>
              </Box>
              <Box className="flex">
                <Typography className="!font-normal">특이사항:</Typography>
                <Typography className="pl-2 !font-normal max-w-[625px]" component="span">
                  {item.note}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        )) ?? <Box> </Box>}
      </Box>
    </Box>
  );
};

export default LessonLogWidget;
