import { Box, Typography } from '@mui/material';
import Root from 'components/Root';
import React from 'react';

const TermsOfService = () => {
  return (
    <Root>
      <Box className="flex justify-center py-20 px-20">
        <Box className="max-w-5xl">
          <Typography variant="h1" className="!pb-8">
            서비스 이용약관
          </Typography>
          <Typography variant="h2" color="neutral.500" className="!pb-8">
            제 1 장 총 칙
          </Typography>
          <Typography variant="body1" color="neutral.500" className="!pb-8">
            제 1 조 (목적)
          </Typography>
          <Typography variant="body1" color="neutral.500" className="!pb-8">
            이 약관은 배워서 남주자 프로젝트(이하 "배남프"라 함)가 운영하는 인터넷 사이트(이하
            "사이트"라 함)에서 제공하는 인터넷 관련 서비스(이하 "서비스"라 한다)를 이용함에 있어
            사이트와 이용자의 권리의무 및 책임사항을 규정함을 목적으로 합니다.
          </Typography>
          <Typography variant="body1" color="neutral.500" className="!pb-8">
            제 2 조 (약관의 효력 및 변경)
          </Typography>
          <Typography variant="body1" color="neutral.500" className="!pb-8">
            1) 이 약관은 배남프가 "사이트"를 통해 게시하고, 이용자가 이에 동의함으로써 효력이
            발생됩니다. <br />
            2) "배남프"는 "약관의규제에 관한 법률", "정보통신망이용촉진 및 정보보호등에
            관한법률(이하 "정보통신망법")" 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수
            있습니다.
            <br />
            3) 배남프가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행 약관과 함께
            사이트의 초기화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지함으로써 효력이
            발생됩니다. <br />
          </Typography>
          <Typography variant="body1" color="neutral.500" className="!pb-8">
            제 3 조 (약관 외 준칙)
          </Typography>
          <Typography variant="body1" color="neutral.500" className="!pb-8">
            이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 정부가 제정한 관계법령 및
            관례에 따릅니다.
          </Typography>
          <Typography variant="body1" color="neutral.500" className="!pb-8">
            제 4 조 (용어의 정의)
          </Typography>
          <Typography variant="body1" color="neutral.500" className="!pb-8">
            이 약관에서 사용하는 주요한 용어의 정의는 다음과 같습니다.
            <br />
            <br />
            1) “이용자” : "사이트"에 접속하여 이 약관에 따라 배남프가 제공하는 무료서비스를 받는
            이용자를 말합니다. <br />
            2)"아이디(ID)" 라 함은 "회원"의 식별과 "서비스" 이용을 위하여 "회원"이 정하고 "배남프"가
            승인하는 문자와 숫자의 조합을 의미합니다. <br />
            3) "비밀번호"라 함은 "회원"이 부여 받은 "아이디와 일치되는 "회원"임을 확인하고
            비밀보호를 위해 "회원" 자신이 정한 문자 또는 숫자의 조합을 의미합니다. <br />
          </Typography>
          <Typography variant="h2" color="neutral.500" className="!pb-8">
            제 2 장 서비스 이용 계약 체결
          </Typography>
          <Typography variant="body1" color="neutral.500" className="!pb-8">
            제 5 조 (이용계약의 성립)
          </Typography>
          <Typography variant="body1" color="neutral.500" className="!pb-8">
            1) 배남프는 서비스 이용자의 이용신청에 대하여 특별한 사정이 없는 한 등록절차에 따라
            이용신청을 승낙하며, 이용자는 등록절차를 거쳐 "동의" 버튼을 누름으로써 이 서비스 약관에
            동의한 것으로 간주됩니다. 약관변경 시에도 이와 동일하며 변경된 약관에 동의하지 않을 경우
            이용자 등록 취소가 가능합니다. <br />
            2) 이용계약은 서비스 이용희망자의 이용약관 동의 후 이용신청에 대하여 배남프가 승낙을
            함으로써 성립합니다.
          </Typography>
          <Typography variant="body1" color="neutral.500" className="!pb-8">
            제 6 조 (이용신청)
          </Typography>
          <Typography variant="body1" color="neutral.500" className="!pb-8">
            1) 이용자에 가입하여 서비스를 이용하고자 하는 자는 배남프가 제시하는 소정의 가입신청
            양식에서 요구하는 사항을 기록하여 신청합니다. <br />
            2) 온라인 가입 신청 양식에 기재하는 모든 이용자정보는 실제 데이터인 것으로 간주하며
            실명이나 실제정보를 입력하지 않은 사용자는 법적으로 보호를 받을 수 없으며, 서비스 사용의
            제한을 받을 수 있습니다.
          </Typography>
          <Typography variant="body1" color="neutral.500" className="!pb-8">
            제 7 조 (이용신청의 승낙)
          </Typography>
          <Typography variant="body1" color="neutral.500" className="!pb-8">
            1) 배남프는 제 6 조에 따른 이용신청에 대하여 특별한 사정이 없는 한 접수 순서대로
            이용신청을 승낙합니다. <br />
            2) 배남프는 다음 각호에 해당하는 경우 이용신청에 대한 승낙을 제한할 수 있고, 그 사유가
            해소될 때까지 승낙을 유보할 수 있습니다. <br />
            가. 서비스 관련 설비에 여유가 없는 경우 <br />
            나. 기술상 지장이 있는 경우 <br />
            다. 기타 배남프의 사정상 필요하다고 인정되는 경우 <br />
            3) 배남프는 다음 각 호에 해당하는 사항을 인지하는 경우 이용계약 신청을 승낙하지 아니할
            수 있다. <br />
            가. 본인의 실명을 신청하지 않은 경우 <br />
            나. 다른 사람의 명의를 사용하여 신청한 경우
            <br />
            다. 이용 신청 시 필요사항을 허위로 기재하여 신청한 경우 <br />
            라. 기타 배남프가 정한 이용신청 요건이 미비 된 경우
            <br />
            4) 제 2 항 또는 제 3 항에 의하여 이용신청의 승낙을 유보하거나 승낙하지 아니하는 경우,
            배남프는 이를 이용신청자에게 알려야 합니다. 다만, 배남프의 귀책사유 없이 이용신청자에게
            통보할 수 없는 경우는 예외로 합니다.
          </Typography>
          <Typography variant="body1" color="neutral.500" className="!pb-8">
            제 8 조 (계약 사항의 변경)
          </Typography>
          <Typography variant="body1" color="neutral.500" className="!pb-8">
            1) 이용자는 배남프가 제공한 이용자정보관리를 통해 언제든지 본인의 개인정보를 열람하고
            수정할 수 있습니다. <br />
            2) 이용자는 이용신청 시 기재한 사항이 변경되었을 경우 온라인으로 이를 수정해야 하며,
            이용자정보의 미변경으로 인하여 발생되는 문제에 대한 책임은 이용자에게 있습니다.
          </Typography>
          <Typography variant="body1" color="neutral.500" className="!pb-8">
            제 9 조 (개인정보의 보호)
          </Typography>
          <Typography variant="body1" color="neutral.500" className="!pb-8">
            1) 배남프는 이용자의 개인정보를 보호하고 존중합니다. <br />
            2) 배남프는 이용자의 정보수집 시 서비스 제공에 필요한 최소한의 정보를 수집합니다. <br />
            3) 배남프는 이용자가 서비스를 이용함에 있어서 온라인 상에서 배남프에게 제공한 개인정보가
            보호받을 수 있도록 최선을 다하고 있습니다. 자세한 사항은 배남프의 개인정보 보호정책을
            참고하시기 바랍니다. <br />
            4) 배남프는 서비스 제공과 관련하여 취득한 이용자의 신상정보를 본인의 승낙없이 제3자에게
            누설 또는 배포할 수 없으며 상업적 목적으로 사용할 수 없습니다. <br />
            5) 이용자는 언제든지 배남프가 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을
            요구할 수 있으며 배남프는 이에 대해 지체없이 필요한 조치를 취할 의무를 집니다. 이용자가
            오류의 정정을 요구한 경우에 배남프는 그 오류를 정정할 때까지 해당 개인정보를 이용하지
            않습니다. <br />
            6) 배남프는 개인정보 보호를 위하여 관리자를 한정하여 그 수를 최소화하며 이용자의
            개인정보의 분실, 도난, 유출, 변조 등으로 인한 이용자의 손해에 대하여 모든 책임을 집니다.
          </Typography>
          <Typography variant="h2" color="neutral.500" className="!pb-8">
            제 3 장 계약 당사자의 의무
          </Typography>
          <Typography variant="body1" color="neutral.500" className="!pb-8">
            제 10 조 (배남프의 의무)
          </Typography>
          <Typography variant="body1" color="neutral.500" className="!pb-8">
            1) 배남프는 법령과 이 약관이 금지하거나 반하는 행위를 하지 않으며 이 약관이 정하는 바에
            따라 지속적이고, 안정적으로 서비스를 제공하는 데 최선을 다하여야 합니다. 다만, 배남프의
            업무상이나 기술상의 이유로 서비스가 일시 중지되거나 운영상의 목적으로 배남프가 정한
            기간에는 서비스가 일시 중지될 수 있습니다. 이런 경우 배남프는 사전 또는 사후 이를
            공지해야 합니다. <br />
            2) 배남프는 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록 이용자의
            개인정보(신용정보 포함)보호를 위한 보안 시스템을 갖추어야 합니다. <br />
            3) 배남프는 이용자로부터 소정의 절차에 의해 제기되는 의견이나 불만이 정당하다고 인정할
            경우에는 적절한 절차를 거쳐 처리하여야 합니다. 처리시 일정기간이 소요될 경우 이용자에게
            그 사유와 처리 일정을 알려주어야 합니다.
          </Typography>
          <Typography variant="body1" color="neutral.500" className="!pb-8">
            제 11 조 (이용자의 의무)
          </Typography>
          <Typography variant="body1" color="neutral.500" className="!pb-8">
            이용자는 다음 각호에 해당되는 행위를 하여서는 안됩니다. 다음 각호에 해당되는 행위가
            발견되면 배남프는 해당자의 이용자자격을 정지 혹은 박탈시킬 수 있습니다. <br />
            1) 신청 또는 변경 시 허위내용의 등록 행위 <br />
            2) 배남프에서 제공하는 서비스를 통해서 얻은 정보를 배남프의 승인 없이 무단으로 복제,
            변경, 출판, 방송 등의 방법으로 사용하거나 이를 타인에게 유포하는 행위 <br />
            3) 다른 이용자의 개인정보를 수집하거나 저장하는 행위
            <br />
            4) 타인의 정보 도용하는 행위
            <br />
            5) 기타 관계 법령에 위배되는 행위
          </Typography>
          <Typography variant="body1" color="neutral.500" className="!pb-8">
            제 12 조 (이용자가입)
          </Typography>
          <Typography variant="body1" color="neutral.500" className="!pb-8">
            이용자는 배남프가 정한 가입 양식에 따라 이용자정보를 기입한 후 이 약관에 동의한다는
            의사표시를 함으로서 이용자가입을 신청합니다. 배남프는 이용자로 가입할 것을 신청한 이용자
            중 다음 각호에 해당하지 않는 한 이용자등록을 허락합니다. <br />
            1) 등록 내용에 허위, 기재누락, 오기가 있는 경우 <br />
            2) 이용자로 등록하는 것이 배남프의 기술상 현저히 지장이 있다고 판단되는 경우 <br />
            3) 가입 신청자가 이 약관 제11조 의하여 이전에 이용자자격을 상실한 적이 있는 경우,
            이용자자격 상실 후 배남프의 이용자 재가입 승낙을 얻은 경우에는 예외로 한다
          </Typography>
          <Typography variant="body1" color="neutral.500" className="!pb-8">
            제 13 조 (서비스 이용신청의 유보 및 거부)
          </Typography>
          <Typography variant="body1" color="neutral.500" className="!pb-8">
            1) 다음 각호에 해당하는 경우 서비스 이용신청을 유보할 수 있습니다. <br />
            가. 기술적인 문제가 발생한 경우 <br />
            나. 장비에 여유가 없는 경우 <br />
            다. 배남프 사정상 이용승낙이 곤란한 경우 <br />
            2) 다음 각호에 해당하는 경우 서비스 이용신청을 거부할 수 있습니다. <br />
            가. 실명을 사용하지 않는 경우 <br />
            나. 타인의 명의를 사용하여 신청한 경우
            <br />
            라. 기재내용을 허위로 기재한 경우 <br />
            마. 기타 배남프가 정한 이용신청요건(순장이상이 아닌 경우) 및 사항에 미치지 못할 경우
          </Typography>
          <Typography variant="body1" color="neutral.500" className="!pb-8">
            제 14 조 (서비스 이용시간)
          </Typography>
          <Typography variant="body1" color="neutral.500" className="!pb-8">
            1) 배남프의 업무상 혹은 기술상 특별한 이유가 없는 한 배남프에서 제공하는 서비스는
            지속적으로 이용할 수 있습니다. <br />
            2) 배남프가 정한 정기점검 혹은 임시점검시간에는 이용할 수 없습니다. <br />
            3) 서비스의 종류에 따라서 지속적으로 제공할 수 없는 경우가 있으며 이는 배남프가 정
            합니다.
          </Typography>
          <Typography variant="body1" color="neutral.500" className="!pb-8">
            제 15 조 (서비스의 중단)
          </Typography>
          <Typography variant="body1" color="neutral.500" className="!pb-8">
            1) 배남프는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신의 두절 등의 사유가
            발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있고, 새로운 서비스로의 교체, 기타
            배남프가 적절하다고 판단하는 사유에 의하여 현재 제공되는 서비스를 완전히 중단할 수
            있습니다. <br />
            2) 서비스 중단의 경우에 배남프는 배남프가 규정한 통지원칙에 따라 이용자에게 사전
            통지합니다. 단, 배남프가 통제할 수 없는 사유로 인한 서비스의 중단(시스템 관리자의 고의나
            과실이 없는 디스크 장애, 시스템 다운 등)으로 인하여 사전 통지가 불가능한 경우에는
            그러하지 아니합니다.
          </Typography>
          <Typography variant="body1" color="neutral.500" className="!pb-8">
            제 16 조 (면책조항)
          </Typography>
          <Typography variant="body1" color="neutral.500" className="!pb-8">
            1) 배남프는 이용자의 서비스 이용과 관련하여 배남프의 고의, 과실에 의한 것이 아닌 한
            이용자에게 발생한 어떠한 손해에 관해서도 책임을 지지 않습니다. <br />
            2) 천재지변 등 불가항력에 의한 서비스 중단 및 이용자가 올린 데이타의 유실 혹은 손상 시
            배남프는 책임이 면제됩니다
          </Typography>
          <Typography variant="body1" color="neutral.500" className="!pb-8">
            제 17 조 (준거법 및 재판관할)
          </Typography>
          <Typography variant="body1" color="neutral.500" className="!pb-8">
            ① “배남프”와 “회원” 간 제기된 소송은 대한민국법을 준거법으로 합니다.
            <br />② “배남프”와 “회원” 간 발생한 분쟁에 관한 소송은 민사소송법 상의 주소지를 관할하는
            법원을 합의 관할로 합니다.
          </Typography>
          <Typography variant="body1" color="neutral.500" className="!pb-8">
            제 18 조 (규정의 준용)
          </Typography>
          <Typography variant="body1" color="neutral.500" className="!pb-8">
            본 약관에 명시되지 않은 사항에 대해서는 관련법령에 의하고, 법에 명시되지 않은 부분에
            대하여는 관습에 의합니다.
          </Typography>
          <Typography variant="body1" color="neutral.500" className="!pb-8">
            부칙
          </Typography>
          <Typography variant="body1" color="neutral.500" className="!pb-8">
            ① 이 약관은 2023년6월23일부터 적용됩니다.
          </Typography>
        </Box>
      </Box>
    </Root>
  );
};

export default TermsOfService;
