class UserModel {
  constructor(name, email, uid, userType, mentoringInfoExist) {
    this.name = name;
    this.email = email;
    this.uid = uid;
    this.userType = userType;
    this.mentoringInfoExist = mentoringInfoExist;
  }

  toString() {
    return `${this.name}, ${this.email},  ${this.uid}, ${this.userType}, ${this.mentoringInfoExist}`;
  }
}

// Firestore data converter
const userConverter = {
  toFirestore: (user) => {
    return {
      name: user.name,
      email: user.email,
      uid: user.uid,
      userType: user.userType,
      mentoringInfoExist: user.mentoringInfoExist,
    };
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return new UserModel(
      data.name,
      data.email,
      data.uid,
      data.userType,
      data.mentoringInfoExist ?? null
    );
  },
};

export default userConverter;
