import { Box, Divider, Typography } from '@mui/material';
import { COMMON } from 'constants';

const DetailSchedule = () => {
  const scheduleList = [
    {
      month: '3월',
      schedule: [
        {
          period: '1(금)',
          descritpion: '멘토 모집 마감',
        },
        {
          period: '4(월)',
          descritpion:
            '멘토 동행여부 검토\n’동행이란?’ 멘토와 멘티가 함께 진행하는 학습 멘토링의 과정',
        },
        {
          period: '5(화)',
          descritpion: '5기 동행 멘토 발표\n(1-2차로 나누어 진행)',
        },
        {
          period: '5(화) ~ 18(월)',
          descritpion: '멘토-멘티 매칭 진행',
        },
        {
          period: '19(화)',
          descritpion: '매칭 결과 안내',
        },
        {
          period: '19(화) ~ 28(목)',
          descritpion: '멘토-멘티 첫인사, 교재 선정 및 신청',
        },
        {
          period: '29(금) ~ 4/7(일)',
          descritpion: '수업 계획서 작성, 교재 배포',
        },
      ],
    },
    {
      month: '4월',
      schedule: [
        {
          period: '6(토)',
          descritpion: '배남프 5기 개강 예배',
        },
        {
          period: '7(일) ~ 6/16(일)',
          descritpion: '멘토링 기간',
        },
      ],
    },
    {
      month: '6월',
      schedule: [
        {
          period: '29(토)',
          descritpion: '배남프 종강 예배',
        },
      ],
    },
  ];

  return (
    <Box className="flex flex-col justify-center items-center w-full py-12 px-8 md:py-36 max-w-4xl text-center">
      <Typography className="pb-5" variant="h1" fontSize="2.25rem">
        배남프 상세 일정
      </Typography>
      <Typography className="pb-16" variant="body1">
        배남프 {COMMON.ORDINAL}기 상세 일정이에요 🗓️
      </Typography>
      <Box
        className="flex flex-col w-full border-dashed border-spacing-8 border-2 md:px-24 md:py-14 p-10"
        sx={{ borderColor: 'primary.main' }}
      >
        {/* 3월 상세 일정 */}
        {scheduleList[0].schedule.map((element, index) => {
          return (
            <Box
              key={element.period}
              className="flex flex-row justify-between items-center w-full py-4"
            >
              {index === 0 ? (
                <Box key={element.period} className="w-14">
                  <Typography fontSize="1.5rem" fontWeight="bold">
                    {scheduleList[0].month}
                  </Typography>
                </Box>
              ) : (
                <Box className="w-14" />
              )}
              <Typography className="text-start !text-sm text-gray-800">
                {element.period}
              </Typography>
              <Typography className="w-5/12 text-left !text-sm text-gray-800 whitespace-pre-line">
                {element.descritpion}
              </Typography>
            </Box>
          );
        })}
        {/* 4월 상세 일정 */}
        {scheduleList[1].schedule.map((element, index) => {
          return (
            <Box
              key={element.period}
              className="flex flex-row justify-between items-center w-full py-4"
            >
              {index === 0 ? (
                <Box key={element.period} className="w-14">
                  <Typography fontSize="1.5rem" fontWeight="bold">
                    {scheduleList[1].month}
                  </Typography>
                </Box>
              ) : (
                <Box className="w-14" />
              )}
              <Typography className="text-start !text-sm text-gray-800">
                {element.period}
              </Typography>
              <Typography
                className={`w-5/12 text-left !text-sm text-gray-800 whitespace-pre-line ${
                  index === 0 ? '!text-[#E4782A]' : ''
                }`}
              >
                {element.descritpion}
              </Typography>
            </Box>
          );
        })}
        {/* 디바이더 */}
        <Box className="flex flex-row justify-between items-center w-full py-4">
          <Box className="w-14" />
          <Box className="flex justify-center w-36 h-20">
            <Divider className="bg-black" orientation="vertical" variant="middle" flexItem />
          </Box>
          <Box className="flex pl-14 w-5/12 h-20">
            <Divider className="bg-black" orientation="vertical" variant="middle" flexItem />
          </Box>
        </Box>
        {/* 6월 상세 일정 */}
        {scheduleList[2].schedule.map((element, index) => {
          return (
            <Box
              key={element.period}
              className="flex flex-row justify-between items-center w-full py-4"
            >
              {index === 0 ? (
                <Box key={element.period} className="w-14">
                  <Typography fontSize="1.5rem" fontWeight="bold">
                    {scheduleList[2].month}
                  </Typography>
                </Box>
              ) : (
                <Box className="w-14" />
              )}
              <Typography className="text-start !text-sm text-gray-800">
                {element.period}
              </Typography>
              <Typography
                className={`w-5/12 text-left !text-sm text-gray-800 whitespace-pre-line ${
                  index === 0 ? '!text-[#E4782A]' : ''
                }`}
              >
                {element.descritpion}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default DetailSchedule;
